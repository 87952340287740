import { useContext, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'

import MainPage from './pages/Main'

// ADMIN
import Admin from './pages/Admin'
import AdminHome from   './pages/Admin/Home'
import AdminCategorias from   './pages/Admin/Categories'
import AdminCoupons from   './pages/Admin/Coupons'
import AdminRestaurants from   './pages/Admin/Restaurants'
import AdminPublicity from   './pages/Admin/Publicity'
import AdminSecurity from   './pages/Admin/Security'

// RESTAURANT
import Restaurant from './pages/Restaurant'
import RestaurantHome from './pages/Restaurant/Home'
import RestaurantCoupons from './pages/Restaurant/Coupons'
import RestaurantValidate from './pages/Restaurant/Validate'
import RestaurantSettings from './pages/Restaurant/Settings'
import RestaurantSettingsInfo from './pages/Restaurant/Settings/Info'
import RestaurantSettingsSecurity from './pages/Restaurant/Settings/Security'

import { LoginContext } from './contexts/LoginContext'
import Product from './pages/Product'
import Cart from './pages/Cart'
import CuponsUser from './pages/CuponsUser'
import Profile from './pages/Profile'
import Favorites from './pages/Favorites'
import NotFound from './pages/NotFound'
import Search from './pages/Search'
import Category from './pages/Category'
import Info from './pages/Profile/Info'
import Security from './pages/Profile/Security'
import CheckOut from './pages/ChekOut'
import StatusPayment from './pages/StatusPayment'
import MoreSales from './pages/MoreSales'
import MoreDiscount from './pages/MoreDiscount'
import MoreSearch from './pages/MoreSearch'
import ForgotPassword from './pages/ForgotPassword'
import About from './pages/About'
import RestaurantPage from './pages/RestaurantPage'
import UseTerms from './pages/UseTerms'
import FAQ from './pages/FAQ'
import Privacy from './pages/Privacy'
import Contact from './pages/Contact'
import BeAPartner from './pages/BeAPartner'
import Doubts from './pages/Doubts'


export function AppRoutes() {

  const { user } = useContext(LoginContext)
  const location = useLocation()
  const navigate = useNavigate()

  const accessRoutes = () => {
    if(location.pathname.startsWith("/admin")) {
      if(user && user?.token === process.env.REACT_APP_ADMIN_TOKEN){
        return navigate(location.pathname)
      }
      return navigate("/")
    }

    if(location.pathname.startsWith("/restaurante")) {
      if(user && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN){
        return navigate(location.pathname)
      }
      return navigate("/")
    }


    if(location.pathname.startsWith("/perfil") || location.pathname.startsWith("/favoritos")) {
      if(user && user?.token === process.env.REACT_APP_USER_TOKEN){
        return navigate(location.pathname)
      }
      return navigate("/")
    }


    return navigate(location.pathname)
  }

  const accessRoutes2 = () => {
    const data = JSON.parse(localStorage.getItem("cupozin-auth") || '{}')


    if(location.pathname.startsWith("/admin")) {
      if(user && user?.token === process.env.REACT_APP_ADMIN_TOKEN || data?.token === process.env.REACT_APP_ADMIN_TOKEN){
        return navigate(location.pathname)
      }
      return navigate("/")
    }

    if(location.pathname.startsWith("/restaurante")) {
      if(user && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN || data?.token === process.env.REACT_APP_RESTAURANT_TOKEN){
        return navigate(location.pathname)
      }
      return navigate("/")
    }

    if(location.pathname.startsWith("/perfil") || location.pathname.startsWith("/favoritos")) {
      if(user && user?.token === process.env.REACT_APP_USER_TOKEN || data?.token === process.env.REACT_APP_USER_TOKEN){
        return navigate(location.pathname)
      }
      return navigate("/")
    }


    return navigate(location.pathname)
  }

  useEffect(() => {
    if(localStorage.getItem("cupozin-auth")) {
      try{
        const data = JSON.parse(localStorage.getItem("cupozin-auth") || '{}')
        if(data && data?.token) {
          if(data.token === process.env.REACT_APP_ADMIN_TOKEN ||
             data.token === process.env.REACT_APP_USER_TOKEN ||
             data.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
              accessRoutes2()
            } else { accessRoutes() }
        }
      } catch {
        accessRoutes()
      }
    } else {
      accessRoutes()
    }

  }, [])

  return (
    <Routes>
      <Route path='*' element={<NotFound />} />
      <Route path='/' element={<MainPage />} />
      <Route path='/sobre' element={<About />} />
      <Route path='/perguntas-frequentes' element={<FAQ />} />
      <Route path='/termos-de-uso' element={<UseTerms />} />
      <Route path='/politicas-de-privacidade' element={<Privacy />} />
      <Route path='/fale-conosco' element={<Contact />} />
      <Route path='/seja-parceiro' element={<BeAPartner />} />
      <Route path='/duvidas-frequentes' element={<Doubts />} />
      <Route path='/mais-vendidos' element={<MoreSales />} />
      <Route path='/mais-descontos' element={<MoreDiscount />} />
      <Route path='/mais-procurados' element={<MoreSearch />} />
      <Route path='/esqueceu-a-senha' element={<ForgotPassword />} />
      <Route path='/search' element={<Search />} />
      <Route path='/categoria/:category' element={<Category />} />
      <Route path='/cupon/:cupon' element={<Product />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/cupons' element={<CuponsUser />} />
      <Route path='/perfil' element={<Profile />}>
        <Route path='/perfil/info' element={<Info />} />
        <Route path='/perfil/segurança' element={<Security />} />
      </Route>
      <Route path='/favoritos' element={<Favorites />} />
      <Route path='/pagamento' element={<CheckOut />} />
      <Route path='/pagamento_status/:idPayment' element={<StatusPayment />} />
      <Route path='/restaurant/:restaurant' element={<RestaurantPage />} />

      <Route path='/admin' element={<Admin />}>
        <Route path='/admin/home' element={<AdminHome />} />
        <Route path='/admin/categorias' element={<AdminCategorias />} />
        <Route path='/admin/cupons' element={<AdminCoupons />} />
        <Route path='/admin/restaurantes' element={<AdminRestaurants />} />
        <Route path='/admin/publicidade' element={<AdminPublicity />} />
        <Route path='/admin/seguranca' element={<AdminSecurity />} />
      </Route>

      <Route path='/restaurante' element={<Restaurant />}>
        <Route path='/restaurante/home' element={<RestaurantHome />} />
        <Route path='/restaurante/cupons' element={<RestaurantCoupons />} />
        <Route path='/restaurante/validar_cupons' element={<RestaurantValidate />} />
        <Route path='/restaurante/configuracoes' element={<RestaurantSettings />}>
          <Route path='/restaurante/configuracoes/info' element={<RestaurantSettingsInfo />} />
          <Route path='/restaurante/configuracoes/seguranca' element={<RestaurantSettingsSecurity />} />
        </Route>
      </Route>
    </Routes>
  )
}
