import React, { useContext } from 'react'
import { ButtonBuy, CartTable, Container, Content, Product, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as CartIcon } from '../../assets/icons/shopping-cart.svg';
import CursorMask from '../../components/CursorMask';
import { LoginContext } from '../../contexts/LoginContext';
import Footer from '../../components/Footer';

type itemCupon = {
  id: number
  cupon_id: number
  plate_name: string
  picture: string
  price_purchase: number
  quantity: number
  qntd: number[]
  restaurant_id: number
  restaurant_name: string
}

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { CartContext } from '../../contexts/CartContext';

function Cart() {

  const { user } = useContext(LoginContext)
  const { cart, totalPrice, load, updateQuantity, deleteCuponOfCart, } = useContext(CartContext)

  const URL = process.env.REACT_APP_URL_AWS_S3



  if(!cart || !user || !user?.token || user?.token !== process.env.REACT_APP_USER_TOKEN || !user?.Carts) {
    return (
      <Container>
        <CursorMask />
        <Header />
        <Content>
          <Title>
            <CartIcon />
            <h2>Carrinho</h2>
          </Title>
        </Content>
        <Footer />
      </Container>
    )
  }


  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <CartIcon />
          <h2>Carrinho</h2>
        </Title>
        <CartTable>
          <div className="header">
            <h2 className='quantity'>Quantidade</h2>
            <h2 className='price'>Preço</h2>
          </div>
          {
            !load ?
            (
              cart?.map((item: itemCupon) => (
                <div key={item.id} className="product">
                  <Product to={`/cupon/${item.cupon_id}`} className="info">
                    <img src={`${URL}/${item.picture}`} alt={item.plate_name} />
                    <div className="text">
                      <h2>{item.plate_name}</h2>
                      <h4>ID do Cupon: {item.cupon_id}</h4>
                      <h3>Restaurante: {item?.restaurant_name ? item.restaurant_name.replace("Restaurante", "").replace("restaurante", "").replace("RESTAURANTE", "") : null}</h3>
                    </div>
                  </Product>
                  <div className="quantity">
                    <select defaultValue={item.quantity ? item.quantity : 1} onChange={(event) => updateQuantity(Number(event.target.value), item.cupon_id)} name="quantity" id="quantity">
                      {
                        item.qntd.map(item => <option key={item} value={item}>{item}</option>)
                      }
                    </select>

                    <h4 onClick={() => deleteCuponOfCart(item.cupon_id)}>Excluir</h4>
                  </div>
                  <div className="price">
                    <h2>R$ {String((item.price_purchase * item.quantity).toFixed(2)).replace(".", ",")}</h2>
                    <h3>R$ {String(item.price_purchase.toFixed(2)).replace(".", ",")} por cada cupon</h3>
                  </div>
                </div>
              ))
            ): <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} />
          }
          <div className="footer">
            <h2 className="items">Total <span>{load ? "(0 items)" : `(${cart.length} items)`}</span></h2>
            <div className="total">
              <h2>Preço total</h2>
              <h3>R$ { load ? "0,00" : String(totalPrice.toFixed(2)).replace(".", ",")}</h3>
              {
                totalPrice > 0 ?
                <h4>(Em até 12x com juros no Cartão de Crédito)</h4>
                : null
              }
              <ButtonBuy to={cart.length >= 1 ? "/pagamento" : "/cart"} className={cart.length >= 1 ? "" : "block"}>
                Comprar
              </ButtonBuy>
            </div>
          </div>
        </CartTable>
      </Content>
      <Footer />
    </Container>
  )
}

export default Cart
