import React, { useContext, useState, useEffect } from 'react'
import { Container, Content, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import CursorMask from '../../components/CursorMask';
import { LoginContext } from '../../contexts/LoginContext';
import Footer from '../../components/Footer';


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

import { initMercadoPago, StatusScreen } from '@mercadopago/sdk-react';
import { useParams } from 'react-router-dom';
import { statusPaymentRestaurant } from '../../services/api';
import { toast } from 'react-toastify';
function StatusPayment() {

  const { idPayment } = useParams()
  const { user } = useContext(LoginContext)

  const [load, setLoad] = useState(true)
  const [data, setData] = useState()

  const getStatus = async () => {
    if (!idPayment) {
      toast.warn("Forneça um id de pagamento no parâmetro")
      return
    }
    setLoad(true)
    const [code, response] = await statusPaymentRestaurant(idPayment)

    if(code === 200) {
      setData(response)
      return
    }

    toast.error("Status de Pagamento não encontrado")
    setLoad(false)
  }

  useEffect(() => {
    getStatus()
  }, [idPayment])

  useEffect(() => {
    if(data && data?.public_key) {
      initMercadoPago(data?.public_key, { locale: 'pt-BR' })
      setLoad(false)
    }
  }, [data])



  const customization = {
    visual: {
      style: {
        theme: 'bootstrap',
        customVariables: {
          baseColor: theme.colors.lightGreen,
          textPrimaryColor: theme.colors.text,
          textSecondaryColor: theme.colors.gray400,
          errorColor: theme.colors.primary,
          successColor: theme.colors.lightGreen,
          baseColorSecondVariant: theme.colors.lightBlue,
          borderRadiusSmall: "4px",
          borderRadiusMedium: "4px",
          borderRadiusLarge: "4px",
          borderRadiusFull: "4px",
        },
      },
    },
  }

  if(!user || !user?.token || user?.token !== process.env.REACT_APP_USER_TOKEN || !user?.Carts) {
    return (
      <Container>
        <CursorMask />
        <Header />
        <Content>
          <Title>
            <CardIcon/>
            <h2>Status Pagamento</h2>
          </Title>
        </Content>
        <Footer />
      </Container>
    )
  }


  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <CardIcon />
          <h2>Status Pagamento</h2>
        </Title>
        {
          idPayment && !load ? <StatusScreen initialization={{
              paymentId: idPayment
            }}
            customazation={customization}
          /> : <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} />
        }
      </Content>
      <Footer />
    </Container>
  )
}

export default StatusPayment
