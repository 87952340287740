import styled from "styled-components";
import { theme } from "../../../../styles/Theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 102.6%;
  height: 100%;
  right: -2.6%;
  border-radius: 16px;
  position: absolute;

  .content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .slider{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      width: 460px !important;
      max-width: 85vw !important;
    }
  }
`;

export const NavigationDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 2.6%;
  bottom: -20px;
  z-index: 99;

  svg{
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: ${theme.colors.gray200};
    border-radius: 50%;
    transition: all .4s;

    path{
      stroke: ${theme.colors.gray400};
      transition: all .4s;
    }
    rect{
      fill: transparent
    }

    &.disable{
      path{
        stroke: ${theme.colors.gray200};
      }
    }

    &:hover{
      path{
        stroke: ${theme.colors.text};
      }
    }
  }

`;
