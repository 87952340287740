import React from 'react'
import { AddToCart, Container, Content } from './styles'
import {ReactComponent as AddCartIcon} from "../../assets/icons/shopping-cart-add.svg"

import { AiOutlineHeart } from "react-icons/ai"

function ItemLayoutLoad() {
  return (
    <Container>
      <AiOutlineHeart className='like' />
      <Content>
        <div className="img" />
        <div className="title">
          <div className="span" />
          <div className="discount" />
        </div>

        <div className="priceInfo">
          <div className="info border">
            <div className="span-2" />
            <div className="h3" />
          </div>

          <div className="info">
            <div className="span-2" />
            <div className="h3" />
          </div>
        </div>
      </Content>

      <AddToCart>
        <span>Comprar</span>
        <AddCartIcon />
      </AddToCart>
    </Container>
  )
}

export default ItemLayoutLoad
