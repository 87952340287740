import { useLocation } from 'react-router-dom'
import CursorMask from '../../components/CursorMask'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Highlights from './Highlights'
import Cupons from './Highlights copy'
import Home from './Home'
import Publicity from './Publicity'
import { Container } from './styles'
import { useEffect } from 'react'
// import purl from "purl";

export default function Main() {

  const location = useLocation()

  useEffect(() => {
    if(location.search.includes("code=")) {
      const split = location.search.split("&")
      split.forEach(item => item.includes("code=") ? (
        console.log("\n\n\ncode"),
        console.log(item.replace("?", "").replace("code=", ""), "\n\n\n")
      ) : null)
    }
  }, [location.search])

  return (
    <Container>
      <CursorMask />
      <Header />
      <Home />
      <Highlights />
      <Publicity />
      <Cupons />
      <Footer />
    </Container>
  )
}
