import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1{
    font-size: 56px;
    font-weight: 200;
    font-family: "NeueMachinaLight";
    color: ${theme.colors.text};
    width: 90%;
    max-width: 1000px;
    margin-top: 8vh;

    span{
      font-size: 80px;
      font-family: "NeueMachina";
      color: ${theme.colors.primary};
    }
  }
`;
