import React from 'react'
import { Card, Container, Gastronomy, Title } from './styles'

import { ReactComponent as HatIcon } from '../../../assets/icons/hat-chef.svg'
import Carousel from './Carousel/index'

function Home() {

  return (
    <Container>
      <Title>
        Encontre o <span>cuponzin</span> de desconto<br/>
        ideal para aproveitar uma experiência<br/>
        gastronômica em Florianópolis.
      </Title>

      <Gastronomy>
        <Card>
          <HatIcon />
          <h2>Gastronomia</h2>
        </Card>
        <Carousel />
      </Gastronomy>
    </Container>
  )
}

export default Home
