import React, { useContext, useState } from 'react'
import PropTypes from "prop-types";
import { Button, Container, Content, Form } from './styles'
import "./style.css";



import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { createPublicity, uploadfiles } from '../../services/api';
import { LoginContext } from '../../contexts/LoginContext';
import { toast } from 'react-toastify';

function ModalAddPublicity({ isOpen, onRequestClose }) {

  const { user } = useContext(LoginContext);

  const [info, setInfo] = useState("⠀")
  const [image, setImage] = useState()
  const [link, setLink] = useState("")
  const [init, setInit] = useState("")
  const [end, setEnd] = useState("")

  const [loading, setLoading] = useState()


  const send = async () => {
    if (!link || !init || !end || !image || image && image.length === 0){
      setInfo("Preecha todos os campos")
      return
    }

    if(!user || !user?.token || user?.token !== process.env.REACT_APP_ADMIN_TOKEN) {
      toast.error("Não foi possível adicionar a publicidade")
      return
    }

    setInfo("⠀")
    setLoading(true)

    const formData = new FormData();
    formData.append("file", image[0])

    const [code, response] = await uploadfiles(formData);

    if(code === 201) {

      const data = {
        picture: response.path,
        link,
        init: `${init}T03:00:00.000-03:00`,
        end: `${end}T03:00:00.000-03:00`,
      }

      const status = await createPublicity(data);

      if(status === 201) {
        toast.success("Publicidade adicionada")
        setLink("")
        setLoading(false)
        onRequestClose();
        return
      }

      if(status === 400) {
        setInfo("Erro ao criar cupons")
        toast.error("Erro ao criar cupons")
        setLoading(false)
        return
      }

      toast.error(`${status} ERROR`)
      setInfo(`${status} ERROR`)
      setLoading(false)
      return
    }

    toast.error(`${code} ERROR`)
    setInfo(`${code} ERROR`)
    setLoading(false)
  }


  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Adicionar Cupon</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <h4 style={{marginTop: "28px"}}>Link</h4>
              <input type="text" name="link" id="link"  maxLength={255} onChange={(event) => setLink(event.target.value)} />

              <h4 style={{marginTop: "28px"}}>Data de início</h4>
              <input type="date" name="init-date" id="init-date"  onChange={(event) => setInit(event.target.value)} />

              <h4 style={{marginTop: "28px"}}>Data final</h4>
              <input type="date" name="end-date" id="end-date"  onChange={(event) => setEnd(event.target.value)} />

              <Button onClick={send}>
                Enviar
              </Button>
            </Form>
          </Content>
        )
      }

    </Container>
  )
}

ModalAddPublicity.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalAddPublicity
