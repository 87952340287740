import React, { useContext, useState, useEffect } from 'react'
import { Button, Container, Field, InputWithMask } from './style.js'
import { LoginContext } from '../../../../contexts/LoginContext'
import { toast } from 'react-toastify';
import { updateRestaurant, uploadfiles } from '../../../../services/api';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from "../../../../styles/Theme"

function Info() {

  const { user } = useContext(LoginContext)

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [street, setStreet] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [number, setNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [load, setLoad] = useState(false)
  const [image, setImage] = useState()

  const update = async () => {
    if(!user || !user?.id || !user?.token || user.token !== process.env.REACT_APP_RESTAURANT_TOKEN) {
      toast.error("Erro ao atualizar dados")
      return
    }


    if(!name || !tel || !street || !neighborhood || !postalCode || !number){
      toast.warn("Preencha todos os campos")
      return
    }

    if(image && image.length > 0) {
      setLoad(true)

      const formData = new FormData();
      formData.append("file", image[0])

      const [code, response] = await uploadfiles(formData);

      if(code === 201) {

        setLoad(true)
        const status = await updateRestaurant(
          {
            name,
            tel,
            neighborhood,
            street,
            number,
            postal_code: postalCode,
            picture: response.path,
            previous_file: user?.picture ? user?.picture : undefined,
          }, user.id)
    
        if(status === 200) {
          toast.success("Informações atualizadas")
          setLoad(false)
          return
        }

        console.log("\n\n\nstatus", status)
    
        setLoad(false)
        toast.error("Erro ao atualizar informações")
        return
      }

      toast.error(`${code} ERROR`)
      setLoad(false)
      return
    }

    setLoad(true)
    const code = await updateRestaurant(
      {
        name,
        tel,
        neighborhood,
        street,
        number,
        postal_code: postalCode
      }, user.id)

    if(code === 200) {
      toast.success("Informações atualizadas")
      setLoad(false)
      return
    }

    setLoad(false)
    toast.error("Erro ao atualizar informações")
  }

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      setName(user?.name)
      setEmail(user?.email)
      setTel(user?.tel)
      setStreet(user?.street)
      setNeighborhood(user?.neighborhood)
      setNumber(user?.number)
      setPostalCode(user?.postal_code)
    }
  }, [user])

  return (
    <Container>
      {
        load ? <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} /> :
        (
          <>
            <Field>
              <h3>Foto do Restaurante</h3>
              <input type="file" name="foto-fachada" id="foto-fachada" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />
            </Field>

            <Field>
              <h3>Nome</h3>
              <div className="field">
                <input type='text' name='name' id='name' value={name} onChange={(event) => setName(event.target.value)} placeholder='Nome' />
              </div>
            </Field>

            <Field>
              <h3>E-mail</h3>
              <div className="field">
                <input type='email' name='email' id='email' value={email} placeholder='E-mail' disabled />
              </div>
            </Field>

            <Field>
              <h3>Telefone</h3>
              <div className="field">
                <InputWithMask mask="(99) 9 9999-9999" type='text' name='tel' id='tel' value={tel} onChange={(event) => setTel(event.target.value)} placeholder='(00) 0 0000-0000' />
              </div>
            </Field>

            <Field>
              <h3>Rua</h3>
              <div className="field">
                <input type='text' name='street' id='street' value={street} onChange={(event) => setStreet(event.target.value)} placeholder='Rua' />
              </div>
            </Field>

            <Field>
              <h3>Bairro</h3>
              <div className="field">
                <input type='text' name='neighborhood' id='neighborhood' value={neighborhood} onChange={(event) => setNeighborhood(event.target.value)} placeholder='Bairro' />
              </div>
            </Field>

            <Field>
              <h3>Número</h3>
              <div className="field">
                <input type='number' name='number' id='number' value={number} onChange={(event) => setNumber(event.target.value)} placeholder='Número' />
              </div>
            </Field>

            <Field>
              <h3>CEP</h3>
              <div className="field">
                <InputWithMask mask="99999-999" type='text' name='cep' id='cep' value={postalCode} onChange={(event) => setPostalCode(event.target.value)} placeholder='00000-000' />
              </div>
            </Field>



            <Button type='button' onClick={update}>Atualizar</Button>
          </>
        )
      }
    </Container>
  )
}

export default Info
