import React, { useEffect, useState } from 'react'
import { Container, Content, PageNavigations, List } from './style'
import CursorMask from '../../components/CursorMask'
import Header from '../../components/Header'
import { useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';

import placeholder from "../../assets/images/placeholder.webp"
import ItemLayoutLoad from '../../components/ItemLayoutLoad'
import { getCupons, getRestaurantPage } from '../../services/api'
import { toast } from 'react-toastify'
import ItemLayout from '../../components/ItemLayout'

type restaurantType = {
  id: number
  name: string
  tel: string
  street: string
  number: number
  postal_code: string
  neighborhood: string
  count: number
  open: boolean
  picture: string
}

type cuponType = {
  id: number,
  picture: string
  price_of: number
  price_purchase: number
  plate_name: string
}



function RestaurantPage() {

  const [page, setPage] = useState<number>(1);
  const [load, setLoad] = useState<boolean>(true);
  const [loadCupons, setLoadCupons] = useState<boolean>(true);
  const [data, setData] = useState<restaurantType>();

  const { restaurant } = useParams() 

  const [cupons, setCupons] = useState<cuponType[]>([]);

  const URL = process.env.REACT_APP_URL_AWS_S3

  const getCuponsSearch = async () => {
    setLoadCupons(true)
    let search = `&restaurant_id=${restaurant}`
    search += `&page=${page}`

    const result = await getCupons(search)

    if (Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as cuponType[];

      if(code === 200){
        setCupons(response)
        setLoadCupons(false)
        return
      }
      toast.error("Error ao procurar cupons")
      setLoadCupons(false)
    }
  }

  const getDataRestaurant = async () => {
    setLoad(true)
    const result = await getRestaurantPage(Number(restaurant))

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number
      const response = result[1] as restaurantType

      if(code === 200) {
        setData(response)
        getCuponsSearch()
        setLoad(false)
        return
      }

      toast.error("Error ao buscar restaurante")
      return
    } 

    toast.error("Error ao buscar restaurante")
    return
  }

  useEffect(() => {
    if(restaurant && Number(restaurant)) {
      getDataRestaurant()
    }
  }, [restaurant])

  useEffect(() => {
    if(restaurant && Number(restaurant) && data && data?.id) {
      getCuponsSearch()
    }
  }, [page])
  
  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        {
          load ? 
          (
            <div className="img-info-load">
              <div className="img" />
              <div className="text">
                <div className="h1" />
                <div className="status" />

                <div className="line">
                  <div className="info">
                    <div className="h4" />
                    <div className="h3" />
                  </div>

                  <div className="info">
                    <div className="h4" />
                    <div className="h3" />
                  </div>
                </div>

                <div className="line">
                  <div className="info">
                    <div className="h4" />
                    <div className="h3" />
                  </div>
                  
                  <div className="info">
                    <div className="h4" />
                    <div className="h3" />
                  </div>
                </div>
              </div>
            </div>
          ) : 
          (
            <div className="img-info">
              <img src={data?.picture ? `${URL}/${data?.picture}` : placeholder} alt="Fachada Restaurante" />

              <div className="text">
                <h1>{data?.name}</h1>

                <div className={data?.open ? "status" : "status closed"} >{data?.open ? "Aberto" : "Fechado"}</div>

                <div className="line">
                  <div className="info">
                    <h4>Rua</h4>
                    <h3>{data?.street} - {data?.number}</h3>
                  </div>

                  <div className="info">
                    <h4>Bairro</h4>
                    <h3>{data?.neighborhood}</h3>
                  </div>
                </div>

                <div className="line">
                  <div className="info">
                    <h4>CEP</h4>
                    <h3>{data?.postal_code}</h3>
                  </div>

                  <div className="info">
                    <h4>Telefone</h4>
                    <h3>{data?.tel}</h3>
                  </div>
                </div>
              </div>


            </div>
          )
        }

        <h1><span>{data?.count}</span> Cupons encontrados desse restaurante</h1>

        <List>
          {
            loadCupons ? 
            (
              <>
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
              </>
            ) : 
            cupons.map((item) => (
              <ItemLayout
                key={item.id}
                id={item.id}
                picture={item.picture}
                name={item.plate_name}
                priceOf={item.price_of}
                pricePurchase={item.price_purchase}
              />
            ))
          }
        </List>

        <PageNavigations>
          <h3>Página</h3>
          <div className="navigation">
            <ArrowLeftIcon
              className={page <= 1 ? "block" : ""}
              onClick={() => page <= 1 ? null : setPage(page - 1)}
            />
            <span>{String(page).padStart(2, "0")}</span>
            <ArrowRightIcon
              className={cupons.length < 20 ? "block" : ""}
              onClick={() => cupons.length < 20 ? null : setPage(page + 1)}
            />
          </div>
        </PageNavigations>

        <iframe
          width="100%"
          style={{ border: "none", marginTop: "8vh", borderRadius: "4px"}}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
            &q=Restaurante+${data?.name}, ${data?.street} - ${data?.number}, ${data?.postal_code}`}>
        </iframe>
      </Content>
      <Footer />
    </Container>
  )
}

export default RestaurantPage