import React, { useState, useEffect } from 'react'
import { Container, Content, Field, Line, MenuInfo, OpInfo, Title } from './style';
import Header from '../../components/Header';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';

import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { Outlet, useLocation } from 'react-router-dom';

function Profile() {

  const location = useLocation()
  const [page, setPage] = useState<string>("")

  useEffect(() => {
    if(location.pathname === "/perfil/seguran%C3%A7a") {
      setPage("security")
      return
    }
  }, [])

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <UserIcon />
          <h2>Perfil</h2>
        </Title>

        <MenuInfo>
          <OpInfo to="/perfil/info" onClick={() => setPage("")}>Informações da Conta</OpInfo>
          <OpInfo to="/perfil/segurança" onClick={() => setPage("security")}>Trocar Senha</OpInfo>
          <Line style={page === "security" ? {left: "216px"} : {left: "0px"}} />
        </MenuInfo>

        <Field>
          <Outlet />
        </Field>
      </Content>
      <Footer />
    </Container>
  )
}

export default Profile
