import React, { useContext, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { AddToCart, Button, Container, Content, ContentItem, Form, Item, Preview } from './styles'
import "./style.css";

import {ReactComponent as LikeIcon} from "../../assets/icons/heart.svg"
import {ReactComponent as CartIcon} from "../../assets/icons/shopping-cart-add.svg"


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { getEspecifyCoupon, updateCuponsAdmin, uploadfiles } from '../../services/api';
import { LoginContext } from '../../contexts/LoginContext';
import { toast } from 'react-toastify';

function ModalEditCuponAdmin({ isOpen, onRequestClose, cuponId }) {

  const { user } = useContext(LoginContext);

  const [info, setInfo] = useState("⠀")
  const [name, setName] = useState("")
  const [priceOf, setPriceOf] = useState("")
  const [pricePurchase, setPricePurchase] = useState("")
  const [image, setImage] = useState()
  const [imagePreview, setImagePreview] = useState()

  const [cuponData, setCuponData] = useState([])

  const [loading, setLoading] = useState()

  const URL = process.env.REACT_APP_URL_AWS_S3

  useEffect(() => {
    setImagePreview(false)
  }, [isOpen])

  useEffect(() => {
    if(!image || image && image.length === 0){
      return
    }

    const file = image[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64 = reader.result
      setImagePreview(base64)
    }

    reader.readAsDataURL(file)
  }, [image])

  const getCupon = async () => {
    setCuponData()
    if(!cuponId) {
      return
    }
    setLoading(true)

    const [code, response] = await getEspecifyCoupon(cuponId)

    if(code === 200) {
      setCuponData(response)
      setName(response.plate_name)
      setPriceOf(Number(response.price_of))
      setPricePurchase(Number(response.price_purchase))
      setLoading(false)
      return
    }

    toast.error("Erro ao obter dados do cupon")
    onRequestClose()
    setLoading(false)
  }

  useEffect(() => {
    getCupon()
  }, [cuponId])

  const send = async () => {
    if (!image || image.length <= 0){
      toast.warn("Coloque uma imagem")
      return
    }
    if(!user || !user?.token || user?.token.split("###")[0] !== process.env.REACT_APP_ADMIN_TOKEN){
      toast.warn("Não foi possível atualizar o cupon, tente novamente mais tarde")
      return
    }

    setInfo("⠀")
    setLoading(true)

    const formData = new FormData();
    formData.append("file", image[0])


    const [code, response] = await uploadfiles(formData);

    if(code === 201) {

      const data = {
        picture: response.path,
        previous_file: cuponData?.picture
      }

      const status = await updateCuponsAdmin(data, cuponId);

      if(status === 200) {
        toast.success("Cupon atualizado")
        setLoading(false)
        onRequestClose();
        return
      }

      if(status === 400) {
        setInfo("Erro ao atualizar cupons")
        toast.error("Erro ao atualizar cupons")
        setLoading(false)
        return
      }

      toast.error(`${status} ERROR`)
      setInfo(`${status} ERROR`)
      setLoading(false)
      return
    }

    toast.error(`${code} ERROR`)
    setInfo(`${code} ERROR`)
    setLoading(false)
    return
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Atualizar Cupon</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <Button onClick={send}>
                Atualizar
              </Button>
            </Form>

            <Preview>
              <h4>Pré-visualização</h4>

              <Item>
                <LikeIcon className='like' />
                <ContentItem>
                  <img src={imagePreview ? imagePreview : `${URL}/${cuponData?.picture}`} alt={cuponData?.plate_name} />
                  <div className="title">
                    <span className='plate'>{name ? name : "⠀"}</span>
                    <div className="discount">{ priceOf && pricePurchase ? String(((pricePurchase * 100) / priceOf - 100).toFixed(0)).replace("-", "") : "0"}%</div>
                  </div>
                  <div className="priceInfo">
                    <div className="info border">
                      <span>Economize</span>
                      <h3>R$ {priceOf && pricePurchase ? String((priceOf - pricePurchase).toFixed(2)).replace(".", ",") : "0,00"}</h3>
                    </div>

                    <div className="info">
                      <span>De R${priceOf ? priceOf.toFixed(0) : 0} por</span>
                      <h3>R$ {pricePurchase ? String(pricePurchase.toFixed(2)).replace(".", ",") : "0,00"}</h3>
                    </div>
                  </div>
                </ContentItem>
                <AddToCart>
                  <span>Comprar</span>
                  <CartIcon />
                </AddToCart>
              </Item>
            </Preview>
          </Content>
        )
      }

    </Container>
  )
}

ModalEditCuponAdmin.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cuponId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalEditCuponAdmin
