import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.text};
  transition: all .4s;
  margin-bottom: 20px;

  &.wrong{
    border-color: ${theme.colors.primary};
    label, input {
      color: ${theme.colors.primary};
    }
  }
`;

export const Title = styled.label`
  font-size: 14px;
  font-weight: 200;
  font-family: "NeueMachinaLight";
  color: ${theme.colors.text};
  position: absolute;
  top: 20px;
  left: 16px;
  transition: all .4s;
  z-index: 99;
  pointer-events: none;
`;

export const Input = styled.input`
  width: 80%;
  height: 24px;
  outline: none;
  font-size: 14px;
  font-weight: 200;
  padding-left: 6px;
  font-family: "NeueMachinaLight";
  color: ${theme.colors.text};
  border: none;
  outline: none;
  background-color: transparent;



  &:focus ~ label , &:active ~ label{
    top: 0;
    left: 0;
    font-size: 10px;
  }
`;

export const Button = styled.div`
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    width: 28px;
    height: 28px;

    path{
      fill: ${theme.colors.text};
    }
  }
`;
