import axios, {AxiosRequestConfig} from 'axios'

//const baseURL: string | undefined = "https://cr-api-cuponzin.onrender.com/"
const baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL
const secret: string | undefined = process.env.REACT_APP_API_SECRET

let config: AxiosRequestConfig | undefined
let configUpload: AxiosRequestConfig | undefined

if(secret !== undefined) {
  config = {
    headers: {
      "authorization": secret,
      "Content-Type": "application/json"
    }
  };
  configUpload = {
    headers: {
      "authorization": secret,
      "Content-Type": "application/json"
    }
  };
} else {
  console.error("ERROR: THE API_SECRET IS UNDEFINED.")
}






// ======================== USERS ========================

export async function verifyEmail(data: {email: string}) {
  if(config !== undefined) {
    const code = await axios.post(`${baseURL}verify_email_exist`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return code
  }
}

export async function codeConfirmation(data: {name: string, email: string}) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}send_mail_confirmation`, data, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function proofRestaurant(data: {name: string, email: string, picture: string, type: string, value: number, month: string }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}send_mail_payment`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return responseData
  }
}

export async function statusPayment(data: {name: string, email: string, id: number }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}send_mail_status_payment`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return responseData
  }
}

export async function verifyCodeConfirm(data: {hash: string, code: string}) {
  if(config !== undefined) {
    const code = await axios.post(`${baseURL}verify_code`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return code
  }
}

export async function createUser(data: {name: string, email: string, password: string, tel: string, token?: string}) {
  if(config !== undefined) {
    const code = await axios.post(`${baseURL}users`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return code
  }
}

export async function updateUser(data: {name?: string, email?: string, password?: string, tel?: string, token?: string}, id: number) {
  if(config !== undefined) {
    const code = await axios.put(`${baseURL}users/${id}`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return code
  }
}

export async function getUser(id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}users/${id}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}


// ======================== RESTAURANTS ==================

export async function getAllRestaurants(search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}restaurants?${search}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function getRestaurant(id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}restaurants/${id}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function getRestaurantPage(id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}restaurant_page/${id}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function createRestaurant(data: {name: string, email: string, password: string, tel: string, token?: string, street: string, neighborhood: string, number: number, postal_code: string}) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}restaurants`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return responseData
  }
}

export async function updateRestaurant(data: {name?: string, email?: string, password?: string, tel?: string, token?: string, street?: string, neighborhood?: string, number?: number, postal_code?: string, open?: boolean, picture?: string}, id: number) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}restaurants/${id}`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return responseData
  }
}

// ======================== Login ========================

export async function login(data: {email: string, password?: string, password_hash?: string}) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}login`, data, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}


// ======================== FILES ========================

export async function uploadfiles(data: FormData) {
  if(configUpload !== undefined) {
    const responseData = await axios.post(`${baseURL}files`, data, configUpload)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

// ======================== categories ========================

export async function getCategories() {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}categories`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function getCategory(id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}categories/${id}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function createCategory(data: {name: string, picture: string}) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}categories`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function updateCategory(data: {name?: string, picture?: string}, id: number) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}categories/${id}`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function deleteCategory(id: number) {
  if(config !== undefined) {
    const responseData = await axios.delete(`${baseURL}categories/${id}`, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}


// ======================== Cupons ========================

export async function getCupons(search?: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}coupons?${search}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}
export async function getCuponsList(search?: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}coupons_list?${search}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function getEspecifyCoupon(id: number, search?: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}coupon/${id}?search=${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function createCupons(data: { plate_name: string, picture: string, category: string, price_of: number, price_purchase: number, quantity: number, restaurant_id: number }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}coupons`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function updateeCupons(data: { plate_name?: string, picture?: string, category?: string, price_of?: number, price_purchase?: number, quantity?: number, restaurant_id?: number }, id: number) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}coupons/${id}`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function updateCuponsAdmin(data: { picture: string }, id: number) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}coupons_admin/${id}`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function deleteCupons(id: number) {
  if(config !== undefined) {
    const responseData = await axios.delete(`${baseURL}coupons/${id}`, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

// ======================== Code ========================

export async function getCodes(restaurantId: number, search?: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}codes/${restaurantId}?${search}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function getCodeCuponData(data: { code: string, restaurant_id: number}) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}coupon_code/${data.code}?restaurant_id=${data.restaurant_id}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function validateCoupon(data: { code: string, restaurant_id: number }) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}validate_coupon`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}


// ======================== Cart ========================
export async function addCart(data: { cupon_id: number, user_id: number, quantity: number }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}cart`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function showCupon(id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}cart/${id}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function updateQuantityCupon(data: { cupon_id: number, user_id: number, quantity: number }) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}cart`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function buyCupons(data: { cupon_id: number, user_id: number, quantity: number }) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}codes`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function cuponsUser(user_id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}codes_user/${user_id}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function deleteCupon(data: { cupon_id: number, user_id: number }) {
  if(config !== undefined) {
    const responseData = await axios.delete(`${baseURL}cart/${data.cupon_id}/${data.user_id}`, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

// ======================== Ratings ========================
export async function createRatings(data: { cupon_id: number, user_id: number, name: string, stars: number, text: string }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}ratings`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function deleteRate(id: number) {
  if(config !== undefined) {
    const responseData = await axios.delete(`${baseURL}ratings/${id}`, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

// ======================== Favories ========================
export async function getFavoritesUser(userId: number, search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}favorites/${userId}?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function addFavorites(data: { cupon_id: number, user_id: number }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}favorites`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function removeFavorites(data: { cupon_id: number, user_id: number }) {
  if(config !== undefined) {
    const responseData = await axios.delete(`${baseURL}favorites/${data.user_id}/${data.cupon_id}`, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

// ======================== Payment ========================

export async function payment(data: { token: string, transaction_amount: number, description: string, installments: number, payment_method_id: string}) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}payment`, data, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}


// ======================== Forgot Password ========================

export async function confirmEmailToSwitchPassword(data: { email: string }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}confirm_email_forgot_password`, data, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function confirmCodeToSwitchPassword(data: { code: string, hash: string }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}send_code_forgot_password`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function switchPassword(data: { email: string, password: string }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}switch_password_forgot_password`, data, config)
    .then(response => response.status)
    .catch(error => error.response.status)

    return responseData
  }
}


// ============================= PUBLICITY ============================

export async function getPublicity(search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}publicity?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function showPublicity(id: number) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}publicity/${id}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function createPublicity(data: { picture: string, link: string, init: string, end: string }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}publicity`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function updatePublicity(data: { picture?: string, link?: string, init?: string, end?: string }, id: number) {
  if(config !== undefined) {
    const responseData = await axios.put(`${baseURL}publicity/${id}`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}

export async function deletePublicity(id: number) {
  if(config !== undefined) {
    const responseData = await axios.delete(`${baseURL}publicity/${id}`, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}


// ================================ SALES ======================================

export async function salesRestaurant(id: number, search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}sales/${id}?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function salesRestaurantValues(id: number, search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}sales_resturant/${id}?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function salesAdmin(search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}sales_admin?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function valuesAdmin(search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}values_admin?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

// ================================ PAYMENT RESTAURANT ======================================

export async function paymentRestaurant(id: number, search: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}payment_restaurant/${id}?${search}`, config)
    .then(response => [response.status, response.data])
    .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function paymentRestaurantAdmin(data: { protocol: string, restaurant_id: number, money_restaurant: number, month: string, year: string, total: number }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}payment_restaurant`, data, config)
      .then(response => response.status)
      .catch(error => error.response.status)

    return responseData
  }
}


export async function stripePaymentIntent(data: { amount: number }) {
  if(config !== undefined) {
    const responseData = await axios.post(`${baseURL}stripe-payment-intent`, data, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}

export async function statusPaymentRestaurant(id: string) {
  if(config !== undefined) {
    const responseData = await axios.get(`${baseURL}status_payment/${id}`, config)
      .then(response => [response.status, response.data])
      .catch(error => [error.response.status, error.response.data])

    return responseData
  }
}