import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Results = styled.section`
  width: 95%;
  display: flex;
  flex-direction: column;
  margin-top: 8vh;
  margin-bottom: 88px;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 4vh;

  h1{
    font-size: 22px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;

    span{
      color: ${theme.colors.primary};
    }
  }

  h2{
    font-size: 18px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.gray400};
    margin: 0;
  }
`;

export const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 256px));
  grid-auto-rows: 340px;
  grid-auto-columns: 256px;
  row-gap: 24px;
  column-gap: 16px;
  grid-auto-flow: dense;
  justify-content: space-between;

  .item{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

  }

  @media (max-width: 556px) {
    &{
      grid-template-columns: 100%;
    }
  }
`;

export const PageNavigations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 4vh;

  h3{
    font-size: 20px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
    margin-bottom: 8px;
  }

  div.navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    span {
      font-size: 20px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      margin: 0;
      text-align: center;
    }

    svg{
      height: 40px;
      width: 40px;
      cursor: pointer;
      background-color: ${theme.colors.gray200};
      border-radius: 50%;
      transition: all .4s;

      path{
        stroke: ${theme.colors.gray400};
        transition: all .4s;
      }
      rect{
        fill: transparent
      }

      &.block{
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:hover{
        background-color: ${theme.colors.primary};
        path{
          stroke: ${theme.colors.white};
        }
      }
    }
  }
`;
