import React from 'react'
import { Container, Content, Text, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as Icon } from '../../assets/icons/hat-chef.svg';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';


function UseTerms() {

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <Icon />
          <h2>Termos de Uso</h2>
        </Title>

        <Text>
          Bem-vindo à plataforma de cupons de desconto em pratos de restaurantes da Cuponzin. Ao utilizar nossos serviços, você concorda com os seguintes termos. É importante ler atentamente este documento:
          <br/><br/>
          <span>1. Uso do Serviço:</span> Nossa plataforma é destinada exclusivamente para uso pessoal e não comercial. Ao acessar nossos cupons de desconto, você concorda em utilizá-los conforme as diretrizes estabelecidas.
          <br/><br/>
          <span>2. Cadastro:</span> Para acessar determinadas áreas e benefícios da plataforma, pode ser necessário criar uma conta. Você é responsável por manter a confidencialidade das informações da sua conta e por todas as atividades realizadas por meio dela.
          <br/><br/>
          <span>3. Cupons e Ofertas:</span> Os cupons de desconto disponibilizados em nossa plataforma são fornecidos por restaurantes parceiros. Reservamo-nos o direito de alterar, suspender ou encerrar qualquer oferta sem aviso prévio. Os cupons estão sujeitos a disponibilidade e podem ter condições específicas de uso.
          <br/><br/>
          <span>4. Responsabilidade do Usuário:</span> Ao utilizar nossos serviços, você concorda em respeitar todas as leis aplicáveis e não utilizar a plataforma para qualquer fim ilegal ou não autorizado.
          <br/><br/>
          <span>5. Privacidade:</span> Comprometemo-nos a proteger suas informações pessoais conforme descrito em nossa <a href="./politicas-de-privacidade">Política de Privacidade</a>. Ao utilizar nossos serviços, você concorda com a coleta, uso e divulgação de suas informações conforme estabelecido nesse documento.
          <br/><br/>
          <span>6. Propriedade Intelectual:</span> Todos os direitos autorais, marcas registradas, patentes e outros direitos de propriedade intelectual relacionados à nossa plataforma são de nossa propriedade ou licenciados para nós.
          <br/><br/>
          <span>7. Limitação de Responsabilidade:</span> Não nos responsabilizamos por quaisquer danos diretos, indiretos, acidentais, consequenciais ou especiais decorrentes do uso ou incapacidade de usar nossos serviços.
          <br/><br/>
          <span>8. Alterações nos Termos:</span> Reservamo-nos o direito de atualizar ou modificar estes Termos de Uso a qualquer momento. A versão revisada entrará em vigor assim que for publicada na plataforma. É responsabilidade do usuário revisar periodicamente os termos atualizados.
          <br/><br/>
          Ao utilizar nossos serviços, você concorda com estes termos. Se tiver dúvidas ou preocupações, entre em contato conosco através dos canais fornecidos.
        </Text>

        

      </Content>
      <Footer />
    </Container>
  )
}

export default UseTerms
