import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const Container = styled.section`
  width: calc(100vw - 241px - 24px);
  min-height: calc(100vh - 24px);
  max-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: -24px;
  margin-bottom: -24px;
`;

export const AddCoupon = styled.button`
  height: 48px;
  min-height: 48px;
  padding: 0px 32px;
  width: fit-content;
  border-radius: 8px;
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin-bottom: 24px;

  &:active{
    background-color: ${theme.colors.lightBlue};
  }
`;

export const Title = styled.h3`
  width: calc(100vw - 241px - 72px);
  font-size: 16px;
  font-family: "NeueMachinaLight";
  color: ${theme.colors.black};
  margin: 0;
  padding-left: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.gray200};
  margin-bottom: 16px;
`;

export const Show = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  margin-bottom: 16px;
  cursor: pointer;

  h2{
    font-size: 20px;
    font-family: "NeueMachinaLight";
    color: ${theme.colors.black};
    margin: 0;
  }

  svg {
    height: 24px;
    width: 24px;

    path { fill: ${theme.colors.black}}
  }
`;

export const Input = styled.div`
  width: calc(100vw - 241px);
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  background-color: ${theme.colors.white};
  margin: 16px 0;
  display: flex;
  align-items: center;

  input{
    width: 98%;
    margin-left: 8px;
    height: 32px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.black};

    &::placeholder{
      color: ${theme.colors.gray200};
    }
  }
`;

export const CuponsList = styled.div`
  width: calc(100vw - 241px - 48px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray400};
  }
`;

export const Coupon = styled.div`
  height: 64px;
  min-height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.gray400};
  position: relative;
  cursor: pointer;

  h1{
    font-size: 20px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
    margin-left: 16px;
  }

  svg {
    height: 24px;
    width: 24px;
    padding: 4px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: ${theme.colors.lightGreenBG};

    path { stroke: ${theme.colors.lightGreen}}

    &.not-used{
      background-color: ${theme.colors.primaryBG};
      path { stroke: ${theme.colors.primary}}
    }
  }
`;

export const LoadMore = styled.div`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin: 24px 0;
`;
