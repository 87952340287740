import styled from "styled-components";
import { theme } from "../../styles/Theme";
import { NavLink } from "react-router-dom";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 32px;
  background-color: ${theme.colors.white};
`;

export const Content = styled.section`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
`;

export const Col = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    width: 45%;
  }

  @media (max-width: 550px) {
    width: 100%;
    align-items: center;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  color: ${theme.colors.text};
  font-weight: 400;
  font-family: "NeueMachinaLight";
  margin: 0;
`;

export const LinkFooter = styled(NavLink)`
  font-size: 16px;
  text-decoration: none;
  color: ${theme.colors.gray400};
  font-weight: 100;
  font-family: "NeueMachinaLight";
  margin: 0;
  transition: all .4s;

  &:hover{
    color: ${theme.colors.lightGreen};
  }
`;

export const Open = styled.h3`
  font-size: 16px;
  text-decoration: none;
  color: ${theme.colors.gray400};
  font-weight: 100;
  font-family: "NeueMachinaLight";
  margin: 0;
  transition: all .4s;
  cursor: pointer;

  &:hover{
    color: ${theme.colors.lightGreen};
  }
`;

export const Media = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const LinkMedia = styled(NavLink)`
  text-decoration: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.gray400};
  transition: all .4s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    height: 18px;
    width: 18px;

    path {
      fill: ${theme.colors.gray400};
      transition: all .4s;
    }
  }

  &:hover{
    border-color: ${theme.colors.primary};
    path { fill: ${theme.colors.primary}; }
  }
`;

export const Section2 = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.gray200};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span{
    font-size: 14px;
    color: ${theme.colors.text};
    font-weight: 100;
    font-family: "NeueMachinaLight";
    margin: 0;
  }
`;
