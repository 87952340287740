import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { Button, Container, Form } from './styles'
import "./style.css";

import {ReactComponent as Logo } from "../../assets/images/Salmao.svg"
import InputField from '../InputField';
import InputMaskField from '../InputMaskField';
import PasswordField from '../PasswordField';
import { createRestaurant, verifyEmail } from '../../services/api';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { toast } from 'react-toastify';

function ModalRegisterRestaurant({ isOpen, onRequestClose }) {

  const [info, setInfo] = useState("⠀")
  const [wrong, setWrong] = useState(false)
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState()
  const handleName = (value) => {
    setName(value)
  }

  const [tel, setTel] = useState()
  const handleTel = (value) => {
    setTel(value)
  }

  const [email, setEmail] = useState()
  const handleEmail = (value) => {
    setEmail(value)
  }


  const [publicKey, setPublicKey] = useState()
  const handlePublicKey = (value) => {
    setPublicKey(value)
  }

  const [accessToken, setAccessToken] = useState()
  const handleAccessToken = (value) => {
    setAccessToken(value)
  }

  const [street, setStreet] = useState()
  const handleStreet = (value) => {
    setStreet(value)
  }

  const [neighborhood, setNeighborhood] = useState()
  const handleNeighborhood = (value) => {
    setNeighborhood(value)
  }

  const [number, setNumber] = useState()
  const handleNumber = (value) => {
    setNumber(value)
  }

  const [postalCode, setPostalCode] = useState()
  const handlePostalCode = (value) => {
    setPostalCode(value)
  }

  const [password, setPassword] = useState()
  const handlePassword = (value) => {
    setPassword(value)
  }

  const [confirmpassword, setConfirmPassword] = useState()
  const handleConfirmPassword = (value) => {
    setConfirmPassword(value)
  }


  useEffect(() => {
    if(password === confirmpassword) setWrong(false)
    else setWrong(true)
  }, [password, confirmpassword])


  const create = async (event) => {
    event.preventDefault()
    setInfo("⠀")
    if(!name || !tel || !email || !password || !confirmpassword || !street || !neighborhood || !number || !postalCode || !accessToken || !publicKey) {
      setInfo("Por favor! Preencha todos os campos")
      return
    }
    if(!(email.includes("@")) || !(email.includes("."))){
      setInfo("Coloque um email válido")
      return
    }
    if(password.includes(" ")){
      setInfo("Não pode contêr espaços em brancos")
      setWrong(true)
      return
    }
    if(password.length < 8){
      setInfo("Mínimo de 8 caractéres")
      setWrong(true)
      return
    }

    if(password !== confirmpassword){
      setInfo("As senhas não combinam")
      setWrong(true)
      return
    }

    setLoading(true)
    const status = await verifyEmail({ email });

    if (status === 200) {
      const data = {
        name,
        email,
        tel,
        password,
        street,
        neighborhood,
        postal_code: postalCode,
        number,
        public_key_mp: publicKey,
        access_token_mp: accessToken,
      }

      const codeCreate = await createRestaurant(data)

      if(codeCreate === 201) {
        setName("")
        setEmail("")
        setStreet("")
        setTel("")
        setNeighborhood("")
        setNumber("")
        setPostalCode("")
        setPassword("")
        setConfirmPassword("")

        toast.success("Restaurante adicionado com sucesso")
        setLoading(false)
        onRequestClose()
        return
      }

      toast.error("Error ao adicionar restaurante")
      setLoading(false)

      return
    }

    if (status === 400) {
      setInfo("Erro ao verificar email")
      toast.error(`Erro ao verificar email`)
      setLoading(false)
      return
    }

    if (status === 403) {
      setInfo("Email já cadastrado")
      toast.error(`Email já cadastrado`)
      setLoading(false)
      return
    }

    setInfo(`${status} ERROR`)
    toast.error(`${status} ERROR`)
    setLoading(false)
  }


  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Logo className='logo-modal'/>
      <span>{info}</span>
      {
        !loading ?
        (
          <Form onSubmit={create}>

          <div className="line">
            <InputField
              placeHolder='NOME RESTAURANTE'
              value={name}
              changeValue={handleName}
              type='text'
              id='restaurant-name'
              required={true}
              width={48}
            />

            <InputMaskField
              placeHolder='TELEFONE'
              value={tel}
              changeValue={handleTel}
              type='tel'
              id='tel'
              required={true}
              width={48}
              mask="(99) 9 9999-9999"
            />
          </div>

          <InputField
            placeHolder='E-MAIL'
            value={email}
            changeValue={handleEmail}
            type='email'
            id='email'
            required={true}
          />

          <InputField
            placeHolder='PUBLIC KEY MERCADO PAGO'
            value={publicKey}
            changeValue={handlePublicKey}
            type='text'
            id='public_key_mp'
            required={true}
          />

          <InputField
            placeHolder='ACCESS TOKEN MERCADO PAGO'
            value={accessToken}
            changeValue={handleAccessToken}
            type='text'
            id='access_toke_mp'
            required={true}
          />

          <InputField
            placeHolder='RUA'
            value={street}
            changeValue={handleStreet}
            type='text'
            id='street'
            required={true}
          />

          <InputField
            placeHolder='BAIRRO'
            value={neighborhood}
            changeValue={handleNeighborhood}
            type='text'
            id='neighborhood'
            required={true}
          />


          <div className="line">
            <InputField
              placeHolder='NÚMERO'
              value={number}
              changeValue={handleNumber}
              type='number'
              id='number'
              width={48}
              required={true}
            />

            <InputMaskField
              placeHolder='CEP'
              value={postalCode}
              changeValue={handlePostalCode}
              type='text'
              id='postal-code'
              mask='99999-999'
              width={48}
              required={true}
            />

          </div>

          <div className="line">
            <PasswordField
              placeHolder='SENHA'
              value={password}
              changeValue={handlePassword}
              type='password'
              id='password'
              width={48}
              required={true}
              wrong={wrong}
            />

            <PasswordField
              placeHolder='CONFIRMAR SENHA'
              value={confirmpassword}
              changeValue={handleConfirmPassword}
              type='password'
              id='confirm-password'
              width={48}
              required={true}
              wrong={wrong}
            />

          </div>


          <Button
            type='submit' style={{marginTop: '20px', marginBottom: "24px"}}
          >
            Criar conta
          </Button>

          </Form>
        )
        :
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} />
        )
      }

    </Container>
  )
}

ModalRegisterRestaurant.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalRegisterRestaurant
