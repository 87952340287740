import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  width: 95%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 88px;
  gap: 16px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
  width: 100%;

  svg{
    height: 32px;
    width: 32px;

    circle, path{
      fill: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 32px;
    color: ${theme.colors.gray400};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const Coupon = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.white};
  transition: all .4s;

  div.info-coupon{
    height: 160px;
    width: 96%;
    display: flex;
    align-items: center;

    img{
      height: 128px;
      width: 172px;
      object-fit: cover;
      object-position: center;
      margin-right: 16px;

      @media (max-width: 768px) {
        max-width: 128px;
        width: 128px;
      }

      @media (max-width: 425px) {
        max-width: 104px;
        width: 104px;
      }
    }

    div.text{
      height: 128px;
      display: flex;
      flex-direction: column;

      h1{
        font-size: 22px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.text};
        margin: 0;
        margin-bottom: 12px;
      }

      h2{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.gray400};
        margin: 0;
        margin-bottom: 4px;
      }

      @media (max-width: 768px) {
        h1{
          font-size: 20px;
        }
      }

      @media (max-width: 425px) {
        h1{
          font-size: 18px;
        }
        h2{
          font-size: 14px;
        }
      }

      div.location{
        display: flex;
        align-items: center;

        svg{
          height: 16px;
          width: 16px;
          margin-right: 8px;
          path{
            fill: ${theme.colors.gray400};
          }
        }

        h3{
          font-size: 14px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.gray400};
          margin: 0;
          margin-bottom: -2px;
        }
        @media (max-width: 425px) {
          h3{
            font-size: 12px;
          }
        }
      }
    }
  }

  button.show{
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-top: 1px solid ${theme.colors.gray200};

    svg {
      height: 20px;
      width: 20px;
      path{
        stroke: ${theme.colors.background};
      }
    }
  }

  div.list{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    height: 0px;
    overflow-y: hidden;
    transition: all .4s;

    div.code{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      min-height: 40px;
      border-bottom: 1px solid ${theme.colors.gray200};

      h2{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        margin: 0;
        color: ${theme.colors.text};
        margin-left: 12px;
      }

      svg {
        height: 20px;
        width: 20px;
        padding: 4px;
        border-radius: 50%;
        margin-right: 12px;
        background-color: ${theme.colors.lightGreenBG};

        path { stroke: ${theme.colors.lightGreen}}

        &.not-used{
          background-color: ${theme.colors.primaryBG};
          path { stroke: ${theme.colors.primary}}
        }
      }
    }
  }


  &.active div.list{
    height: auto;
  }



`;
