import styled from "styled-components";
import { theme } from "../../../styles/Theme";


export const Container = styled.form`
  width: calc(100vw - 241px - 24px);
  min-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: -24px;
  margin-bottom: -24px;
  gap: 32px;
`;


export const Field = styled.div`
  width: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  h3{
    font-size: 12px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
  }

  div.field{
    width: 100%;
    height: 40px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input{
      width: 90%;
      height: 32px;
      border: none;
      outline: none;
      background-color: transparent;
      border-radius: 4px;
      margin-left: 8px;
      font-size: 14px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};

      &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
      }

      &::placeholder{
        color: ${theme.colors.gray200};
      }

      &:focus, &:active {
        outline: none;
      }

    }

    svg{
      height: 32px;
      width: 32px;
      margin-right: 4px;
      cursor: pointer;

      path {
        fill: ${theme.colors.text};
      }
    }
  }
`;

export const Button = styled.button`
  height: 48px;
  min-height: 48px;
  margin-top: 16px;
  width: 100%;
  max-width: 512px;
  border-radius: 4px;
  background-color: ${theme.colors.lightGreen};
  font-size: 16px;
  font-family: "NeueMachinaLight";
  font-weight: 400;
  color: ${theme.colors.white};
  cursor: pointer;
  border: none;
  transition: all .4s;

  &:hover{
    background-color: ${theme.colors.lightBlue};
  }
`;

