import React, { useEffect, useState, useContext } from 'react'
import { AddCoupon, Container, Coupon, CuponsList, LoadMore, Title } from './styles'

import ModalAddCupon from '../../../components/ModalAddCupon'
import { getCupons } from '../../../services/api'
import { LoginContext } from '../../../contexts/LoginContext'

import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg"

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';
import ModalAddMore from '../../../components/ModalAddMore'
import ModalEditCupon from '../../../components/ModalEditCupon'
import { toast } from 'react-toastify'
import { Input } from '../Validate/styles'

type ResponseType = {
  id: number;
  plate_name: string;
  picture: string;
  category: string;
  price_of: number;
  price_purchase: number;
  quantity: number;
  remaining: number
};


function Coupons() {

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalAddMore, setOpenModalAddMore] = useState<boolean>(false)
  const [openModalEditCupon, setOpenModalEditCupon] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [coupons, setCoupons] = useState<ResponseType[]>([])
  const [couponId, setCouponId] = useState<number>(0)
  const [couponIdEdit, setCouponIdEdit] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [searchCupon, setSearchCupon] = useState<string>("")

  const URL = process.env.REACT_APP_URL_AWS_S3

  const { user } = useContext(LoginContext);

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleOpenModalAddMore = (id: number) => {
    setCouponId(id)
    setOpenModalAddMore(true)
  }

  const handleCloseModalAddMore = () => {
    setOpenModalAddMore(false)
  }

  const handleOpenModalEditCupon = (id: number) => {
    setCouponIdEdit(id)
    setOpenModalEditCupon(true)
  }

  const handleCloseModalEditCupon = () => {
    setOpenModalEditCupon(false)
  }

  async function getCuponsRestaurant() {
    setLoading(true)
    let search = ""

    search += `&restaurant_id=${user.id}`
    search += `&page=${page}`
    search += `&search=${searchCupon}`

    const response = await getCupons(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as ResponseType[];

      if (code === 200) {
        setCoupons([...coupons, ...data]);
        setLoading(false);
        return;
      }
    } else {
      toast.error("ERROR AO OBTER CUPONS")
    }
  }

  async function getCuponsRestaurantSearch() {
    setLoading(true)
    let search = ""

    search += `&restaurant_id=${user.id}`
    search += `&page=1`
    search += `&search=${searchCupon}`

    const response = await getCupons(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as ResponseType[];

      if (code === 200) {
        setCoupons(data);
        setLoading(false);
        return;
      }
    } else {
      toast.error("ERROR AO OBTER CUPONS")
    }
  }

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      getCuponsRestaurantSearch()
    }
  }, [searchCupon])

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      getCuponsRestaurant()
    }
  }, [page, user])

  return (
    <Container>
      <ModalAddCupon isOpen={openModal} onRequestClose={handleClose} />
      <ModalAddMore isOpen={openModalAddMore} onRequestClose={handleCloseModalAddMore} cuponId={couponId} />
      <ModalEditCupon isOpen={openModalEditCupon} onRequestClose={handleCloseModalEditCupon} cuponId={couponIdEdit} />
      <AddCoupon onClick={handleOpen}>
        Adicionar Cupon
      </AddCoupon>

      <Title>Cupons</Title>

      <Input>
        <input type='text' id='search-cupon' name='seacrh-cupon' placeholder='Pesquisar' onChange={(event) => setSearchCupon(event.target.value)} />
      </Input>

      <CuponsList>
      {
        coupons.map(item => (
          <Coupon key={item.id}>
            <img src={`${URL}/${item.picture}`} alt={item.plate_name} />
            <div className='text'>
              <h2>{String(item.id).padStart(2, '0')} - {item.plate_name}</h2>
              <h3>Restante: {item.remaining}</h3>
              <h3>De: R$ {String(item.price_of.toFixed(2)).replace(".", ",")}</h3>
              <h3>Por: R$ {String(item.price_purchase.toFixed(2)).replace(".", ",")}</h3>
            </div>
            <button type='button' onClick={() => handleOpenModalAddMore(item.id)}>Adicionar mais</button>

            <EditIcon className='edit' onClick={() => handleOpenModalEditCupon(item.id)} />
          </Coupon>
        ))
      }
      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          coupons.length / page < page * 20 ? null : 
          (
            <LoadMore onClick={() => setPage(page + 1)}>
              Carregar mais
            </LoadMore>
          )
        )
      }
      </CuponsList>

    </Container>
  )
}

export default Coupons
