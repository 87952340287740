import React, { useEffect, useContext, useState} from 'react'
import { Container, Input, LoadMore, Restaurant, RestaurantsList, Select, Values } from './styles'
import { LoginContext } from '../../../contexts/LoginContext'
import { salesAdmin, valuesAdmin } from '../../../services/api'
import { toast } from 'react-toastify'
import { CiLocationOn } from "react-icons/ci"

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';

type typeCupon = {
  id: number
  name: string
  email: string
  tel: string
  street: string
  neighborhood: string
  number: number
  postal_code: number

  institute: string
  account_type: string
  agency: string
  number_account: string

  pay: boolean

  count: number
  price: number
  total: number
}

type typeValue = {
  price: number
  total: number
}

const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

function Home() {

  const { user } = useContext(LoginContext)

  const [restaurants, setRestaurants] = useState<typeCupon[]>([])
  const [values, setValues] = useState<{ total: number, price: number }>({ total: 0, price: 0})
  const [page, setPage] = useState<number>(1)
  const [searchRestaurant, setSearchRestaurant] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const [date, setDate] = useState(`${monthNames[currentMonth-1]} ${currentYear}`)


  const getSales = async () => {
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}&search=${searchRestaurant}&page=1`

    setLoading(true)
    const result = await salesAdmin(search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as typeCupon[];

      if (code === 200) {
        setRestaurants(response)
        setLoading(false)
        return
      }

      toast.error("Erro ao obter restaurantes")
      setLoading(false)
      return
    }
    setLoading(false)
  }

  const getSales2 = async () => {
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}&search=${searchRestaurant}&page=${page}`

    setLoading(true)
    const result = await salesAdmin(search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as typeCupon[];

      if (code === 200) {
        setRestaurants([...restaurants, ...response])
        setLoading(false)
        return
      }

      toast.error("Erro ao obter restaurantes")
      setLoading(false)
      return
    }
    setLoading(false)
  }

  const getSalesSearch = async () => {
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}&search=${searchRestaurant}&page=1`

    setLoading(true)
    const result = await salesAdmin(search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as typeCupon[];

      if (code === 200) {
        setRestaurants(response)
        setLoading(false)
        return
      }

      toast.error("Erro ao obter restaurantes")
      setLoading(false)
      return
    }
    setLoading(false)
  }


  const getSalesValues = async () => {
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}`

    setLoading(true)
    const result = await valuesAdmin(search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as unknown;

      if (code === 200) {
        setValues(response as typeValue)
        setLoading(false)
        return
      }

      toast.error("Erro ao obter valores")
      setLoading(false)
      return
    }
    setLoading(false)
  }

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_ADMIN_TOKEN) {
      getSales()
      getSalesValues()
    }
  }, [user, date])

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_ADMIN_TOKEN) {
      getSalesSearch()
    }
  }, [searchRestaurant])

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_ADMIN_TOKEN) {
      getSales2()
    }
  }, [page])

  const [months, setMonths] = useState<string[]>([])

  useEffect(() => {
    const monthOptions = [];

    for (let year = 2023; year <= currentYear; year++) {
      const startMonth = year === 2023 ? 6 : 1;
      const endMonth = year === currentYear ? currentMonth : 12;
      for (let month = startMonth; month <= endMonth; month++) {
        const optionValue = `${monthNames[month - 1]} ${year}`;
        monthOptions.push(optionValue);
      }
    }
    setMonths(monthOptions)
  }, [])

  return (
    <Container>
      <Select value={date} name='interval' id="interval" onChange={(event) => setDate(event.target.value)}>
        {
          months.map(month => <option key={month} value={month}>{month}</option>)
        }
      </Select>

      <Values>
        <div className="content">
          <h4>Valor total</h4>
          <h2>R$ {values.total ? String(values.total.toFixed(2)).replace(".", ',') : "0,00"}</h2>
        </div>

        <div className="content">
          <h4>Valor a receber</h4>
          <h2>R$ {values.price ? String(values.price.toFixed(2)).replace(".", ',') : "0,00"}</h2>
        </div>
      </Values>

      <Input>
        <input type='text' id='search-restaurant' name='seacrh-restaurant' placeholder='Procurar restaurante' onChange={(event) => setSearchRestaurant(event.target.value)} />
      </Input>

      <RestaurantsList>
        {
          loading ? null : 
          (
            restaurants.map((item) => (
              <Restaurant key={item.id}>
                <div className="text">
                  <h2>{item.name}</h2>
                  <h3>Email: {item.email}</h3>
                  <h3>Telefone: {item.tel}</h3>
                  <div className="location">
                    <CiLocationOn />
                    <h3>Rua {item.street.replace("Rua", "").replace("rua", "").replace("RUA", "")} - {item.number}, {item.neighborhood} - {item.postal_code}</h3>
                  </div>
                  <div className="value">
                    <h5>Valor a receber</h5>
                    <h4>R$ {item.price ? String(item.price.toFixed(2)).replace(".", ',') : "0,00"}</h4>
                  </div>
                </div>

              </Restaurant>
            ))
          )
        }
        {
          loading ?
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
          )
          :
          (
            <LoadMore onClick={() => setPage(page + 1)}>
              Carregar mais
            </LoadMore>
          )
        }
      </RestaurantsList>



    </Container>
  )
}

export default Home
