import React from 'react'
import { Container, Items, Title } from './style'

import { ReactComponent as HatIcon } from '../../../assets/icons/hat-chef.svg'
import Carousel from './Carousel'

function Highlights() {
  return (
    <Container>
      <Title>
        <HatIcon />
        <h2>Destaques</h2>
      </Title>
      <Items>
        <Carousel />
      </Items>
    </Container>
  )
}

export default Highlights
