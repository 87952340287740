import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, NavigationDiv } from './styles';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';


import 'swiper/css';
import 'swiper/css/bundle';

import { getCategories } from '../../../../services/api';

function Carousel() {


  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])

  const URL = process.env.REACT_APP_URL_AWS_S3

  const [slides, setSlides] = useState(window.innerWidth * 0.85 / 220)

  const next = useRef()
  const prev = useRef()
  const swiperRef = useRef(null);

  useEffect(() => {
    function handleSize () {
      const calc = window.innerWidth * 0.85 / 220
      setSlides(calc)
    }

    window.addEventListener("resize", handleSize)

    return (() => {
      window.removeEventListener("resize", handleSize)
    })

  }, [])

  useEffect(() => {
    async function getCategory() {
      const [code, response] = await getCategories()
      if(code === 200) {
        setCategories(response)
        setLoading(false)
        return
      }

    }
    getCategory();
  }, [])

  return (
    <Container>
      <Swiper
        className="content"
        spaceBetween={28}
        slidesPerView={slides}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={{
          nextEl: next,
          prevEl: prev
        }}
        autoplay
        pagination={{ clickable: true }}
      >
        {
          loading ?
          (
            <>
              <SwiperSlide className="slider-load">
                <div className="img" />
                <div className="span" />
              </SwiperSlide>

              <SwiperSlide className="slider-load">
                <div className="img" />
                <div className="span" />
              </SwiperSlide>

              <SwiperSlide className="slider-load">
                <div className="img" />
                <div className="span" />
              </SwiperSlide>

              <SwiperSlide className="slider-load">
                <div className="img" />
                <div className="span" />
              </SwiperSlide>

              <SwiperSlide className="slider-load">
                <div className="img" />
                <div className="span" />
              </SwiperSlide>

              <SwiperSlide className="slider-load">
                <div className="img" />
                <div className="span" />
              </SwiperSlide>
            </>
          )
          :
          (
            categories.map((item) => (
              <SwiperSlide className="slider" key={item.id}>
                <a href={`/categoria/${item.name.replaceAll(" ", "-").toLowerCase()}`}>
                  <img src={`${URL}/${item.picture}`} alt={`${item.id}-${item.name}`} />
                  <span>{item.name}</span>
                </a>
              </SwiperSlide>
            ))
          )
        }
      </Swiper>

      <NavigationDiv>
        <ArrowLeftIcon
          ref={prev}
          // className={swiperRef.current && swiperRef.current.isBeginning ? 'disable' : ''}
          onClick={() => swiperRef.current.slidePrev()} />

        <ArrowRightIcon
          ref={next}
          // className={swiperRef.current && swiperRef.current.isEnd ? 'disable' : ''}
          onClick={() => swiperRef.current.slideNext()} />
      </NavigationDiv>
    </Container>
  );
}

export default Carousel;
