import { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, NavigationDiv } from './styles';
import { Autoplay } from "swiper/modules"

import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
// import PlaceHolder from '../../../assets/images/placeholder.webp';
import Publi from '../../../assets/images/publicity.png';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';
import { getPublicity } from '../../../services/api';
import { toast } from 'react-toastify';


function Publicity() {

  const next = useRef()
  const prev = useRef()
  const swiperRef = useRef(null);

  const [load, setLoad] = useState(false)
  const [publicity, setPublicity] = useState([])

  const URL = process.env.REACT_APP_URL_AWS_S3

  const loadPublicity = async () => {
    setLoad(true)
    const [code, response] = await getPublicity("show=true")

    if(code === 200) {
      setPublicity(response)
      setLoad(false)
      return
    }

    toast.error("Erro ao carregar publicidades")
    setLoad(false)
    return
  }

  useEffect(() => {
    loadPublicity()
  }, [])

  return (
    <Container>
      <Swiper
        className="content"
        spaceBetween={0}
        slidesPerView={1}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={{
          nextEl: next,
          prevEl: prev
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        pagination={{ clickable: true }}
        modules={[Autoplay]}
      >
        {
          load ?
          (
            <SwiperSlide className='slider'>
              <img src={Publi} className='img' alt="Publicidade" />
            </SwiperSlide>
          )
          :
          (
            publicity.map(item =>
              (
                <SwiperSlide key={item.id} className='slider'>
                  <a href={item.link} rel="noreferrer" target='_blank'>
                    <img src={`${URL}/${item.picture}`} alt="Publicidade" />
                  </a>
                </SwiperSlide>
              ))
          )
        }
        {
          publicity.length <= 0 ?
          (
            <>
              <SwiperSlide className='slider'>
                <img src={Publi} className='img' alt="Publicidade" />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <img src={Publi} className='img' alt="Publicidade" />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <img src={Publi} className='img' alt="Publicidade" />
              </SwiperSlide>
            </>
          ) : null
        }
      </Swiper>

      <h2>Espaço de Publicidade</h2>

      <NavigationDiv>
        <ArrowLeftIcon
          ref={prev}
          // className={swiperRef.current && swiperRef.current.isBeginning ? 'disable' : ''}
          onClick={() => swiperRef.current.slidePrev()} />

        <ArrowRightIcon
          ref={next}
          // className={swiperRef.current && swiperRef.current.isEnd ? 'disable' : ''}
          onClick={() => swiperRef.current.slideNext()} />
      </NavigationDiv>

    </Container>
  );
}

export default Publicity;
