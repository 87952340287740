import React, { useContext, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { AddToCart, Button, Container, Content, ContentItem, Form, InputWithMask, Item, Preview } from './styles'
import "./style.css";

import {ReactComponent as LikeIcon} from "../../assets/icons/heart.svg"
import {ReactComponent as CartIcon} from "../../assets/icons/shopping-cart-add.svg"
import {ReactComponent as TrashIcon} from "../../assets/icons/trash.svg"


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { deleteCupons, getCategories, getEspecifyCoupon, updateeCupons, uploadfiles } from '../../services/api';
import { LoginContext } from '../../contexts/LoginContext';
import { toast } from 'react-toastify';

function ModalEditCupon({ isOpen, onRequestClose, cuponId }) {

  const { user } = useContext(LoginContext);

  const [info, setInfo] = useState("⠀")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [priceOf, setPriceOf] = useState("")
  const [pricePurchase, setPricePurchase] = useState("")
  const [image, setImage] = useState()
  const [imagePreview, setImagePreview] = useState()
  const [category, setCategory] = useState()

  const [categories, setCategories] = useState([])
  const [cuponData, setCuponData] = useState([])

  const [loading, setLoading] = useState()

  const URL = process.env.REACT_APP_URL_AWS_S3

  useEffect(() => {
    setImagePreview(false)
  }, [isOpen])

  useEffect(() => {
    if(!image || image && image.length === 0){
      return
    }

    const file = image[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64 = reader.result
      setImagePreview(base64)
    }

    reader.readAsDataURL(file)
  }, [image])

  const getCategory = async () => {
    setLoading(true)
    const [code, response] = await getCategories()

    if(code === 200) {
      setCategories(response)
      setLoading(false)
      return
    }
    toast.error("Error ao obter categorias")
    setInfo("ERROR ao obter categorias")
    setLoading(false)
  }

  const getCupon = async () => {
    setCuponData()
    if(!cuponId) {
      return
    }
    setLoading(true)

    const [code, response] = await getEspecifyCoupon(cuponId)

    if(code === 200) {
      setCuponData(response)
      setName(response.plate_name)
      setCategory(response.category)
      setPriceOf(Number(response.price_of))
      setPricePurchase(Number(response.price_purchase))
      setDescription(response.description)
      setLoading(false)
      return
    }

    toast.error("Erro ao obter dados do cupon")
    setLoading(false)
  }

  useEffect(() => {
    getCategory()
    getCupon()
  }, [cuponId, isOpen])

  const send = async () => {
    if (!cuponId || !name  || !priceOf || !pricePurchase || !category || !description){
      setInfo("Preecha todos os campos")
      return
    }
    if(!user && !user?.id){
      toast.warn("Não foi possível atualizar o cupon, tente novamente mais tarde")
      return
    }

    if(image && image.length > 0) {
      setInfo("⠀")
      setLoading(true)

      const formData = new FormData();
      formData.append("file", image[0])

      console.log("formData", formData)

      const [code, response] = await uploadfiles(formData);

      if(code === 201) {

        const data = {
          plate_name: name,
          picture: response.path,
          category,
          price_of: priceOf,
          price_purchase: pricePurchase,
          restaurant_id: user.id,
          description,
          previous_file: cuponData?.picture
        }

        const status = await updateeCupons(data, cuponId);

        if(status === 200) {
          toast.success("Cupon atualizado")
          setLoading(false)
          onRequestClose();
          return
        }

        if(status === 400) {
          setInfo("Erro ao atualizar cupons")
          toast.error("Erro ao atualizar cupons")
          setLoading(false)
          return
        }

        toast.error(`${status} ERROR`)
        setInfo(`${status} ERROR`)
        setLoading(false)
        return
      }

      toast.error(`${code} ERROR`)
      setInfo(`${code} ERROR`)
      setLoading(false)
      return
    }
    setInfo("⠀")
    setLoading(true)

    const data = {
      plate_name: name,
      category,
      price_of: priceOf,
      price_purchase: pricePurchase,
      restaurant_id: user.id,
      description
    }

    const status = await updateeCupons(data, cuponId);

    if(status === 200) {
      toast.success("Cupon atualizado")
      setLoading(false)
      onRequestClose();
      return
    }

    if(status === 400) {
      setInfo("Erro ao atualizar cupons")
      toast.error("Erro ao atualizar cupons")
      setLoading(false)
      return
    }

    toast.error(`${status} ERROR`)
    setInfo(`${status} ERROR`)
    setLoading(false)
    return
  }

  const deleteCupon = async () => {
    if(!cuponId) {
      toast.error("Error ao deletar cupon")
      return
    }

    setLoading(true)
    const code = await deleteCupons(cuponId)

    if(code === 200) {
      toast.success("Cupon deletado com sucesso")
      setLoading(false)
      onRequestClose()
      return
    }

    if(code === 401) {
      toast.warn("Não foi possível deletar esse cupon, porque há usuários que compraram esse cupon e não utilizaram!\n\nVocê poderá deletar assim quando os cupons forem todos utilizados!")
      setLoading(false)
      return
    }

    toast.error("Error ao deletar cupon")
    setLoading(false)
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Atualizar Cupon</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <h4 style={{marginTop: "28px"}}>Nome do Prato</h4>
              <input value={name} type="text" name="plate-name" id="plate-name"  maxLength={255} onChange={(event) => setName(event.target.value)} />

              <div style={{marginTop: "28px"}} className="line">
                <div className="input">
                  <h4>De</h4>
                  <InputWithMask mask="R$ 999,99" type="text" name="price-of" id="price-of" maxLength={255} onChange={(event) => setPriceOf(Number(event.target.value.replace("_", "0").replace("R$ ", "").replace(",", ".")))} />
                </div>

                <div className="input">
                  <h4>Por</h4>
                  <InputWithMask mask="R$ 999,99" type="text" name="price-purchase" id="price-purchase" maxLength={255} onChange={(event) => setPricePurchase(Number(event.target.value.replace("_", "0").replace("R$ ", "").replace(",", ".")))} />
                </div>
              </div>

              <h4 style={{marginTop: "28px"}}>Categoria</h4>
              <select value={category} onChange={(event) => setCategory(event.target.value)}>
                <option value="">Selecione</option>
                {
                  categories.map(item => <option key={item.id} value={item.name}>{item.name}</option>)
                }
                <option value="Outro">Outro</option>
              </select>

              <h4 style={{marginTop: "28px"}}>Descrição do Prato</h4>
              <textarea value={description} onChange={(event) => setDescription(event.target.value)} id="description" name='description'/>

              <Button onClick={send}>
                Atualizar
              </Button>
            </Form>

            <Preview>
              <h4>Pré-visualização</h4>

              <Item>
                <LikeIcon className='like' />
                <ContentItem>
                  <img src={imagePreview ? imagePreview : `${URL}/${cuponData?.picture}`} alt={cuponData?.plate_name} />
                  <div className="title">
                    <span className='plate'>{name ? name : "⠀"}</span>
                    <div className="discount">{ priceOf && pricePurchase ? String(((pricePurchase * 100) / priceOf - 100).toFixed(0)).replace("-", "") : "0"}%</div>
                  </div>
                  <div className="priceInfo">
                    <div className="info border">
                      <span>Economize</span>
                      <h3>R$ {priceOf && pricePurchase ? String((priceOf - pricePurchase).toFixed(2)).replace(".", ",") : "0,00"}</h3>
                    </div>

                    <div className="info">
                      <span>De R${priceOf ? priceOf.toFixed(0) : 0} por</span>
                      <h3>R$ {pricePurchase ? String(pricePurchase.toFixed(2)).replace(".", ",") : "0,00"}</h3>
                    </div>
                  </div>
                </ContentItem>
                <AddToCart>
                  <span>Comprar</span>
                  <CartIcon />
                </AddToCart>
              </Item>
            </Preview>

            <TrashIcon className='delete' onClick={() => deleteCupon()} />
          </Content>
        )
      }

    </Container>
  )
}

ModalEditCupon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cuponId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalEditCupon
