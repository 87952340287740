import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

export const Cupon = styled.section`
  width: 95%;
  display: flex;
  gap: 20px;
  margin-top: 8vh;
  position: relative;
  overflow: hidden;
  height: 348px;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
  }

  img{
    width: 45%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all .4s;
    overflow: hidden;
    border-radius: 4px;

    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      max-height: 312px;
      height: 20vh;
      min-height: 280px;
    }

    &:hover{
      transform: scale(104%);
    }
  }

  div.discount{
    position: absolute;
    top: 8px;
    left: 8px;
    height: 48px;
    width: 48px;
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.white};
    margin: 0;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.info{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
    }

    div.ratings{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 12px;
      width: fit-content;

      div.stars{
        display: flex;
        align-items: center;
        gap: 2px;

        svg{
          height: 18px;
          width: 18px;

          path {
            fill: ${theme.colors.gray400}
          }

          &.marked path{
            fill: ${theme.colors.yellow}
          }
        }
      }

      span{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.text};
        margin: 0;
      }

    }
  }

  h1{
    font-size: 24px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
    margin-bottom: 4px;
  }

  h2{
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.gray400};
    margin: 0;
    margin-bottom: 2px;

    a{
      color: ${theme.colors.lightGreen};
    }
  }

  h3{
    font-size: 12px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.gray400};
    margin: 0;
    margin-bottom: 24px;
  }

  div.line{
    display: flex;
    gap: 40px;

    div.price{
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 12px;

      h1{
        font-size: 28px;
        color: ${theme.colors.lightBlue};
        margin: 0;
        font-family: "NeueMachina";
        font-weight: 900;

        &.economy{
          font-family: "NeueMachinaLight";
          font-weight: 200;
          font-size: 20px;
        }
      }

      h2{
        font-size: 14px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.gray400};
        margin: 0;
      }
    }
  }

  div.location{
    display: flex;
    align-items: center;

    svg{
      height: 18px;
      width: 18px;
      margin-right: 8px;
      path{
        fill: ${theme.colors.gray400};
      }
    }

    h3{
      font-size: 16px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.gray400};
      margin: 0;
      margin-bottom: -2px;
    }
  }
`;

export const LoadCupon = styled.div`
  width: 95%;
  display: flex;
  gap: 20px;
  margin-top: 8vh;
  position: relative;
  overflow: hidden;
  height: fit-content;

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
  }

  div.img{
    width: 45%;
    height: 100%;
    height: 100%;
    background-color: ${theme.colors.gray200};
    border-radius: 4px;

    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
      max-height: 312px;
      height: 20vh;
      min-height: 280px;
    }
  }

  div.discount{
    position: absolute;
    top: 8px;
    left: 8px;
    height: 48px;
    width: 48px;
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.white};
    margin: 0;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.info{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;
    }


    div.ratings{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 12px;
      width: fit-content;

      div.stars{
        display: flex;
        align-items: center;
        gap: 2px;

        svg{
          height: 18px;
          width: 18px;

          path {
            fill: ${theme.colors.gray400}
          }
        }
      }

      div.span{
        height: 18px;
        width: 24px;
        border-radius: 2px;
        background-color: ${theme.colors.gray200};
      }

    }
  }

  div.h1{
    height: 26px;
    width: 300px;
    border-radius: 2px;
    background-color: ${theme.colors.gray200};
    margin: 0;
    margin-bottom: 4px;
  }

  div.h2{
    height: 18px;
    width: 128px;
    border-radius: 2px;
    background-color: ${theme.colors.gray200};
    margin: 0;
    margin-bottom: 2px;
  }

  div.h3{
    height: 14px;
    width: 104px;
    border-radius: 2px;
    background-color: ${theme.colors.gray200};
    margin: 0;
    margin-bottom: 24px;
  }

  div.line{
    display: flex;
    gap: 40px;

    div.price{
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 12px;

      div.h1-price{
        height: 30px;
        width: 148px;
        border-radius: 2px;
        background-color: ${theme.colors.gray200};
        margin: 0;

        &.economy{
          width: 104px;
          height: 24px;
        }
      }

      div.h2-price{
        height: 16px;
        width: 72px;
        border-radius: 2px;
        background-color: ${theme.colors.gray200};
        margin: 0;
      }
    }
  }

  div.location{
    height: 18px;
    width: 300px;
    border-radius: 2px;
    background-color: ${theme.colors.gray200};
    margin: 0;
  }
`;

export const AddCart = styled.div`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.white};
  transition: all .4s;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  font-family: "NeueMachina";
  margin-top: 32px;
  border-radius: 2px;
  gap: 24px;

  span{
    margin-bottom: -2px;
    font-size: 18px;
  }

  svg{
    height: 24px;
    width: 24px;

    path, circle{
      fill: ${theme.colors.white};
    }
  }

  &:before, &:after{
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 50%;
    position: absolute;
    bottom: 12px;
    background-color: ${theme.colors.background};
  }

  &:before{
    left: -8px;
  }

  &::after{
    right: -8px;
  }

`;

export const Description = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 32px;
  width: 95%;

  h4{
    font-size: 20px;
    color: ${theme.colors.gray400};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  svg{
    height: 32px;
    width: 32px;

    path{
      stroke: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 24px;
    color: ${theme.colors.text};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const Ratings = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  padding-bottom: 32px;
  width: 95%;
`;

export const Title2 = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  svg{
    height: 32px;
    width: 32px;

    path{
      fill: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 24px;
    color: ${theme.colors.text};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 88px;
  position: relative;

  h2{
    font-size: 20px;
    color: ${theme.colors.text};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-top: 24px;
  }

  div.stars{
    display: flex;
    align-items: center;
    gap: 2px;

    svg{
      height: 20px;
      width: 20px;
      cursor: pointer;

      path {
        fill: ${theme.colors.gray400};
      }

      &.marked path{
        fill: ${theme.colors.yellow};
      }
    }

  }

  textarea{
    width: 100%;
    padding-left: 8px;
    padding-top: 8px;
    min-width: 100%;
    max-width: 100%;
    height: 15vw;
    min-height: 56px;
    max-height: 72px;
    border: none;
    background-color: ${theme.colors.white};
    outline: none;
    border-radius: 16px;
    font-size: 18px;
    color: ${theme.colors.text};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-top: 16px;

    &::placeholder{
      color: ${theme.colors.gray200};
    }
  }
`;

export const Send = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${theme.colors.lightGreen};
  cursor: pointer;
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    height: 28px;
    width: 28px;

    path {
      stroke: ${theme.colors.white};
    }
  }
`;


export const UserRating = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid ${theme.colors.gray200};
  position: relative;

  div.info{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    position: relative;

    div.user{
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: ${theme.colors.gray200};
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        height: 14px;
        width: 14px;

        path {
          fill: ${theme.colors.gray400}
        }
      }
    }

    div.text{
      display: flex;
      flex-direction: column;
      gap: 2px;

      h2{
        font-size: 16px;
        color: ${theme.colors.text};
        font-family: "NeueMachinaLight";
        margin: 0;
        margin-bottom: 4px;
      }

      div.stars{
        display: flex;
        align-items: center;
        gap: 2px;

        svg{
          height: 16px;
          width: 16px;
          transition: all .4s;

          path {
            fill: ${theme.colors.gray400};
            transition: all .4s;
          }

          &.marked path{
            fill: ${theme.colors.yellow};
          }
        }
      }

      h3{
        font-size: 12px;
        color: ${theme.colors.gray400};
        font-family: "NeueMachinaLight";
        margin: 0;
      }
    }
  }

  h1{
    font-size: 18px;
    color: ${theme.colors.text};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 12px;
  }

  svg.delete{
    position: absolute;
    top: 32px;
    right: 0px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    path {
      stroke: ${theme.colors.primary};
    }
  }
`;

export const ShareAndLike = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 24px;
  z-index: 5;

  svg{
    width: 32px;
    height: 32px;
    cursor: pointer;

    path {
      fill: ${theme.colors.gray400}
    }

    &.like-marked path{
      fill: ${theme.colors.primary}
    }
  }
`;
