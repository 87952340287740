import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../styles/Theme";
import { NavLink } from "react-router-dom";

Modal.setAppElement("#root")

export const Container = styled(Modal)`
  width: 90%;
  max-width: 400px;
  height: auto;
  max-height: 90vh;
  min-height: 80px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  transition: all .4s;
  position: relative;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  svg.logo-modal{
    height: 24px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;

    path, rect, polygon{
      fill: ${theme.colors.primary};
    }
  }

  svg.back{
    height: 32px;
    width: 32px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: transparent;

    path {
      stroke: ${theme.colors.gray400};
    }
    rect{
      fill: transparent;
    }
  }

  span{
    margin: 0;
    font-size: 12px;
    margin-bottom: 16px;
    color: ${theme.colors.lightBlue};
  }

  h2{
    width: 90%;
    margin: 0;
    font-size: 16px;
    margin-bottom: 24px;
    color: ${theme.colors.text};
    text-align: center;
  }

  svg.check-cross {
    height: 48px;
    width: 48px;
    padding: 12px;
    border-radius: 50%;
    margin-bottom: 20px;
    background-color: ${theme.colors.lightGreenBG};

    path { stroke: ${theme.colors.lightGreen}}

    &.not{
      background-color: ${theme.colors.primaryBG};
      path { stroke: ${theme.colors.primary}}
    }
  }
`;

export const Form = styled.form`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.info{
    display: flex;
    gap: 12px;
    width: 100%;

    img{
      height: 96px;
      width: 96px;
      min-width: 96px;
      object-fit: cover;
      object-position: center;
    }

    div.text{
      display: flex;
      flex-direction: column;
      gap: 2px;
      justify-content: flex-start;

      h1{
        margin: 0;
        font-size: 16px;
        color: ${theme.colors.text};
        font-family: "NeueMachinaLight";
        font-weight: 200;
      }

      h3{
        margin: 0;
        font-size: 12px;
        color: ${theme.colors.gray400};
        font-family: "NeueMachinaLight";
        font-weight: 200;
      }
    }
  }

  div.line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;

    button{
      width: 48%;
      height: 40px;
      min-height: 40px;
      border-radius: 4px;
      border: none;
      background-color: ${theme.colors.lightGreen};
      font-size: 18px;
      color: ${theme.colors.white};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 4px;

      &.cancel{
        background-color: ${theme.colors.primary};
      }
    }
  }
`;

export const Link1 = styled(NavLink)`
  font-size: 16px;
  text-decoration: none;
  color: ${theme.colors.gray400};
  font-weight: 100;
  font-family: "NeueMachinaLight";
  margin: 0;
  transition: all .4s;

  &:hover{
    color: ${theme.colors.lightGreen};
  }
`;

export const Open = styled.h3`
  font-size: 16px;
  text-decoration: none;
  color: ${theme.colors.gray400};
  font-weight: 100;
  font-family: "NeueMachinaLight";
  margin: 0;
  transition: all .4s;
  cursor: pointer;

  &:hover{
    color: ${theme.colors.lightGreen};
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
  border: none;
  background-color: ${theme.colors.lightGreen};
  font-size: 18px;
  color: ${theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
`;
