import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin-top: 8vh;
  margin-bottom: 88px;

  div.img-info{
    display: flex;
    gap: 48px;
    width: 100%;

    img{
      height: 400px;
      width: 720px;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
      box-shadow: 0 0 24px #0000001A;
    }

    div.text{
      display: flex;
      flex-direction: column;
      gap: 24px;

      h1{
        font-size: 28px;
        font-weight: 400;
        color: ${theme.colors.text};
        margin: 0;
      }

      div.status{
        height: 24px;
        padding: 0 24px;
        border-radius: 4px;
        background-color: ${theme.colors.lightGreen};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: ${theme.colors.lightGreenBG};
        max-width: fit-content;
        margin-top: -12px;

        &.closed{
          color: ${theme.colors.primaryBG};
          background-color: ${theme.colors.primary};
        }
      }

      h2{
        font-size: 20px;
        font-weight: 400;
        color: ${theme.colors.gray400};
        margin: 0;

        span{
          color: ${theme.colors.lightGreen};
        }
      }

      div.line{
        display: flex;
        gap: 64px;
        flex-wrap: wrap;

        div.info{
        display: flex;
        flex-direction: column;
        gap: 2px;

        h4{
          font-size: 12px;
          font-weight: 400;
          color: ${theme.colors.gray400};
          margin: 0;
        }

        h3{
          font-size: 18px;
          font-weight: 400;
          color: ${theme.colors.gray400};
          margin: 0;
        }
      }
      }

      div.info{
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4{
          font-size: 12px;
          font-weight: 400;
          color: ${theme.colors.gray400};
          margin: 0;
        }

        h3{
          font-size: 16px;
          font-weight: 400;
          color: ${theme.colors.gray400};
          margin: 0;
        }
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      img{
        width: 100%;
      }

      div.text{
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      img{
        height: 320px;
      }
    }

    @media (max-width: 500px) {
      img{
        height: 240px;
      }
    }

    @media (max-width: 420px) {
      img{
        height: 200px;
      }
    }
  }

  div.img-info-load{
    display: flex;
    gap: 48px;
    width: 100%;

    div.img{
      height: 400px;
      width: 720px;
      border-radius: 4px;
      background-color: ${theme.colors.gray200};
      box-shadow: 0 0 24px #0000001A;
    }

    div.text{
      display: flex;
      flex-direction: column;
      gap: 24px;

      div.h1{
        height: 30px;
        width: 192px;
        border-radius: 4px;
        background-color: ${theme.colors.gray200};
      }

      div.status{
        height: 24px;
        width: 104px;
        min-width: 104px;
        border-radius: 4px;
        background-color: ${theme.colors.lightGreen};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: ${theme.colors.lightGreenBG};
        max-width: fit-content;
        margin-top: -12px;

        &.closed{
          color: ${theme.colors.primaryBG};
          background-color: ${theme.colors.primary};
        }
      }


      div.h2{
        height: 22px;
        width: 128px;
        border-radius: 4px;
        background-color: ${theme.colors.gray200};
      }

      div.line{
        display: flex;
        gap: 64px;
        flex-wrap: wrap;

        div.info{
          display: flex;
          flex-direction: column;
          gap: 2px;

          div.h4{
            height: 14px;
            width: 48px;
            border-radius: 4px;
            background-color: ${theme.colors.gray200};
          }

          div.h3{
            height: 20px;
            width: 128px;
            border-radius: 4px;
            background-color: ${theme.colors.gray200};
          }
        }
      }

      div.info{
        display: flex;
        flex-direction: column;
        gap: 4px;

        h4{
          font-size: 12px;
          font-weight: 400;
          color: ${theme.colors.gray400};
          margin: 0;
        }

        h3{
          font-size: 16px;
          font-weight: 400;
          color: ${theme.colors.gray400};
          margin: 0;
        }
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      div.img{
        width: 100%;
      }

      div.text{
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      div.img{
        height: 320px;
      }
    }

    @media (max-width: 500px) {
      div.img{
        height: 240px;
      }
    }

    @media (max-width: 420px) {
      div.img{
        height: 200px;
      }
    }
  }

  h1{
    font-size: 24px;
    font-weight: 400;
    color: ${theme.colors.gray400};
    margin: 48px  0;
    width: 100%;

    span{
      color: ${theme.colors.primary};
    }
  }

  iframe{
    height: 45vh;

    @media (max-width: 767px) {
      height: 320px;
    }

    @media (max-width: 500px) {
      height: 240px;
    }

    @media (max-width: 420px) {
      height: 200px;
    }
  }
`;

export const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, 256px));
  grid-auto-rows: 340px;
  grid-auto-columns: 256px;
  row-gap: 24px;
  column-gap: 16px;
  grid-auto-flow: dense;
  justify-content: space-between;

  .item{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

  }

  @media (max-width: 556px) {
    &{
      grid-template-columns: 100%;
    }
  }
`;

export const PageNavigations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 4vh;

  h3{
    font-size: 20px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
    margin-bottom: 8px;
  }

  div.navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    span {
      font-size: 20px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      margin: 0;
      text-align: center;
    }

    svg{
      height: 40px;
      width: 40px;
      cursor: pointer;
      background-color: ${theme.colors.gray200};
      border-radius: 50%;
      transition: all .4s;

      path{
        stroke: ${theme.colors.gray400};
        transition: all .4s;
      }
      rect{
        fill: transparent
      }

      &.block{
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:hover{
        background-color: ${theme.colors.primary};
        path{
          stroke: ${theme.colors.white};
        }
      }
    }
  }

`;
