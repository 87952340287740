import React, { useContext, useState, useEffect } from 'react'
import { ButtonSearch, CartAndLogin, CartButton, Container, Input, LinkOp, LoginButton, Search, UserButton } from './styles'

import { ReactComponent as Logo } from '../../assets/images/Azul-Escuro.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { ReactComponent as CartIcon } from '../../assets/icons/shopping-cart.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'

import { ModalsContexts } from '../../contexts/ModalsContexts'
import { LoginContext } from '../../contexts/LoginContext'
import { CartContext } from '../../contexts/CartContext'

import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import { theme } from '../../styles/Theme';
import { useNavigate } from 'react-router-dom'

type itemCupon = {
  id: number
  cupon_id: number
  picture: string
  price_purchase: number
  quantity: number
}

function Header() {

  const navigate = useNavigate()

  const { user, logout } = useContext(LoginContext);
  const { cart, load } = useContext(CartContext)
  const { handleOpenModalLogin } = useContext(ModalsContexts);

  const [open, setOpen] = useState<boolean>(false)
  const [openCart, setOpenCart] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")

  const URL = process.env.REACT_APP_URL_AWS_S3

  useEffect(() => {
    if(open) {
      setOpenCart(false)
      return
    }
  }, [open])

  useEffect(() => {
    if(openCart) {
      setOpen(false)
      return
    }
  }, [openCart])

  const handleOpenCart = () => {
    if(!user || !user?.token) {
      handleOpenModalLogin()
      return
    }
    setOpenCart(!openCart)
  }

  const searchCupons = () => {
    if(!search){
      return
    }
    navigate(`/search?search=${search}`)
  }

  const keyPressSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter') {
      searchCupons()
    }
  }

  return (
    <Container>
      <a href="/">
        <Logo className='logo' />
      </a>

      <Search>
        <Input
          type='text'
          name='search-cuponzin'
          id='search-cuponzin'
          placeholder='O que você procura?'
          onChange={(event) => setSearch(event.target.value)}
          onKeyDown={(event) => keyPressSearch(event)}
        />
        <ButtonSearch onClick={() => searchCupons()}>
          <SearchIcon />
        </ButtonSearch>
      </Search>

      <CartAndLogin>
        {
          user && user?.token && user?.token === process.env.REACT_APP_ADMIN_TOKEN || user?.token === process.env.REACT_APP_RESTAURANT_TOKEN ? null :
          (
            <CartButton onClick={handleOpenCart}>
              {
                user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN ?
                (
                  user.Carts.length ? <div className="count"><span>{user.Carts.length}</span></div> : null
                )
                : null
              }

              {
                user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN ?
                (
                  <div className="cart-items"style={openCart ? {height: "auto", padding: '4px'} : { height: '0px', padding: '0px'}}>

                    <div className="list">
                      { load ? <UseAnimations animation={loading} size={28} strokeColor={theme.colors.text} />
                        :
                        (
                          cart.map((item: itemCupon) => (
                            <a href={`/cupon/${item?.cupon_id}`} key={item?.cupon_id} className="item-cart-list">
                              <img src={`${URL}/${item.picture}`} alt="" />
                              <div className="text">
                                <div className="price-and-qntd">
                                  <h4>R$ {String(item.price_purchase.toFixed(2)).replace(".", ",")}</h4>
                                  <h5>x{item.quantity}</h5>
                                </div>
                                <h4>R$ {String((item.price_purchase * item.quantity).toFixed(2)).replace(".", ",")}</h4>
                              </div>
                            </a>
                          ))
                        )
                      }
                    </div>
                    <a href="/cart" className='button'>
                      Abrir carrinho
                    </a>
                  </div>
                )
                : null
              }

              <CartIcon />
            </CartButton>
          )
        }

        {
          user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN ?
          (
            <UserButton onClick={() => setOpen(!open)}>
              <span className='name'>Olá, {user?.name.split(" ")[0]}</span>
              <div>
                <UserIcon />
              </div>
              <div className="options" style={open ? {padding: "4px", height: "auto"} : {padding: "0px", height: "0px"}}>
                <LinkOp to="/perfil/info">Perfil</LinkOp>
                <LinkOp to="/cupons">Cupons</LinkOp>
                <LinkOp to="/favoritos">Favoritos</LinkOp>
                <span className='op' onClick={() => logout()}>LogOut</span>
              </div>
            </UserButton>
          ) : null
        }

        {
          user && user?.token && user?.token.split("###")[0] === process.env.REACT_APP_ADMIN_TOKEN ?
          (
            <UserButton onClick={() => setOpen(!open)}>
              <span className='name'>Olá, {user?.name.split(" ")[0]}</span>
              <div>
                <UserIcon />
              </div>
              <div className="options" style={open ? {padding: "4px", height: "auto"} : {padding: "0px", height: "0px"}}>
                <LinkOp to="/admin/home">Plataforma</LinkOp>
                <span className='op' onClick={() => logout()}>LogOut</span>
              </div>
            </UserButton>
          ) : null
        }

{
          user && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN ?
          (
            <UserButton onClick={() => setOpen(!open)}>
              <span className='name'>Olá, {user?.name.split(" ")[0]}</span>
              <div>
                <UserIcon />
              </div>
              <div className="options" style={open ? {padding: "4px", height: "auto"} : {padding: "0px", height: "0px"}}>
                <LinkOp to="/restaurante/home">Plataforma</LinkOp>
                <span className='op' onClick={() => logout()}>LogOut</span>
              </div>
            </UserButton>
          ) : null
        }

        {
          !user ?
          (
            <LoginButton onClick={handleOpenModalLogin}>
              <span>Login</span>
              <div>
                <UserIcon />
              </div>
            </LoginButton>
          ) : null
        }
      </CartAndLogin>
    </Container>
  )
}

export default Header
