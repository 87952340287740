import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Container = styled.aside`
  height: 100vh;
  width: 240px;
  min-height: 240px;
  border-right: 1px solid ${theme.colors.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const Logo = styled.a`
  text-decoration: none;
  width: 80%;
  border-bottom: 1px solid ${theme.colors.gray200};
  margin-top: 40px;

  svg {
    height: 28px;
    width: 100%;
    padding-bottom: 24px;

    path, rect, polygon {
      fill: ${theme.colors.black};
    }
  }
`;
