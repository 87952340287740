import React from 'react'
import { Container } from './style';
import Header from '../../components/Header';

function NotFound() {

  return (
    <Container>
      <Header />
      <h1><span>404</span><br /> Página não encontrada</h1>
    </Container>
  )
}

export default NotFound
