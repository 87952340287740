import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../styles/Theme";
import { NavLink } from "react-router-dom";

Modal.setAppElement("#root")

export const Container = styled(Modal)`
  width: 90%;
  max-width: 560px;
  height: auto;
  max-height: 90vh;
  min-height: 80px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  transition: all .4s;
  position: relative;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  div.line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  svg.logo-modal{
    height: 24px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;

    path, rect, polygon{
      fill: ${theme.colors.primary};
    }
  }

  svg.back{
    height: 32px;
    width: 32px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: transparent;

    path {
      stroke: ${theme.colors.gray400};
    }
    rect{
      fill: transparent;
    }
  }

  span{
    margin: 0;
    font-size: 12px;
    margin-bottom: 16px;
    color: ${theme.colors.lightBlue};
  }

  h2{
    width: 90%;
    margin: 0;
    font-size: 16px;
    margin-bottom: 24px;
    color: ${theme.colors.text};
    text-align: center;
  }
`;

export const Form = styled.form`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.terms-and-privacy{
    margin: 8px 0;
    label span{
      margin-left: 8px;
      font-size: 16px;
      font-weight: 300;
      font-family: "NeueMachinaLight";
      color: ${theme.colors.text};

      a{
        color: ${theme.colors.lightBlue};
      }
    }
  }
`;

export const Link1 = styled(NavLink)`
  font-size: 16px;
  text-decoration: none;
  color: ${theme.colors.gray400};
  font-weight: 100;
  font-family: "NeueMachinaLight";
  margin: 0;
  transition: all .4s;

  &:hover{
    color: ${theme.colors.lightGreen};
  }
`;

export const Open = styled.h3`
  font-size: 16px;
  text-decoration: none;
  color: ${theme.colors.gray400};
  font-weight: 100;
  font-family: "NeueMachinaLight";
  margin: 0;
  transition: all .4s;
  cursor: pointer;

  &:hover{
    color: ${theme.colors.lightGreen};
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
  border: none;
  background-color: ${theme.colors.lightGreen};
  font-size: 18px;
  color: ${theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
`;
