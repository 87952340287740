import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { LoginContext } from "./LoginContext";
import { toast } from "react-toastify";
import { deleteCupon, showCupon, updateQuantityCupon } from "../services/api";

export const CartContext = createContext();

export function CartProvider({ children }) {

  const { user, setUser } = useContext(LoginContext)

  const [cart, setCart] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [load, setLoading] = useState(false);

  async function getItemsCart() {
    setLoading(true)
    const result = await showCupon(user.id)

    if (Array.isArray(result) && result.length >= 2) {
      const code = result[0];
      const response = result[1];

      if (code === 200) {
        setCart(response)
        let price = 0

        response.map((item) => price = price + item.price_purchase * item.quantity)
        setTotalPrice(price)
        setLoading(false)
        return
      }
    }
    toast.error("Error ao obter dados do carrinho")
    setLoading(false)
  }

  useEffect(() => {
    if(user && user?.token && user?.id && user?.token === process.env.REACT_APP_USER_TOKEN){
      getItemsCart();
      return
    }
    setCart([])
  }, [user])

  const updateQuantity = async (quantity, cuponId) => {
    if(user && user?.id && Array.isArray( user?.Carts)) {
      const data = {
        cupon_id: cuponId,
        user_id: user.id,
        quantity,
      }

      const code = await updateQuantityCupon(data);

      if(code === 200) {
        setUser({
          ...user,
          Carts: user.Carts.map((item) => item.cupon_id === cuponId ? { ...item, quantity } : item)
        })
        return
      }

      toast.error("Error ao atualizar quantidade");
    }
  }

  const deleteCuponOfCart = async (cuponId) => {
    if(user && user?.id) {
      const data = {
        cupon_id: cuponId,
        user_id: user.id,
      }

      const code = await deleteCupon(data);

      if(code === 200) {
        setCart([])
        setUser({
          ...user,
          Carts: user.Carts.filter((item) => item.cupon_id !== cuponId)
        })
        toast.success("Cupon removido");
        return
      }

      toast.error("Error ao deletar cupon");
    }
  }


  return(
    <CartContext.Provider
      value={{
        cart, setCart,
        totalPrice, setTotalPrice,
        load, setLoading,
        updateQuantity, deleteCuponOfCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
