import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import { AppRoutes } from './routes'

import { Theme } from './styles/Theme'
import { Normalize } from 'styled-normalize'
import { ModalsProvider } from './contexts/ModalsContexts'
import { LoginProvider } from './contexts/LoginContext'
import ModalRegisterUser from './components/ModalRegisterUser'
import ModalLogin from './components/ModalLogin'
import { CartProvider } from './contexts/CartContext';

export default function App() {

  return (
    <BrowserRouter>
      <LoginProvider>
        <CartProvider>
          <ModalsProvider>
            <Theme>
              <ToastContainer />
              <ModalLogin />
              <ModalRegisterUser />
              <AppRoutes />
              <Normalize />
            </Theme>
          </ModalsProvider>
        </CartProvider>
      </LoginProvider>
    </BrowserRouter>
  )
}
