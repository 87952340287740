import React, { useEffect, useContext, useState} from 'react'
import { Container, Coupon, CuponsList, LoadMore, Select, Values } from './styles'
import { LoginContext } from '../../../contexts/LoginContext'
import { salesRestaurant, salesRestaurantValues, updateRestaurant } from '../../../services/api'
import { toast } from 'react-toastify'

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';

type typeCupon = {
  id: number
  plate_name: string
  picture: string
  count: number
  price: number
  total: number
}

type typeValue = {
  price: number
  total: number
}


const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];



function Home() {

  const { user, setUser } = useContext(LoginContext)

  const [cupons, setCupons] = useState<typeCupon[]>([])
  const [load, setLoad] = useState<boolean>(false)
  const [values, setValues] = useState<{ total: number, price: number }>({ total: 0, price: 0})

  const [page, setPage] = useState<number>(1)

  const URL = process.env.REACT_APP_URL_AWS_S3

  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const [date, setDate] = useState(`${monthNames[currentMonth-1]} ${currentYear}`)
  const [open, setOpen] = useState<string>("")

  useEffect(() => {
    async function openOrCloseRestaurant() {
      const openOrClose:boolean = open === "Aberto" ? true : false
      const code = await updateRestaurant({ open: openOrClose}, user.id)
      
      if(code === 200) {
        setUser({
          ...user,
          open: openOrClose
        })
      }
    }
    if(user && user?.id && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      openOrCloseRestaurant()
    }
  }, [open])

  const getSales = async () => {
    setLoad(true)
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}&page=1`


    const result = await salesRestaurant(user.id, search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as typeCupon[];

      if (code === 200) {
        setCupons(response)
        setLoad(false)
        return
      }

      toast.error("Erro ao obter cupons")
      setLoad(false)
    }
  }

  const getSales2 = async () => {
    setLoad(true)
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}&page=${page}`


    const result = await salesRestaurant(user.id, search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as typeCupon[];

      if (code === 200) {
        setCupons([...cupons, ...response])
        setLoad(false)
        return
      }

      toast.error("Erro ao obter cupons")
      setLoad(false)
    }
  }

  const getSalesValues = async () => {
    const search = `month=${date.split(" ")[0]}&year=${date.split(" ")[1]}`

    setLoad(true)
    const result = await salesRestaurantValues(user.id, search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as typeValue;

      if (code === 200) {
        setValues(response)
        setLoad(false)
        return
      }

      toast.error("Erro ao obter cupons")
      setLoad(false)
    }
  }

 

  useEffect(() => {
    if(user && user?.id && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      getSales()
      getSalesValues()
      setCupons([])
    }
  }, [user, date])

  useEffect(() => {
    if(user && user?.id && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      getSales2()
    }
  }, [page])

  const [months, setMonths] = useState<string[]>([])

  useEffect(() => {
    const monthOptions = [];

    for (let year = 2023; year <= currentYear; year++) {
      const startMonth = year === 2023 ? 6 : 1;
      const endMonth = year === currentYear ? currentMonth : 12;
      for (let month = startMonth; month <= endMonth; month++) {
        const optionValue = `${monthNames[month - 1]} ${year}`;
        monthOptions.push(optionValue);
      }
    }
    setMonths(monthOptions)
  }, [])


  return (
    <Container>
      <div className="line">
        <Select value={date} name='interval' id="interval" onChange={(event) => setDate(event.target.value)}>
          {
            months.map(month => <option key={month} value={month}>{month}</option>)
          }
        </Select>

        <Select value={user?.open ? "Aberto" : "Fechado"} name='open-restaurant' id="open-restaurant" onChange={(event) => setOpen(event.target.value)}>
          <option value="Aberto">Aberto</option>
          <option value="Fechado">Fechado</option>
        </Select>
      </div>

      <Values>
        <div className="content">
          <h4>Valor total</h4>
          <h2>R$ {values.total ? String(values.total.toFixed(2)).replace(".", ',') : "0,00"}</h2>
        </div>

        <div className="content">
          <h4>Valor a receber</h4>
          <h2>R$ {values.price ? String(values.price.toFixed(2)).replace(".", ',') : "0,00"}</h2>
        </div>
      </Values>

      <CuponsList>
        {
          cupons.map((item) => (
            <Coupon key={item.id}>
              <img src={`${URL}/${item.picture}`} alt={item.plate_name} />
              <div className="text">
                <h2>{item.plate_name}</h2>
                <h3>Vendas: {item.count}</h3>
                <h3>Valor a receber: R$ {item.price ? String(item.price.toFixed(2)).replace(".", ',') : "0,00"}</h3>
                <h3>Valor total em vendas: R$ {item.total ? String(item.total.toFixed(2)).replace(".", ',') : "0,00"}</h3>
              </div>
            </Coupon>
          ))
        }
      </CuponsList>

      {
        load ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          cupons.length / page < page * 20 ? null : 
          (
            <LoadMore onClick={() => setPage(page + 1)}>
              Carregar mais
            </LoadMore>
          )
        )
      }

    </Container>
  )
}

export default Home
