import React, { useState, useContext, useEffect } from 'react'
import { Button, Container, Form, Open } from './styles'
import { ModalsContexts } from '../../contexts/ModalsContexts'
import "./style.css";

import {ReactComponent as Logo } from "../../assets/images/Salmao.svg"
import {ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg"
import InputField from '../InputField';
import InputMaskField from '../InputMaskField';
import PasswordField from '../PasswordField';
import { codeConfirmation, createUser, verifyCodeConfirm, verifyEmail } from '../../services/api';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

function ModalRegisterUser() {

  const { modalRegister, handleCloseModalRegister, handleOpenModalLogin } = useContext(ModalsContexts);
  const [info, setInfo] = useState("⠀")
  const [codeConfirm, setCodeConfirm] = useState()
  const [wrong, setWrong] = useState(false)
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState()
  const handleName = (value) => {
    setName(value)
  }

  const [tel, setTel] = useState()
  const handleTel = (value) => {
    setTel(value)
  }

  const [email, setEmail] = useState()
  const handleEmail = (value) => {
    setEmail(value)
  }

  const [password, setPassword] = useState()
  const handlePassword = (value) => {
    setPassword(value)
  }

  const [confirmpassword, setConfirmPassword] = useState()
  const handleConfirmPassword = (value) => {
    setConfirmPassword(value)
  }

  const [verifyCode, setVerifyCode] = useState()
  const handleCode = (value) => {
    setVerifyCode(value)
  }

  useEffect(() => {
    if(password === confirmpassword) setWrong(false)
    else setWrong(true)
  }, [password, confirmpassword])

  useEffect(() => {
    setInfo("⠀")
  }, [])

  const getCodeConfirmation = async (event) => {
    event.preventDefault()
    if(!name || !tel || !email || !password || !confirmpassword) {
      setInfo("Por favor! Preencha todos os campos")
      return
    }
    if(!(email.includes("@")) || !(email.includes("."))){
      setInfo("Coloque um email válido")
      return
    }
    if(password.includes(" ")){
      setInfo("Não pode contêr espaços em brancos")
      setWrong(true)
      return
    }
    if(password.length < 8){
      setInfo("Mínimo de 8 caractéres")
      setWrong(true)
      return
    }

    if(password !== confirmpassword){
      setInfo("As senhas não combinam")
      setWrong(true)
      return
    }

    setLoading(true)
    const status = await verifyEmail({ email });

    if (status === 200) {
      const data = {
        name,
        email
      }

      const [code, response] = await codeConfirmation(data);
      setLoading(false)

      if(code === 200) {
        setInfo("⠀")
        setCodeConfirm(response.code)
        return
      }
      if(code === 400) {
        setInfo("Erro ao enviar o código")
        return
      }

      setInfo(`${code} ERROR`)
      return
    }

    if (status === 400) {
      setInfo("Erro ao verificar email")
      setLoading(false)
      return
    }

    if (status === 403) {
      setInfo("Email já cadastrado")
      setLoading(false)
      return
    }

    setInfo(`${status} ERROR`)
    setLoading(false)
  }

  const resendCode = async () => {
    if(!name || !tel || !email || !password || !confirmpassword) {
      setInfo("ERROR! Tente novamente mais tarde")
      return
    }

    setLoading(true)
    const data = {
      name,
      email,
    }

    const [code, response] = await codeConfirmation(data);
    setLoading(false)

    if(code === 200) {
      setInfo("Código reenviado")
      setCodeConfirm(response.code)
      return
    }
    if(code === 400) {
      setInfo("Erro ao enviar o código")
      return
    }

    setInfo(`${code} ERROR`)
  }

  const createAccount = async () => {
    setInfo("⠀")
    if(!name || !tel || !email || !password || !confirmpassword || !codeConfirm) {
      setInfo("ERROR! Tente novamente mais tarde")
      return
    }

    if(!verifyCode){
      setInfo("Digite o código")
      return
    }

    if(verifyCode.includes("_")){
      setInfo("Digite o código inteiro")
      return
    }

    setLoading(true)
    const status = await verifyCodeConfirm({ hash: codeConfirm, code: verifyCode})

    if(status === 200) {
      const data = {
        name,
        email,
        tel,
        password,
      }

      const code = await createUser(data);
      setLoading(false)

      if(code === 201) {
        setCodeConfirm("")
        setVerifyCode("")
        setEmail("")
        setTel("")
        setName("")
        setPassword("")
        setConfirmPassword("")

        handleOpenModalLogin()
        return
      }

      if(code === 400){
        setInfo("Erro ao criar conta")
        return
      }

      if(code === 403){
        setInfo("Este email já foi cadastrado")
        return
      }

      setInfo(`${code} ERROR`)
      return
    }

    if(status === 400) {
      setInfo("Erro ao confirmar o código")
      setLoading(false)
      return
    }

    if(status === 403) {
      setInfo("Código inválido")
      setLoading(false)
      return
    }

    setInfo(`${status} ERROR`)
    setLoading(false)
  }


  return (
    <Container
      isOpen={modalRegister}
      onRequestClose={handleCloseModalRegister}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      {
        codeConfirm ? <ArrowLeftIcon className='back' onClick={() => {setCodeConfirm(""); setVerifyCode("")}} /> : null
      }

      <Logo className='logo-modal'/>
      <span>{info}</span>
      {
        !loading && !codeConfirm ?
        (
          <Form onSubmit={getCodeConfirmation}>

          <div className="line">
            <InputField
              placeHolder='NOME'
              value={name}
              changeValue={handleName}
              type='name'
              id='name'
              required={true}
              width={48}
            />

            <InputMaskField
              placeHolder='TELEFONE'
              value={tel}
              changeValue={handleTel}
              type='tel'
              id='tel'
              required={true}
              width={48}
              mask="(99) 9 9999-9999"
            />
          </div>

          <InputField
            placeHolder='E-MAIL'
            value={email}
            changeValue={handleEmail}
            type='email'
            id='email'
            required={true}
          />

          <div className="line">
            <PasswordField
              placeHolder='SENHA'
              value={password}
              changeValue={handlePassword}
              type='password'
              id='password'
              width={48}
              required={true}
              wrong={wrong}
            />

            <PasswordField
              placeHolder='CONFIRMAR SENHA'
              value={confirmpassword}
              changeValue={handleConfirmPassword}
              type='password'
              id='confirm-password'
              width={48}
              required={true}
              wrong={wrong}
            />

          </div>

          <div className="terms-and-privacy">
            <input type="checkbox" name="terms-and-privacy" id="terms-and-privacy" required/>
            <label htmlFor="terms-and-privacy"><span>Ao criar conta você concorda com nossos <a href="./termos-de-uso" target="_blank">Termos de Uso</a> e <a href="./politicas-de-privacidade" target="_blank">Políticas de Privacidade</a> ?</span></label>
          </div>

          <Button
            type='submit' style={{marginTop: '20px'}}
          >
            Criar conta
          </Button>

          <Open
            style={{marginBottom: "12px", marginTop: '12px', fontSize: "14px"}}
            onClick={handleOpenModalLogin}
          >Fazer login</Open>
        </Form>
        )
        :
        (
          !loading ?
          (
            <>
              <h2>Um código de confirmação foi enviado para o email {email}</h2>

              <InputMaskField
                placeHolder='CÓDIGO DE CONFIRMAÇÃO'
                value={verifyCode}
                changeValue={handleCode}
                type='text'
                id='code'
                required={true}
                mask="999999"
                width={90}
              />

              <Open
                style={{marginBottom: "12px", marginTop: '20px', fontSize: "14px"}}
                onClick={resendCode}
              >Reenviar código</Open>

              <Button
                type='submit' style={{marginBottom: "20px", marginTop: '20px', width: "90%"}}
                onClick={createAccount}
              >
                Verificar código
              </Button>
            </>
          )
          :
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} />
          )
        )
      }

    </Container>
  )
}


export default ModalRegisterUser
