import React, { useContext, useState } from 'react'
import { Button, Container, Content, Field, Input, Resend } from './style'

import { ReactComponent as Logo } from '../../assets/images/Salmao.svg'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

import { toast } from 'react-toastify'
import { confirmCodeToSwitchPassword, confirmEmailToSwitchPassword, switchPassword } from '../../services/api'

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { useNavigate } from 'react-router-dom'
import { ModalsContexts } from '../../contexts/ModalsContexts'
import { LoginContext } from '../../contexts/LoginContext'

function ForgotPassword() {

  const { handleOpenModalLogin } = useContext(ModalsContexts)
  const { logout } = useContext(LoginContext)

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [code, setCode] = useState("")
  const [load, setLoad] = useState(false)

  const [codeHash, setCodeHash] = useState("")

  const [emailToConfirm, setEmailToConfirm] = useState()
  const [codeToConfirm, setCodeToConfirm] = useState()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const sendEmailToConfirm = async () => {
    if(!email) {
      toast.warn("Preencha os campos")
      return
    }

    setLoad(true)
    const [status, response] = await confirmEmailToSwitchPassword({ email })

    if (status === 200) {
      setCodeHash(response.code)
      toast.success("Código enviado para o email")
      setEmailToConfirm(email)
      setLoad(false)
      return
    }

    if(status === 404) {
      toast.error("Email não cadastrado")
      setLoad(false)
      return
    }

    toast.error("Error ao confirmar email")
    setLoad(false)
    return
  }

  const resendCode = async () => {
    if(!email) {
      toast.error("Error ao reenviar código")
      return
    }

    setLoad(true)
    const [status, response] = await confirmEmailToSwitchPassword({ email })

    if (status === 200) {
      setCodeHash(response.code)
      toast.success("Código reenviado")
      setEmailToConfirm(email)
      setLoad(false)
      return
    }

    toast.error("Error ao reenviar código")
    setLoad(false)
    return
  }

  const sendCodeToConfirm = async () => {
    if(!code) {
      toast.warn("Digite um código válido")
      return
    }

    if(!codeHash) {
      toast.error("Error ao confirmar código")
      return
    }

    setLoad(true)
    const status = await confirmCodeToSwitchPassword({ code, hash: codeHash })

    if (status === 200) {
      setCodeToConfirm(code)
      setLoad(false)
      return
    }

    toast.error("Error ao confirmar código")
    setLoad(false)
    return
  }

  const switchPass = async () => {
    if (!password || !confirmPassword) {
      toast.warn("Preencha os campos")
      return
    }
    if(password.includes(" ")){
      toast.warn("Não pode contêr espaços em brancos")
      return
    }
    if(password.length < 8){
      toast.warn("Mínimo de 8 caractéres")
      return
    }

    if(password !== confirmPassword){
      toast.warn("As senhas não combinam")
      return
    }

    if(!email) {
      toast.error("Não foi possível atualizar a senha")
      return
    }

    setLoad(true)
    const status = await switchPassword({ email, password })

    if(status === 200) {
      setEmail("")
      setEmailToConfirm("")
      setCode("")
      setCodeToConfirm("")
      setPassword("")
      setConfirmPassword("")
      toast.success("Senha atualizada com sucesso")
      setLoad(false)
      navigate("/")
      logout()
      handleOpenModalLogin()
      return
    }

    toast.error("Não foi possível atualizar a senha")
    return

  }

  return (
    <Container>
      <Content>
        <Logo className='logo' />

        {
          load ?  <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} /> :
          (
            !emailToConfirm ?
            (
              <>
                <h1>Digite um e-mail</h1>
                <Field>
                  <h3>E-mail</h3>
                  <Input>
                    <input value={email} type='text' name='email' id='email' maxLength={255} placeholder='E-mail' onChange={(event) => setEmail(event.target.value)} />
                  </Input>
                </Field>

                <Button onClick={sendEmailToConfirm}>Confirmar</Button>
              </>
            )
            :
            (
              !codeToConfirm ?
              (
                <>
                  <h3 className="top">Um código foi enviado para o email <span>{emailToConfirm}</span></h3>
                  <Field>
                    <h3>Código</h3>
                    <Input>
                      <input value={code} type='text' name='confirmCode' id='confirmCode' maxLength={6} placeholder='Digite o código' onChange={(event) => setCode(event.target.value)} />
                    </Input>
                  </Field>

                  <Resend onClick={resendCode}>Reenviar código</Resend>

                  <Button onClick={sendCodeToConfirm}>Confirmar</Button>
                </>
              )
              :
              (
                <>
                  <h1>Digite uma nova senha</h1>
                  <Field>
                    <h3>Nova senha</h3>
                    <Input>
                      <input value={password} type={ showPassword ? "text" : "password"} name='password' id='password' maxLength={255} placeholder='Nova senha' onChange={(event) => setPassword(event.target.value)} />
                      {
                        showPassword ? <AiOutlineEyeInvisible onClick={() => setShowPassword(false)} /> : <AiOutlineEye onClick={() => setShowPassword(true)} />
                      }
                    </Input>
                  </Field>

                  <Field>
                    <h3>Confirmar nova senha</h3>
                    <Input>
                      <input value={confirmPassword} type={ showConfirmPassword ? "text" : "password"} name='confirm-password' id='confirm-password' maxLength={255} placeholder='Confirmar nova senha' onChange={(event) => setConfirmPassword(event.target.value)} />
                      {
                        showConfirmPassword ? <AiOutlineEyeInvisible onClick={() => setShowConfirmPassword(false)} /> : <AiOutlineEye onClick={() => setShowConfirmPassword(true)} />
                      }
                    </Input>
                  </Field>

                  <Button onClick={switchPass}>Confirmar</Button>
                </>
              )
            )
          )
        }
      </Content>
    </Container>
  )
}

export default ForgotPassword
