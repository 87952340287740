import { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, NavigationDiv } from './styles';
import Card from '../../../../components/Card';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/arrow-right.svg';

// import Burger from '../../../../assets/images/burger.png'
// import Japa from '../../../../assets/images/japa.png'
// import Pizza from '../../../../assets/images/pizza.png'


import 'swiper/css';
import 'swiper/css/bundle';
import { theme } from '../../../../styles/Theme';


function Carousel() {

  const [slides, setSlides] = useState(window.innerWidth / 524)

  // const categories = [
  //   {id: 1, text: "Lanches", image: Burger },
  //   {id: 2, text: "Japonesa", image: Japa },
  //   {id: 3, text: "Pizza", image: Pizza },
  //   {id: 4, text: "Lanches", image: Burger },
  //   {id: 5, text: "Japonesa", image: Japa },
  //   {id: 6, text: "Pizza", image: Pizza },
  // ]

  const next = useRef()
  const prev = useRef()
  const swiperRef = useRef(null);

  useEffect(() => {
    function handleSize () {
      if (window.innerWidth <= 768) {
        const calc = window.innerWidth / (window.innerWidth * 0.85 + 24)
        setSlides(calc)
        return
      }
      const calc = window.innerWidth / 524
      setSlides(calc)
    }

    window.addEventListener("resize", handleSize)

    return (() => {
      window.removeEventListener("resize", handleSize)
    })

  }, [])

  return (
    <Container>
      <Swiper
        className="content"
        spaceBetween={24}
        slidesPerView={slides}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={{
          nextEl: next,
          prevEl: prev
        }}
        autoplay
        pagination={{ clickable: true }}
      >
        <SwiperSlide className='slider'>
          <Card text="Os cuponzin mais vendidos" color={theme.colors.lightBlue} link='/mais-vendidos' />
        </SwiperSlide>

        <SwiperSlide className='slider'>
          <Card text="Os cuponzin com mais descontos" color={theme.colors.lightGreen} link='/mais-descontos' />
        </SwiperSlide>

        <SwiperSlide className='slider'>
          <Card text="Os cuponzin mais procurados" color={theme.colors.primary} link='/mais-procurados' />
        </SwiperSlide>

      </Swiper>

      <NavigationDiv>
        <ArrowLeftIcon
          ref={prev}
          // className={swiperRef.current && swiperRef.current.isBeginning ? 'disable' : ''}
          onClick={() => swiperRef.current.slidePrev()} />

        <ArrowRightIcon
          ref={next}
          // className={swiperRef.current && swiperRef.current.isEnd ? 'disable' : ''}
          onClick={() => swiperRef.current.slideNext()} />
      </NavigationDiv>
    </Container>
  );
}

export default Carousel;
