import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const Container = styled.section`
  width: calc(100vw - 241px - 24px);
  min-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  margin-right: -24px;
  margin-bottom: -24px;
`;

export const Select = styled.select`
  width: 192px;
  height: 32px;
  font-size: 16px;
  font-family: "NeueMachinaLight";
  font-weight: 200;
  background-color: transparent;
  border: none;
  color: ${theme.colors.text};

  option{
    font-size: 14px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
  }
`;

export const Values = styled.div`
  display: flex;
  align-items: center;
  gap: 128px;
  height: 96px;

  div.content{
    height: 100%;
    width: 192px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    h4{
      font-size: 14px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      margin: 0;
    }

    h2{
      font-size: 20px;
      color: ${theme.colors.text};
      margin: 0;
    }
  }
`;

export const RestaurantsList = styled.div`
  width: calc(100vw - 241px - 48px);
  height: calc(100vh - 312px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray400};
  }
`;

export const Input = styled.div`
  width: calc(100vw - 241px - 48px);
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  background-color: ${theme.colors.white};
  margin: 16px 0;
  display: flex;
  align-items: center;

  input{
    width: 98%;
    margin-left: 8px;
    height: 32px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.black};

    &::placeholder{
      color: ${theme.colors.gray200};
    }
  }
`;

export const Restaurant = styled.div`
  height: auto;
  min-height: 192px;
  width: 100%;
  display: flex;
  background-color: ${theme.colors.white};
  position: relative;
  gap: 20px;
  padding: 12px 0;

  div.text{
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 12px;

    h2{
      font-size: 20px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      margin: 0;
      margin-bottom: 4px;
    }

    h3{
      font-size: 12px;
      font-family: "NeueMachinaLight";
      color: ${theme.colors.gray400};
      margin: 0;
    }

    div.location{
      display: flex;
      align-items: center;

      svg{
        height: 16px;
        width: 16px;
        margin-right: 8px;
        path{
          fill: ${theme.colors.gray400};
        }
      }

      h3{
        font-size: 12px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.gray400};
        margin: 0;
        margin-bottom: -2px;
      }
    }

    div.value{
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin-top: 12px;

      h5{
        font-size: 10px;
        font-family: "NeueMachinaLight";
        color: ${theme.colors.gray400};
        margin: 0;
      }

      h4{
        font-size: 18px;
        color: ${theme.colors.lightBlue};
        margin: 0;
      }
    }

    div.payment-data{
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin-bottom: 12px;

      h5{
        font-size: 10px;
        font-family: "NeueMachinaLight";
        color: ${theme.colors.gray400};
        font-weight: 200;
        margin: 0;
      }

      h4{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        color: ${theme.colors.gray400};
        margin: 0;
      }
    }
  }

  div.status{
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    right: 12px;
    bottom: 12px;

    h4{
      font-size: 14px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      margin: 0;
    }

    svg{
      height: 24px;
      width: 24px;
      padding: 4px;
      border-radius: 50%;
      background-color: ${theme.colors.white};

      path{
        stroke: ${theme.colors.text};
      }

      &.yes{
        background-color: ${theme.colors.lightGreenBG};
        path{
          stroke: ${theme.colors.lightGreen};
        }
      }

      &.no{
        background-color: ${theme.colors.primaryBG};
        cursor: pointer;

        path{
          stroke: ${theme.colors.primary};
        }
      }
    }
  }
`;

export const LoadMore = styled.div`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin: 24px 0;
`;

export const Options = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 24px;

  div.op{
    height: 100%;
    padding: 0 24px;
    border-radius: 20px;
    border: 1px solid ${theme.colors.black};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    h2{
      font-size: 12px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.black};
      margin: 0;
    }

    &.active{
      background-color: ${theme.colors.black};
      h2{
        color: ${theme.colors.white};
      }
    }
  }

  h2.clean{
    font-size: 12px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.black};
    margin: 0;
    cursor: pointer;
    text-decoration: underline;
  }
`;
