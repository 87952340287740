import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { AddCart, Container, Cupon, Description, Field, LoadCupon, Ratings, Send, ShareAndLike, Title, Title2, UserRating } from './style';
import Header from '../../components/Header';

import { CiLocationOn } from "react-icons/ci"
import { FaRegStar, FaStar } from "react-icons/fa"

import {ReactComponent as AddCartIcon} from "../../assets/icons/shopping-cart-add.svg"
import {ReactComponent as ReceiptIcon} from "../../assets/icons/receipt.svg"
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as SendIcon } from '../../assets/icons/send.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as TrashtIcon } from '../../assets/icons/trash.svg';

import { addCart, addFavorites, createRatings, deleteRate, getEspecifyCoupon, removeFavorites } from '../../services/api';
import { toast } from 'react-toastify';
import { LoginContext } from '../../contexts/LoginContext';
import { ModalsContexts } from '../../contexts/ModalsContexts';

import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

import CursorMask from '../../components/CursorMask';
import Highlights from '../../components/Highlights';
import Footer from '../../components/Footer';

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"
import { GiShare } from "react-icons/gi"
import { PageNavigations } from '../Search/style';

type ratingsType = {
  id: number
  stars: number
  name: string
  text: string
  user_id: number
  createdAt: string
}

type itemCupon = {
  id: number
  plate_name: string
  category: string
  picture: string
  price_purchase: number
  price_of: number
  quantity: number
  restaurant_id: number
  restaurant_name: string
  street: string
  neighborhood: string
  number: number
  postal_code: string
  qntd_ratings: number
  avg: number
  description: string

  Ratings: ratingsType[]

}


function Product() {

  const { cupon } = useParams();
  const { user, setUser } = useContext(LoginContext)
  const { handleOpenModalLogin } = useContext(ModalsContexts)

  const [cuponData, setCuponData] = useState<itemCupon>();
  const [load, setLoad] = useState<boolean>(true);
  const [loadPage, setLoadPage] = useState<boolean>(false);
  const [loadRating, setLoadRating] = useState<boolean>(false);
  const [loadFavorite, setLoadFavorite] = useState<boolean>(false)
  const [load2, setLoad2] = useState<boolean>(false);
  const [stars, setStars] = useState<number>(4);
  const [page, setPage] = useState<number>(1);
  const [text, setText] = useState<string>("");

  const descriptionWithBreaks = cuponData?.description.split('\n').map((line, index) => (
    <div key={index}>{line}<br/></div>
  ));

  const URL = process.env.REACT_APP_URL_AWS_S3

  const addFavoriteCupon = async (cuponId: number) => {
    if(loadFavorite){
      return
    }
    if(!user || !user?.token) {
      handleOpenModalLogin()
      return
    }

    if(user && user.token === process.env.REACT_APP_USER_TOKEN  && Array.isArray(user?.favorites_id)) {
      setLoadFavorite(true)
      const code = await addFavorites({ cupon_id: cuponId, user_id: user.id })

      if (code === 201) {
        const favorites = user.favorites_id
        favorites.push(cuponId)
        setUser({
          ...user,
          favorites_id: favorites
        })
        setLoadFavorite(false)
        return
      }
      if (code === 400) {
        toast.error("Erro ao adicionar aos favoritos")
        setLoadFavorite(false)
        return
      }
      if (code === 401) {
        toast.warn("Este cupon já está adicionado nos favoritos")
        setLoadFavorite(false)
        return
      }
      toast.error("Erro ao adicionar aos favoritos")
      setLoadFavorite(false)
      return
    }
    setLoadFavorite(false)
  }

  const removeFavoriteCupon = async (cuponId: number) => {
    if(loadFavorite){
      return
    }

    if(!user || !user?.token) {
      handleOpenModalLogin()
      return
    }

    if(user && user.token === process.env.REACT_APP_USER_TOKEN  && Array.isArray(user?.favorites_id)) {
      setLoadFavorite(true)
      const code = await removeFavorites({ cupon_id: cuponId, user_id: user.id })

      if (code === 200) {
        setUser({
          ...user,
          favorites_id: user.favorites_id.filter((cupon: number) => cupon !== cuponId)
        })

        setLoadFavorite(false)
        return
      }
      if (code === 400) {
        toast.error("Erro ao remover dos favoritos")
        setLoadFavorite(false)
        return
      }
      toast.error("Erro ao remover dos favoritos")
      setLoadFavorite(false)
      return
    }
    toast.error("Erro ao remover dos favoritos")
    setLoadFavorite(false)
  }

  const addToCartFunc = async (cuponId: number) => {
    if(load2){
      return
    }

    if(!user || !user?.token) {
      handleOpenModalLogin()
    }

    if(user && user.token === process.env.REACT_APP_USER_TOKEN) {
      setLoad2(true)
      const code = await addCart({ cupon_id: cuponId, user_id: user.id, quantity: 1 })

      if (code === 201) {
        if(user.Carts.filter((item: { cupon_id: number }) => item.cupon_id === cuponId).length > 0) {
          setUser({
            ...user,
            Carts: user.Carts.map((item: { cupon_id: number, quantity: number }) => item.cupon_id === cuponId ? { ...item, quantity: item.quantity + 1 } : item)
          })
        } else {
          setUser({
            ...user,
            Carts: [...user.Carts, { user_id: user.id, cupon_id: cuponId, quantity: 1 }]
          })
        }
        toast.success("Adicionado no carrinho")
        setLoad2(false)
        return
      }
      if (code === 400) {
        toast.error("Erro ao adicionar ao carrinho")
        setLoad2(false)
        return
      }
      if (code === 406) {
        toast.error("Quantidade máxima excedida")
        setLoad2(false)
        return
      }
      toast.error("Erro ao adicionar ao carrinho")
      setLoad2(false)
    }
  }

  const deleteRateUser = async (rateId: number) => {
    if(!cuponData || !rateId) {
      return
    }

    setLoadRating(true)
    
    const code = await deleteRate(rateId)
    
    const newRatings: ratingsType[] = cuponData?.Ratings.filter((item) => item.id !== rateId)

    if(code === 200) {
      setCuponData({
        ...cuponData,
        Ratings: newRatings
      })
      toast.success("Comentário removido")
      setLoadRating(false)
      return
    }

    toast.error("Erro ao remover comentário")
    setLoadRating(false)
    return
  }

  async function getCuponData(){
    setTimeout(() => {
      setLoadPage(false)
    }, 1000)
    setLoad(true)

    let search = ""
    search += `&page=${page}`

    const result = await getEspecifyCoupon(Number(cupon), search)

    if(Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number
      const response = result[1] as itemCupon


      if(code === 200) {
        setCuponData(response)
        setLoad(false)
        return
      }
      toast.error("Cupon não encontrado")
    }
  }

  useEffect(() => {
    if(cupon) {
      getCuponData();
      return
    }
  }, [page])

  useEffect(() => {
    setLoadPage(true)
    if(cupon) {
      getCuponData();
      return
    }
    setTimeout(() => {
      setLoadPage(false)
    }, 1000)
  }, [cupon])

  const sendRating = async () => {
    if(loadRating) {
      return
    }

    if(!text || !user || !user?.id || !user?.token || user?.token !== process.env.REACT_APP_USER_TOKEN){
      toast.error("Error ao enviar avaliação")
      return
    }

    setLoadRating(true)

    const dataResponse = {
      name: user.name,
      user_id: user.id,
      cupon_id: Number(cupon),
      stars,
      text
    }


    const code = await createRatings(dataResponse)

    if(code === 201) {
      setText("")
      toast.success("Avaliação enviada")
      setLoadRating(false)
      return
    }
    toast.error(`${code} - Error ao enviar avaliação`)
    setLoadRating(false)
  }

  if(loadPage) {
    return (
      <Container />
    )
  }

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href)
    toast.success("Link copiado")
  }

  return (
    <Container>
      <CursorMask />
      <Header />
      {
        !load && cuponData ?
        (
          <Cupon>
            <img src={`${URL}/${cuponData.picture}`} alt={cuponData.plate_name} />
            <div className="discount">{(100 - ((cuponData.price_purchase * 100) / cuponData.price_of)).toFixed(0)}%</div>
            <div className="info">
              <ShareAndLike>
                <GiShare onClick={copyLink} />
                {
                  loadFavorite ? <UseAnimations animation={loading} size={32} strokeColor={theme.colors.gray400} /> :
                  (
                    user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN && user?.favorites_id.indexOf(Number(cupon)) !== -1 ? <AiFillHeart onClick={() => removeFavoriteCupon(Number(cupon))} className='like-marked' /> : <AiOutlineHeart onClick={() => addFavoriteCupon(Number(cupon))} />
                  )
                }
              </ShareAndLike>
              <div className="ratings">
                <div className="stars">
                  <FaStar className={cuponData.avg >= 1 ? "marked" : ""} />
                  <FaStar className={cuponData.avg >= 2 ? "marked" : ""} />
                  <FaStar className={cuponData.avg >= 3 ? "marked" : ""} />
                  <FaStar className={cuponData.avg >= 4 ? "marked" : ""} />
                  <FaStar className={cuponData.avg >= 5 ? "marked" : ""} />
                </div>
                <span>{cuponData.avg.toFixed(1)}</span>
                <span>({cuponData.qntd_ratings})</span>
              </div>
              <h1>{cuponData.plate_name}</h1>
              <h3>ID do Cupon: {cupon}</h3>

              <div style={{marginBottom: "0px"}} className="line">
                <div className="price">
                  <h2>De R${String((cuponData.price_of).toFixed(0))} por</h2>
                  <h1>R$ {String((cuponData.price_purchase).toFixed(2)).replace(".", ",")}</h1>
                </div>

                <div className="price">
                  <h2>Economize</h2>
                  <h1 className='economy'>R$ {String((cuponData.price_of - cuponData.price_purchase).toFixed(2)).replace(".", ",")}</h1>
                </div>
              </div>

              <h2 style={{fontSize: "16px", marginBottom: "12px"}}>Categoria: <a href={`/categoria/${cuponData.category.replaceAll(" ", "-").toLowerCase()}`}>{cuponData.category}</a></h2>
              <h2 style={{fontSize: "14px", marginBottom: "12px"}}>Estoque: {cuponData.quantity} Cupons</h2>

              <h2>Restaurante: <a href={`/restaurant/${cuponData.restaurant_id}`}>{cuponData.restaurant_name.replace("Restaurante", "").replace("restaurante", "").replace("RESTAURANTE", "")}</a></h2>

              <div className="location">
                <CiLocationOn />
                <h3>Rua {cuponData.street.replace("Rua", "").replace("rua", "").replace("RUA", "")} - {cuponData.number}, {cuponData.neighborhood} - {cuponData.postal_code}</h3>
              </div>

              <AddCart onClick={() => addToCartFunc(cuponData.id)}>
                {
                  load2 ? <UseAnimations animation={loading} size={28} strokeColor={theme.colors.white} /> :
                  (
                    <>
                      <span>Adicionar ao Carrinho</span>
                      <AddCartIcon />
                    </>
                  )
                }
              </AddCart>

            </div>
          </Cupon>
        )
        :
        (

          <LoadCupon>
            <div className="img" />
            <div className="discount" />
            <div className="info">
              <ShareAndLike>
                <GiShare />
                <AiOutlineHeart />
              </ShareAndLike>
              <div className="ratings">
                <div className="stars">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <div className="span" />
                <div className="span" />
              </div>
              <div className="h1" />
              <div className="h3" />

              <div style={{marginBottom: "0px"}} className="line">
                <div className="price">
                  <div className="h2-price" />
                  <div className="h1-price" />
                </div>
                <div className="price">
                  <div className="h2-price" />
                  <div className="h1-price economy" />
                </div>
              </div>

              <div style={{height: "18px", marginBottom: "12px", width: "140px"}} className="h3" />
              <div style={{height: "16px", marginBottom: "12px", width: "156px"}} className="h3" />
              <div className="h2" />
              <div className="location" />

              <AddCart>
                <span>Adicionar ao Carrinho</span>
                <AddCartIcon />
              </AddCart>
            </div>
          </LoadCupon>
        )
      }
      <Description>
        <Title>
          <ReceiptIcon />
          <h2>Descrição</h2>
        </Title>
        <h4>{descriptionWithBreaks}</h4>
      </Description>

      <Highlights text="Outros cupons" filter={`&except=${cupon}`} />

      {
        cuponData ? <Highlights text="Mais deste restaurante" filter={`&except=${cupon}&restaurant_id=${cuponData.restaurant_id}`} /> : null
      }

{
        cuponData ? <Highlights text="Mais desta categoria" filter={`&except=${cupon}&category=${cuponData.category.replaceAll("-", " ")}`} /> : null
      }

      <Ratings>
        <Title2>
          <FaRegStar />
          <h2>Avaliações</h2>
        </Title2>

        <Field>
          <h2>Fazer uma avaliação</h2>

          <div className="stars">
            <FaStar onClick={() => setStars(1)} className={stars >= 1 ? "marked" : ""} />
            <FaStar onClick={() => setStars(2)} className={stars >= 2 ? "marked" : ""} />
            <FaStar onClick={() => setStars(3)} className={stars >= 3 ? "marked" : ""} />
            <FaStar onClick={() => setStars(4)} className={stars >= 4 ? "marked" : ""} />
            <FaStar onClick={() => setStars(5)} className={stars >= 5 ? "marked" : ""} />
          </div>

          {
            loadRating ? <UseAnimations animation={loading} size={28} strokeColor={theme.colors.text} /> : <textarea value={text} onChange={(event) => setText(event.target.value)} placeholder='Avalie esse prato...' id='ratings' name='ratings' />
          }

          <Send onClick={sendRating}>
            <SendIcon />
          </Send>
        </Field>

        <PageNavigations>
          <div className="navigation">
            <ArrowLeftIcon
              className={page <= 1 ? "block" : ""}
              onClick={() => page <= 1 ? null : setPage(page - 1)}
            />
            <span>{String(page).padStart(2, "0")}</span>
            <ArrowRightIcon
              className={cuponData?.Ratings && cuponData?.Ratings.length < 20 ? "block" : ""}
              onClick={() => cuponData?.Ratings && cuponData?.Ratings.length < 20 ? null : setPage(page + 1)}
            />
          </div>
        </PageNavigations>

        {
          load ? <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} /> :
          (
            cuponData?.Ratings.map(item => (
              <UserRating key={item.id}>
                <div className="info">
                  <div className="user">
                    <UserIcon />
                  </div>

                  <div className="text">
                    <h2>{item.name}</h2>
                    <div className="stars">
                      <FaStar className={item.stars >= 1 ? "marked" : ""} />
                      <FaStar className={item.stars >= 2 ? "marked" : ""} />
                      <FaStar className={item.stars >= 3 ? "marked" : ""} />
                      <FaStar className={item.stars >= 4 ? "marked" : ""} />
                      <FaStar className={item.stars >= 5 ? "marked" : ""} />
                    </div>

                    <h3>{String(new Date(item.createdAt).getDate()).padStart(2, "0")}/{String(new Date(item.createdAt).getMonth()+1).padStart(2, "0")}/{new Date(item.createdAt).getFullYear()}</h3>
                  </div>
                </div>

                <h1>{item.text}</h1>

                {
                  (user && user?.id && user?.id === item.user_id && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN) || (user && user?.token && user?.token.split("###")[0] === process.env.REACT_APP_ADMIN_TOKEN) ? <TrashtIcon className='delete' onClick={() => deleteRateUser(item.id)}/> : null
                }
              </UserRating>
            ))
          )
        }

      </Ratings>

      <Footer />

    </Container>
  )
}

export default Product
