import React, { useState, useContext, useEffect } from 'react'
import PropTypes from "prop-types";
import { Button, Container, Form } from './styles'
import "./style.css";

import {ReactComponent as Logo } from "../../assets/images/Salmao.svg"
import {ReactComponent as CheckIcon } from "../../assets/icons/check.svg"
import {ReactComponent as CrossIcon } from "../../assets/icons/cross.svg"
import {ReactComponent as ArrowBack } from "../../assets/icons/arrow-left.svg"

import InputField from '../InputField';
import { LoginContext } from '../../contexts/LoginContext';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { validateCoupon, getCodeCuponData } from '../../services/api';
import { toast } from 'react-toastify';

function ModalValidateCupon({ isOpen, onRequestClose }) {

  const { user } = useContext(LoginContext)
  const [info, setInfo] = useState("⠀")
  const [valide, setValide] = useState()
  const [cuponData, setCuponData] = useState()
  const [loading, setLoading] = useState(false)

  const [cuponCode, setCuponCode] = useState()
  const handleCode = (value) => {
    setCuponCode(value)
  }

  const URL = process.env.REACT_APP_URL_AWS_S3

  useEffect(() => {
    setValide("")
  }, [])

  const getCouponData = async () => {
    setInfo("⠀")
    if (!cuponCode) {
      setInfo("Preencha o código")
      return
    }

    if (!user && !user?.id) {
      setInfo("Não foi possível verificar o código")
      toast.error("Não foi possível verificar o código")
      return
    }

    setLoading(true)
    const [code, data] = await getCodeCuponData({code: cuponCode.toUpperCase(), restaurant_id: user.id})

    if(code === 200) {
      setCuponData(data)
      setLoading(false)
      return
    }
    if(code === 400) {
      setInfo("Este cupon pertence a outro restaurante")
      toast.error("Este cupon pertence a outro restaurante")
      setLoading(false)
      return
    }
    if(code === 404) {
      setInfo("Cupon não encontrado")
      toast.error("Cupon não encontrado")
      setLoading(false)
      return
    }

    if(code === 401) {
      setInfo("Este cupon já foi usado")
      toast.error("Este cupon já foi usado")
      setLoading(false)
      return
    }

    if(code === 402) {
      setInfo("Este cupon ainda não foi comprado")
      toast.error("Este cupon ainda não foi comprado")
      setLoading(false)
      return
    }
    setInfo("Erro ao processar cupon")
    toast.error("Erro ao processar cupon")
    setLoading(false)
  }

  const validateCode = async () => {
    setInfo("⠀")
    if (!cuponCode) {
      setInfo("Preencha o código")
      return
    }

    if (!user && !user?.id) {
      setInfo("Não foi possível verificar o código")
      toast.error("Não foi possível verificar o código")
      return
    }

    setLoading(true)
    const status = await validateCoupon({ code: cuponCode.toUpperCase(), restaurant_id: user.id});

    if (status === 200) {
      setInfo("CUPON VALIDADO")
      toast.success("Cupon validado")
      setCuponCode("")
      setValide("valide")
      setLoading(false)
      return
    }

    if (status === 400) {
      setInfo("Error ao validar cupon")
      setLoading(false)
      return
    }

    if (status === 404) {
      setInfo("Este cupon não existe")
      setLoading(false)
      return
    }

    if (status === 401) {
      setInfo("Este cupon pertence a outro restaurante")
      setLoading(false)
      return
    }

    if (status === 403) {
      setInfo("Este cupon já foi usado antes")
      setValide("not-valide")
      setLoading(false)
      return
    }

    setInfo(`${status} ERROR`)
    setLoading(false)
  }

  const back = () => {
    setValide(false)
    setCuponData()
    setInfo("⠀")
    setCuponCode()
  }

  useEffect(() => {
    back()
  }, [isOpen])

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Logo className='logo-modal'/>
      {
        valide ? <ArrowBack onClick={back} className='back' /> : null
      }
      <span>{info}</span>
      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} />
        )
        :
        (
          !valide ?
          (
            !cuponData ?
            (
              <Form onSubmit={(event) => event.preventDefault()}>
                <InputField
                  placeHolder='CÓDIGO CUPON'
                  value={cuponCode}
                  changeValue={handleCode}
                  type='text'
                  id='cupon'
                  required={true}
                />

                <Button style={{marginBottom: "20px"}} type='button' onClick={getCouponData}>
                  Validar Cupon
                </Button>
              </Form>
            )
            :
            (
              <Form onSubmit={(event) => event.preventDefault()}>
                <div className="info">
                  <img src={`${URL}/${cuponData?.picture}`} alt={cuponData?.plate_name} />
                  <div className="text">
                    <h1>{cuponData?.plate_name}</h1>
                    <h3>Código: {cuponData?.code}</h3>
                    <h3>ID: {cuponData?.cupon_id}</h3>
                  </div>
                </div>

                <div className="line">
                  <button type='button' className='cancel' onClick={back}>
                    Cancelar
                  </button>

                  <button type='button' onClick={() => validateCode()}>
                    Confirmar
                  </button>
                </div>
              </Form>
            )
          )
          :
          (
            valide === "valide" ? <CheckIcon className='check-cross' /> : <CrossIcon className='check-cross not' />
          )
        )
      }

    </Container>
  )
}

ModalValidateCupon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalValidateCupon
