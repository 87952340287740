import React, { useContext, useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { Button, Container, Content, Form } from './styles'
import "./style.css";

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { showPublicity, updatePublicity, uploadfiles } from '../../services/api';
import { LoginContext } from '../../contexts/LoginContext';
import { toast } from 'react-toastify';

function ModalEditPublicity({ isOpen, onRequestClose, idPublicity }) {

  const { user } = useContext(LoginContext);

  const [info, setInfo] = useState("⠀")
  const [image, setImage] = useState()
  const [link, setLink] = useState("")
  const [init, setInit] = useState("")
  const [end, setEnd] = useState("")

  const [loading, setLoading] = useState()

  const getDataPublicity = async () => {
    setLoading(true)
    const [code, response] = await showPublicity(idPublicity)

    if (code === 200) {
      setLink(response?.link)
      setInit(`${new Date(response?.init).getFullYear()}-${String(new Date(response?.init).getMonth()+1).padStart(2, "0")}-${new Date(response?.init).getDate()}`)
      setEnd(`${new Date(response?.end).getFullYear()}-${String(new Date(response?.end).getMonth()+1).padStart(2, "0")}-${new Date(response?.end).getDate()}`)
      setLoading(false)
      return
    }

    toast.error("Erro ao editar publicidade")
    setLoading(false)
    onRequestClose()
    return
  }

  useEffect(() => {
    if(idPublicity) {
      getDataPublicity()
    }
  }, [idPublicity])



  const send = async () => {
    if (!link || !init || !end){
      setInfo("Preecha todos os campos")
      return
    }

    if(!user || !user?.token || user?.token !== process.env.REACT_APP_ADMIN_TOKEN || !idPublicity) {
      toast.error("Não foi possível atualizar a publicidade")
      onRequestClose()
      return
    }

    if(image && image.length > 0){
      setInfo("⠀")
      setLoading(true)

      const formData = new FormData();
      formData.append("file", image[0])

      const [code, response] = await uploadfiles(formData);

      if(code === 201) {

        const data = {
          picture: response.path,
          link,
          init: `${init}T03:00:00.000-03:00`,
          end: `${end}T03:00:00.000-03:00`,
        }

        const status = await updatePublicity(data, idPublicity);

        if(status === 200) {
          toast.success("Publicidade atualizada")
          setLink("")
          setLoading(false)
          onRequestClose();
          return
        }

        if(status === 400) {
          setInfo("Erro ao criar cupons")
          toast.error("Erro ao criar cupons")
          setLoading(false)
          return
        }

        toast.error(`${status} ERROR`)
        setInfo(`${status} ERROR`)
        setLoading(false)
        return
      }

      toast.error(`${code} ERROR`)
      setInfo(`${code} ERROR`)
      setLoading(false)
    }

    setInfo("⠀")
    setLoading(true)

    const data = {
      link,
      init: `${init}T03:00:00.000-03:00`,
      end: `${end}T03:00:00.000-03:00`,
    }

    const status = await updatePublicity(data, idPublicity);

    if(status === 200) {
      toast.success("Publicidade atualizada")
      setLink("")
      setLoading(false)
      onRequestClose();
      return
    }

    if(status === 400) {
      setInfo("Erro ao criar cupons")
      toast.error("Erro ao criar cupons")
      setLoading(false)
      return
    }

    toast.error(`${status} ERROR`)
    setInfo(`${status} ERROR`)
    setLoading(false)
    return
  }


  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Atualizar Publicidade</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <h4 style={{marginTop: "28px"}}>Link</h4>
              <input type="text" value={link} name="link" id="link" maxLength={255} onChange={(event) => setLink(event.target.value)} />

              <h4 style={{marginTop: "28px"}}>Data de início</h4>
              <input type="date" value={init} name="init-date" id="init-date"  onChange={(event) => setInit(event.target.value)} />

              <h4 style={{marginTop: "28px"}}>Data final</h4>
              <input type="date" value={end} name="end-date" id="end-date"  onChange={(event) => setEnd(event.target.value)} />

              <Button onClick={send}>
                Atualizar
              </Button>
            </Form>
          </Content>
        )
      }

    </Container>
  )
}

ModalEditPublicity.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  idPublicity: PropTypes.number.isRequired,
};

export default ModalEditPublicity
