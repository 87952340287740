import React from 'react'
import { Container, Items } from './style'

import Carousel from './Carousel'

function Cupons() {
  return (
    <Container>
      <Items>
        <Carousel />
      </Items>
    </Container>
  )
}

export default Cupons
