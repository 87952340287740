import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ModalsContexts = createContext();

export function ModalsProvider({ children }) {

  const [modalLogin, setModalLogin] = useState(false)
  const [modalRegister, setModalRegister] = useState(false)

  const handleOpenModalLogin = () => {
    setModalLogin(true)
    setModalRegister(false)
  }

  const handleCloseModalLogin = () => {
    setModalLogin(false)
  }

  const handleOpenModalRegister = () => {
    setModalRegister(true)
    setModalLogin(false)
  }

  const handleCloseModalRegister = () => {
    setModalRegister(false)
  }

  return(
    <ModalsContexts.Provider
      value={{
        modalLogin,setModalLogin,
        handleOpenModalLogin, handleCloseModalLogin,

        modalRegister, setModalRegister,
        handleOpenModalRegister, handleCloseModalRegister
      }}
    >
      {children}
    </ModalsContexts.Provider>
  )
}

ModalsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
