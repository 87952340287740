import React from 'react'
import { Container, Content, Text, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as Icon } from '../../assets/icons/hat-chef.svg';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';


function BeAPartner() {

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <Icon />
          <h2>Seja Parceiro</h2>
        </Title>

        <Text>
        <span className="title">Por que se tornar um parceiro da Cuponzin?</span>
        <br/><br/>
        A Cuponzin está em busca de estabelecimentos gastronômicos excepcionais para se juntarem à nossa rede de parceiros. Ao se associar a nós, você terá acesso a uma ampla audiência de apreciadores de boa comida, aumentando a visibilidade do seu restaurante e atraindo novos clientes.
        <br/><br/>
        <span className="title-2">Benefícios de ser um parceiro:</span>
        <br/><br/>
        <span>1. Aumente a Visibilidade:<br/></span> Divulgaremos seu restaurante para nossa base de usuários ávidos por experiências gastronômicas, aumentando sua visibilidade local.
        <br/><br/>
        <span>2. Atraia Novos Clientes:<br/></span> Ofereça cupons de desconto exclusivos para atrair novos clientes e fidelizar os existentes, aumentando o tráfego no seu estabelecimento.
        <br/><br/>
        <span>3. Parceria Flexível:<br/></span> Adaptamos nossas parcerias de acordo com suas necessidades, oferecendo opções personalizadas para promover seu negócio.
        <br/><br/><br/>


        <span className="title-2">Como se tornar um parceiro:</span>
        <br/><br/>
        Se você deseja fazer parte da nossa rede de parceiros, preencha o formulário abaixo com suas informações e detalhes sobre seu restaurante. Nossa equipe entrará em contato para discutir as oportunidades de parceria disponíveis.
        <br/>
        <a href="" target='_blank'>Formulário de Cadastro para Parceiros</a>

        <br/><br/>
        Requisitos para ser um parceiro:
        <br/><br/>
        <span>1. Servir pratos de alta qualidade.</span><br/>
        <span>2. Comprometimento com um ótimo atendimento ao cliente.</span><br/>
        <span>3. Localização dentro da área de atuação da Cuponzin.</span><br/><br/>

        Junte-se a nós e faça parte da experiência gastronômica oferecida pela Cuponzin. Estamos ansiosos para colaborar com você e promover os sabores únicos do seu restaurante!
        </Text>

      </Content>
      <Footer />
    </Container>
  )
}

export default BeAPartner
