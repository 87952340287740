import React from 'react'
import PropTypes from "prop-types"
import { Container } from './style'

function Card({ color, text, link}: {color: string, text: string, link: string}) {
  return (
    <Container to={link} style={{backgroundColor: color}}>
      <h2>{text}</h2>
    </Container>
  )
}

Card.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}


export default Card
