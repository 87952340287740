import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { theme } from "../../styles/Theme";

export const Container = styled(NavLink)`
  height: 100%;
  width: 500px;
  max-width: 500px;
  background-color: ${theme.colors.primary};
  border-radius: 16px;
  position: relative;
  display: inline-block;
  flex-direction: column;
  overflow: hidden;
  border: none;

  @media (max-width: 768px) {
    width: 460px !important;
    max-width: 85vw !important;
  }

  &:before, &:after{
    content: '';
    position: absolute;
    position: absolute;
    height: 48px;
    width: 48px;
    bottom: 60px;
    border-radius: 50%;
    background-color: ${theme.colors.background};
    z-index: 99;

    @media (max-width: 425px) {
      height: 32px;
      width: 32px;
      bottom: 40px;
    }

    @media (max-width: 500px) {
      height: 32px;
      width: 32px;
      bottom: 40px;
    }
  }

  &::before{
    left: -32px;
    @media (max-width: 500px) {
      left: -18px;
    }
    @media (max-width: 425px) {
      left: -18px;
    }
  }

  &::after{
    left: none;
    right: -32px;

    @media (max-width: 500px) {
      right: -18px;
    }

    @media (max-width: 425px) {
      right: -18px;
    }
  }

  h2{
    margin: 0;
    font-size: 24px;
    color: ${theme.colors.white};
    margin: 0;
    margin-bottom: 80px;
    font-family: 'NeueMachinaLight';
    font-weight: 100;
    position: absolute;
    left: 32px;
    top: 32px;
    width: 50%;

    @media (max-width: 425px) {
      font-size: 20px;
      width: 80%;
      left: 18px;
      top: 18px;
    }
  }
`;
