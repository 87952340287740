import React, { useContext, useState } from 'react'

import { LoginContext } from '../../../contexts/LoginContext'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

import { toast } from 'react-toastify';
import { login, updateUser } from '../../../services/api';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from "../../../styles/Theme"
import { Button, Container, Field } from './styles';

function Security() {

  const { user } = useContext(LoginContext)

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  const [load, setLoad] = useState(false)

  const update = async (event) => {
    event.preventDefault()
    if(!user || !user?.id || !user?.token || !user?.email || user.token !== process.env.REACT_APP_ADMIN_TOKEN) {
      toast.error("Erro ao atualizar senha")
      return
    }

    if(!password || !newPassword || !confirmNewPassword){
      toast.warn("Preencha todos os campos")
      return
    }

    if(newPassword !== confirmNewPassword) {
      toast.warn("As senhas novas não combinam")
      return
    }

    if(newPassword.includes(" ")) {
      toast.warn("A senha não pode conter espaços")
      return
    }

    if(newPassword.length < 8) {
      toast.warn("Mínimo de 8 caracters")
      return
    }

    setLoad(true)
    const status = await login({ email: user.email, password})
    if(status[0] === 200) {
      const code = await updateUser({ password: newPassword }, user.id)

      if(code === 200) {
        toast.success("Senha atualizada")
        setLoad(false)
        return
      }

      setLoad(false)
      toast.error("Erro ao atualizar senha")
      return
    }

    setLoad(false)
    toast.error("Senha atual errada")
    return
  }

  return (
    <Container onSubmit={update}>
      {
        load ? <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} /> :
        (
          <>
            <Field>
              <h3>Senha</h3>
              <div className="field">
                <input type={showPassword ? "text" : "password"} name='password' id='password' onChange={(event) => setPassword(event.target.value)} placeholder='Senha' />
                {
                  showPassword ?
                  <AiOutlineEyeInvisible onClick={() => setShowPassword(false)} /> :
                  <AiOutlineEye onClick={() => setShowPassword(true)} />
                }
              </div>
            </Field>

            <Field>
              <h3>Nova senha</h3>
              <div className="field">
                <input type={showNewPassword ? "text" : "password"} name='new-password' id='new-password' onChange={(event) => setNewPassword(event.target.value)}  placeholder='Nova senha' />
                {
                  showNewPassword ?
                  <AiOutlineEyeInvisible onClick={() => setShowNewPassword(false)} /> :
                  <AiOutlineEye onClick={() => setShowNewPassword(true)} />
                }
              </div>
            </Field>

            <Field>
              <h3>Confirmar nova senha</h3>
              <div className="field">
                <input type={showConfirmNewPassword ? "text" : "password"} name='confirm-new-password' id='confirm-new-password' onChange={(event) => setConfirmNewPassword(event.target.value)}  placeholder='Confirmar nova senha' />
                {
                  showConfirmNewPassword ?
                  <AiOutlineEyeInvisible onClick={() => setShowConfirmNewPassword(false)} /> :
                  <AiOutlineEye onClick={() => setShowConfirmNewPassword(true)} />
                }
              </div>
            </Field>
            <Button type='submit'>Atualizar</Button>
          </>
        )
      }
    </Container>
  )
}

export default Security
