import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 256px;
  background-color: ${theme.colors.white};
  position: relative;
  overflow: hidden;

  &:before, &:after{
    content: '';
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    bottom: 56px;
    background-color: ${theme.colors.background};
  }

  &:before{
    left: -12px;
  }

  &::after{
    right: -12px;
  }

  svg.like{
    height: 16px;
    width: 16px;
    padding: 4px;
    border-radius: 50%;
    background-color: ${theme.colors.white};
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 99;

    path{
      fill: ${theme.colors.gray400};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;
  transition:  all .4s;
  cursor: pointer;

  div.img{
    width: 100%;
    height: 128px;
    background-color: ${theme.colors.gray100};
  }


  div.title{
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.gray200};
    position: relative;

    div.span{
      width: 92px;
      height: 20px;
      border-radius: 4px;
      background-color: ${theme.colors.gray100};
      margin-left: 24px;
    }

    div.discount{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: ${theme.colors.primary};
      position: absolute;
      top: -24px;
      right: 24px;
      font-size: 14px;
      color: ${theme.colors.white};
      font-family: "NeueMachinaLight";
      font-weight: 200;
    }
  }

  div.priceInfo{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;

    div.info{
      width: 50%;
      min-width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      div.span-2{
        width: 64px;
        height: 16px;
        border-radius: 4px;
        background-color: ${theme.colors.gray100};
        margin-bottom: 2px;
      }

      div.h3{
        width: 80%;
        height: 24px;
        border-radius: 4px;
        background-color: ${theme.colors.gray100};
      }

      &.border{
        border-right: 1px solid ${theme.colors.gray200};
        height: 100%;
      }
    }
  }
`;

export const AddToCart = styled.div`
  height: 68px;
  min-height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${theme.colors.lightGreen};
  cursor: pointer;

  span{
    font-size: 16px;
    color: ${theme.colors.white};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin: 0;
  }

  svg{
    height: 20px;
    width: 20px;

    path, circle{ fill: ${theme.colors.white}}
  }
`;
