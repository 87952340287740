import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const Container = styled.div`
  width: 95%;
  min-height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${theme.colors.text};
  line-height: 2.8rem;
  margin: 0;
  margin-bottom: 80px;
  margin-top: 24px;
  font-family: 'NeueMachinaLight';
  font-weight: 100;

  span{
    color: ${theme.colors.primary};
  }

  @media (max-width: 678px) {
    font-size: 24px;
    margin-bottom: 64px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    margin-top: 24px;
    line-height: 2.4rem;
  }

  @media (max-width: 420px) {
    font-size: 18px;
    margin-top: 24px;
    line-height: 2.4rem;
  }
`;

export const Gastronomy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 240px;
  margin-bottom: 40px;
  position: relative;
`;

export const Card = styled.div`
  height: 100%;
  width: 440px;
  background-color: ${theme.colors.primary};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &:before, &:after{
    content: '';
    position: absolute;
    position: absolute;
    height: 48px;
    width: 48px;
    bottom: 60px;
    border-radius: 50%;
    background-color: ${theme.colors.background};
  }

  &::before{
    left: none;
    left: -32px;
  }

  &::after{
    left: none;
    right: -32px;
  }

  svg{
    height: 32px;
    width: 32px;
    position: absolute;
    top: 32px;
    left: 32px;

    path{
      fill: ${theme.colors.white};
    }

    @media (max-width: 767px) {
      top: 24px;
      left: 24px;
    }
  }

  h2{
    margin: 0;
    font-size: 24px;
    color: ${theme.colors.white};
    margin: 0;
    margin-bottom: 80px;
    font-family: 'NeueMachinaLight';
    font-weight: 100;
    position: absolute;
    left: 32px;
    bottom: -56px;

    @media (max-width: 767px) {
      bottom: -72px;
      left: 24px;
    }
  }
`;
