import styled from "styled-components";
import { theme } from "../../styles/Theme";
import { NavLink } from "react-router-dom";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  height: auto;
  flex-wrap: wrap;
  min-height: 64px;
  margin-top: 24px;

  a{
    text-decoration: none;
    svg.logo{
      height: 28px;

      path, rect, polygon {
        fill: ${theme.colors.text};
      }
    }
  }

  @media (max-width: 768px) {
    a{
      width: 100%;
      svg.logo{
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 500px){
    a{
      width: fit-content;
      order: 1;
      svg.logo{
        width: 100%;
        height: 22px;
        margin-bottom: 16px;
      }
    }
  }

`;

export const Search = styled.div`
  width: 70%;
  max-width: 400px;
  height: 30px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 6px #0000000e;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 100%;
    max-width: none;
    order: 3;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  color: ${theme.colors.text};
  height: 100%;
  width: 100%;
  margin-right: 8px;
  border-radius: 20px;
  border: 14px;
  padding-left: 12px;
  background-color: transparent;
  outline: none;

  &:focus, &:active{
    outline: none;
    background: transparent;
  }

  &::placeholder{
    color: ${theme.colors.gray200};
  }
`;

export const ButtonSearch = styled.div`
  height: 100%;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: ${theme.colors.text};
  cursor: pointer;
  transition: all .4s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    height: 16px;
    width: 16px;
    transition: all .4s;

    path{
      fill: ${theme.colors.background};
    }
  }

  &:hover svg{
    opacity: 0.8;
  }
`;

export const CartAndLogin = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: 500px) {
    order: 2;
    margin-top: -20px;
  }
`;

export const CartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  transition: all .4s;
  background-color: ${theme.colors.gray200};
  position: relative;

  svg{
    height: 14px;
    width: 14px;

    path, circle {
      fill: ${theme.colors.gray400};
    }
  }

  div.count{
    position: absolute;
    font-size: 8px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.white};
    padding: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.lightBlue};
    border-radius: 16px;
    top: -4px;
    right: -4px;
    height: 12px;
    width: 12px;
    margin: 0;

    span{
      margin-left: -2px;
      margin-bottom: -2px;
    }

  }

  div.cart-items{
    position: absolute;
    transition: all .4s;
    width: 300px;
    top: calc(100% + 8px);
    left: calc(100% - 166px);
    pad: 4px;
    border-radius: 8px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    max-height: 80vh;
    overflow: hidden;
    z-index: 9998;

    div.list{
      height: auto;
      max-height: calc(80vh - 56px);
      overflow-y: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.gray100};
      }

      a.item-cart-list{
        width: 100%;
        height: 92px;
        min-height: 92px;
        border-bottom: 1px solid ${theme.colors.gray100};
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: ${theme.colors.text};

        img{
          height: 72px;
          width: 72px;
          margin-left: 6px;
          object-fit: cover;
          object-position: center;
        }

        div.text{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 72px;

          h4, h5{
            text-align: right;
            margin: 0;
            color: ${theme.colors.text};
          }

          div.price-and-qntd{
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    a.button{
      text-decoration: none;
      width: 100%;
      height: 48px;
      min-height: 48px;
      border-radius: 4px;
      cursor: pointer;
      background-color: ${theme.colors.lightGreen};
      color: ${theme.colors.white};
      font-size: 16px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const LoginButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  border-radius: 18px;
  padding: 3px;
  cursor: pointer;
  transition: all .4s;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 6px #0000000e;

  span{
    font-size: 14px;
    padding-left: 16px;
    padding-right: 12px;
    color: ${theme.colors.text};
    margin: 0;
  }


  div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: ${theme.colors.gray200};

    svg{
      height: 14px;
      width: 14px;

      path {
        fill: ${theme.colors.gray400}
      }
    }
  }
`;

export const UserButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  border-radius: 18px;
  padding: 3px;
  cursor: pointer;
  transition: all .4s;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 6px #0000000e;
  position: relative;

  span.name{
    font-size: 14px;
    padding-left: 16px;
    padding-right: 12px;
    color: ${theme.colors.text};
    margin: 0;
  }


  div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: ${theme.colors.gray200};

    svg{
      height: 14px;
      width: 14px;

      path {
        fill: ${theme.colors.gray400}
      }
    }
  }

  div.options{
    width: 172px;
    height: auto;
    border-radius: 8px;
    background-color: ${theme.colors.white};
    position: absolute;
    top: calc(100% + 8px);
    left: calc(50% - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    cursor: default;
    overflow: hidden;
    transition: all .4s;
    z-index: 99;

    span.op{
      cursor: pointer;
      font-size: 14px;
      color: ${theme.colors.text};
      margin: 0;
      width: 100%;
      height: 32px;
      min-height: 32px;
      text-align: center;
      transition: all .4s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover{
        background-color: ${theme.colors.gray100};
      }
    }
  }
`;

export const LinkOp = styled(NavLink)`
  cursor: pointer;
  font-size: 14px;
  color: ${theme.colors.text};
  margin: 0;
  width: 100%;
  height: 32px;
  min-height: 32px;
  text-align: center;
  transition: all .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover{
    background-color: ${theme.colors.gray100};
  }
`;
