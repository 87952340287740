import React from 'react'
import PropTypes from "prop-types"
import { Container, Items, Title } from './style'

import { ReactComponent as HatIcon } from '../../assets/icons/hat-chef.svg'
import Carousel from './Carousel'

function Highlights({ text, filter }: { text: string, filter?: string }) {
  return (
    <Container>
      <Title>
        <HatIcon />
        <h2>{text}</h2>
      </Title>
      <Items>
        <Carousel filter={filter} />
      </Items>
    </Container>
  )
}

Highlights.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Highlights
