import React, { useEffect, useState, useContext } from 'react'
import PropTypes from "prop-types";
import { Add, Button, Container, Content } from './styles'
import "./style.css";

import {ReactComponent as Logo } from "../../assets/images/Salmao.svg"
import {ReactComponent as MinusIcon} from "../../assets/icons/minus.svg"
import {ReactComponent as PlusIcon} from "../../assets/icons/plus.svg"


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { getEspecifyCoupon, updateeCupons } from '../../services/api';
import { toast } from 'react-toastify';
import { LoginContext } from '../../contexts/LoginContext';

function ModalAddMore({ isOpen, onRequestClose, cuponId }) {

  const { user } = useContext(LoginContext)

  const [info, setInfo] = useState("⠀")
  const [loading, setLoading] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [data, setData] = useState()

  const URL = process.env.REACT_APP_URL_AWS_S3

  useEffect(() => {
    async function getCupon() {
      setLoading(true)
      const [code, response] = await getEspecifyCoupon(cuponId);

      if(code === 200) {
        setData(response)
        setLoading(false)
        return
      }
      setInfo("Error ao obter cupon")
      toast.error("Error ao obter cupon")
      setLoading(false)
    }
    if(cuponId) {
      getCupon();
    }
  }, [cuponId])

  const minusQuantity = () => {
    if(quantity <= 1){
      return
    }
    const n = Number(quantity) - 1
    setQuantity(n)
  }
  const plusQuantity = () => {
    const n = Number(quantity) + 1
    setQuantity(n)
  }

  const update = async () => {
    if(!cuponId || !data || !data?.id || !user || !user?.id || !user?.token || user?.token !== process.env.REACT_APP_RESTAURANT_TOKEN) {
      toast.error("Não foi possível adicionar os cupons")
      return
    }

    if (!quantity || quantity <= 0) {
      toast.warn("Error: Coloque uma quantidade mínima")
      return
    }

    if (quantity > 500 - data?.quantity) {
      toast.warn(`Quantidade máxima: ${500 - data?.quantity}`)
      return
    }
    setLoading(true)
    setInfo("⠀")

    const dataResponse = {
      restaurant_id: user.id,
      quantity,
    }

    const code = await updateeCupons(dataResponse, cuponId)

    if (code === 200) {
      toast.success("Cupons adicionados")
      setLoading(false)
      onRequestClose()
      return
    }

    toast.error("Erro ao adicionar cupons")
    setLoading(false)
  }



  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Logo className='logo-modal'/>
      <span>{info}</span>

      {
        loading ? <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} /> :
        (
          <Content>
            <div className="info-coupon">
              <img src={`${URL}/${data?.picture}`} alt={data?.plate_name} />
              <div className="text">
                <h2>{data?.plate_name}</h2>
                <h3>ID: {data?.id}</h3>
                <h3>Quantidade: {data?.quantity}</h3>
              </div>
            </div>

            <Add>
              <MinusIcon
                className={quantity <= 1 ? "block" : ""}
                onClick={quantity <= 1 ? null : minusQuantity}
              />

              <input type='number' min={1} max={data?.quantity ? 200 - data?.quantity : 200} value={quantity} onChange={(event) => setQuantity(event.target.value)} />

              <PlusIcon
                className={data?.quantity ? quantity >= 500 - data?.quantity ? "block" : "" : quantity > 500 ? "block" : ""}
                onClick={data?.quantity ? quantity >= 500 - data?.quantity ? null : plusQuantity : quantity > 500 ? null : plusQuantity}
              />
            </Add>

            <Button onClick={update}>Adicionar</Button>
          </Content>
        )
      }

    </Container>
  )
}
ModalAddMore.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  cuponId: PropTypes.number.isRequired,
};

export default ModalAddMore
