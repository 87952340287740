import React, { useState, useEffect } from 'react'
import { Container, List, PageNavigations, Results, TextContent } from './style';
import Header from '../../components/Header';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';

import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import ItemLayoutLoad from '../../components/ItemLayoutLoad';
import { getCupons } from '../../services/api';
import { toast } from 'react-toastify';
import ItemLayout from '../../components/ItemLayout';

type cuponType = {
  id: number,
  picture: string
  price_of: number
  price_purchase: number
  plate_name: string
}

function MoreSales() {

  const [page, setPage] = useState<number>(1);
  const [load, setLoad] = useState<boolean>(true);
  const [cupons, setCupons] = useState<cuponType[]>([]);

  const getCuponsSearch = async () => {
    setLoad(true)
    const search = `&moreSales=true`

    const result = await getCupons(search)

    if (Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as cuponType[];

      if(code === 200){
        setCupons(response)
        setLoad(false)
        return
      }
      toast.error("Error ao procurar cupons")
      setLoad(false)
    }
  }

  useEffect(() => {
    getCuponsSearch()
  }, [page])

  return (
    <Container>
      <CursorMask />
      <Header />
      <Results>
        <TextContent>
          <h1>Exibindo resultados para: <span>Os cupons mais vendidos</span></h1>
          <h2>{load ? "⠀" : `${cupons.length} Cupons encontrados`}</h2>
        </TextContent>

        <List>
          {
            load ?
            (
              <>
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
                <ItemLayoutLoad />
              </>
            ) :
            cupons.map((item) => (
              <ItemLayout
                key={item.id}
                id={item.id}
                picture={item.picture}
                name={item.plate_name}
                priceOf={item.price_of}
                pricePurchase={item.price_purchase}
              />
            ))
          }
        </List>

        <PageNavigations>
          <h3>Página</h3>
          <div className="navigation">
            <ArrowLeftIcon
              className={page <= 1 ? "block" : ""}
              onClick={() => page <= 1 ? null : setPage(page - 1)}
            />
            <span>{String(page).padStart(2, "0")}</span>
            <ArrowRightIcon
              className={cupons.length < 20 ? "block" : ""}
              onClick={() => cupons.length < 20 ? null : setPage(page + 1)}
            />
          </div>
        </PageNavigations>
      </Results>
      <Footer />
    </Container>
  )
}

export default MoreSales
