import React from 'react';
import { Container, Input, Title } from './styles';

type ChangeValueFunction = (newValue: string) => void;

function InputMaskField({ placeHolder, value, changeValue, type, id, required, width, mask, disabled }: { placeHolder?: string, value?: string, changeValue?: ChangeValueFunction, type: string, id: string, required: boolean, width?: number, mask: string, disabled?: boolean }) {


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (changeValue) {
      changeValue(event.target.value);
    }
  };

  return (
    <Container style={width ? { width: `${width}%`} : {}}>
      <Input
        type={type}
        value={value}
        onChange={handleInputChange}
        id={id}
        name={id}
        required={required}
        mask={mask}
        disabled={disabled}
      />

      <Title htmlFor={id} style={value ? { top: '0', left: '0', fontSize: "10px" } : {}}>
        {placeHolder}
      </Title>
    </Container>
  );
}

export default InputMaskField;
