import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  width: 95%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 88px;
  gap: 24px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;

  svg{
    height: 32px;
    width: 32px;

    path{
      fill: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 32px;
    color: ${theme.colors.gray400};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const Text = styled.h2`
  font-size: 20px;
  color: ${theme.colors.gray400};
  margin: 0;
  font-family: "NeueMachinaLight";
  font-weight: 200;

  span{
    color: ${theme.colors.text};
  }
`;
