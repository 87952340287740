import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { Button, Container, Form } from './styles'
import "./style.css";

import {ReactComponent as Logo } from "../../assets/images/Salmao.svg"
import InputField from '../InputField';
import InputMaskField from '../InputMaskField';
import { getRestaurant, updateRestaurant } from '../../services/api';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { toast } from 'react-toastify';

function ModalEditRestaurant({ isOpen, onRequestClose, restaurantId }) {

  const [info, setInfo] = useState("⠀")
  const [loading, setLoading] = useState(false)

  async function getRestaurantData() {
    setLoading(true)
    const [code, response] = await getRestaurant(restaurantId)
    console.log(response)

    if(code === 200) {
      setName(response?.name)
      setEmail(response?.email)
      setTel(response?.tel)
      setPublicKey(response?.public_key_mp)
      setAccessToken(response?.access_token_mp)
      setStreet(response?.street)
      setNeighborhood(response?.neighborhood)
      setPostalCode(response?.postal_code)
      setNumber(response?.number)
      setLoading(false)
      return
    }

    toast.error("Error ao obter dados do restaurante")
    setLoading(false)
  }

  useEffect(() => {
    if(restaurantId) {
      getRestaurantData()
    }
  }, [isOpen, restaurantId])

  const [name, setName] = useState()
  const handleName = (value) => {
    setName(value)
  }

  const [tel, setTel] = useState()
  const handleTel = (value) => {
    setTel(value)
  }

  const [email, setEmail] = useState()
  const handleEmail = (value) => {
    setEmail(value)
  }

  const [publicKey, setPublicKey] = useState()
  const handlePublicKey = (value) => {
    setPublicKey(value)
  }

  const [accessToken, setAccessToken] = useState()
  const handleAccessToken = (value) => {
    setAccessToken(value)
  }

  const [street, setStreet] = useState()
  const handleStreet = (value) => {
    setStreet(value)
  }

  const [neighborhood, setNeighborhood] = useState()
  const handleNeighborhood = (value) => {
    setNeighborhood(value)
  }

  const [number, setNumber] = useState()
  const handleNumber = (value) => {
    setNumber(value)
  }

  const [postalCode, setPostalCode] = useState()
  const handlePostalCode = (value) => {
    setPostalCode(value)
  }


  const create = async (event) => {
    event.preventDefault()
    setInfo("⠀")
    if(!restaurantId || !accessToken || !publicKey ) {
      setInfo("Por favor! Preencha todos os campos")
      return
    }

    setLoading(true)
    const data = {
      public_key_mp: publicKey,
      access_token_mp: accessToken,
    }

    const code = await updateRestaurant(data, restaurantId)
    if(code === 200) {
      toast.success("Credenciais atualizadas")
      setLoading(false)
      onRequestClose()
      return
    }

    toast.error("Error ao atualizar credenciais")
    setLoading(false)
    
  }


  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Logo className='logo-modal'/>
      <span>{info}</span>
      {
        !loading ?
        (
          <Form onSubmit={create}>

          <div className="line">
            <InputField
              placeHolder='NOME RESTAURANTE'
              value={name}
              changeValue={handleName}
              type='text'
              id='restaurant-name'
              required={true}
              width={48}
              disabled={true}
            />

            <InputMaskField
              placeHolder='TELEFONE'
              value={tel}
              changeValue={handleTel}
              type='tel'
              id='tel'
              required={true}
              width={48}
              mask="(99) 9 9999-9999"
              disabled={true}
            />
          </div>

          <InputField
            placeHolder='E-MAIL'
            value={email}
            changeValue={handleEmail}
            type='email'
            id='email'
            required={true}
            disabled={true}
          />

          <InputField
            placeHolder='PUBLIC KEY MERCADO PAGO'
            value={publicKey}
            changeValue={handlePublicKey}
            type='text'
            id='public_key_mp'
            required={true}
          />

          <InputField
            placeHolder='ACCESS TOKEN MERCADO PAGO'
            value={accessToken}
            changeValue={handleAccessToken}
            type='text'
            id='access_toke_mp'
            required={true}
          />

          <InputField
            placeHolder='RUA'
            value={street}
            changeValue={handleStreet}
            type='text'
            id='street'
            required={true}
            disabled={true}
          />

          <InputField
            placeHolder='BAIRRO'
            value={neighborhood}
            changeValue={handleNeighborhood}
            type='text'
            id='neighborhood'
            required={true}
            disabled={true}
          />


          <div className="line">
            <InputField
              placeHolder='NÚMERO'
              value={number}
              changeValue={handleNumber}
              type='number'
              id='number'
              width={48}
              required={true}
              disabled={true}
            />

            <InputMaskField
              placeHolder='CEP'
              value={postalCode}
              changeValue={handlePostalCode}
              type='text'
              id='postal-code'
              mask='99999-999'
              width={48}
              required={true}
              disabled={true}
            />

          </div>

          


          <Button
            type='submit' style={{marginTop: '20px', marginBottom: "24px"}}
          >
            Editar Restaurante
          </Button>

          </Form>
        )
        :
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} />
        )
      }

    </Container>
  )
}

ModalEditRestaurant.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  restaurantId: PropTypes.number.isRequired,
};

export default ModalEditRestaurant
