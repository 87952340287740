import React from 'react'
import { Container, Content, Text, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as Icon } from '../../assets/icons/hat-chef.svg';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';


function About() {

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <Icon />
          <h2>Sobre</h2>
        </Title>

        <Text>
          Bem-vindo à Cuponzin, onde a paixão pela gastronomia se encontra com a busca incessante por economia. Somos uma equipe dedicada e apaixonada por conectar pessoas aos melhores sabores das cozinhas locais, tornando cada refeição uma experiência acessível e memorável. <br/><br/>
          Nossa jornada começou com um propósito simples, porém poderoso: democratizar o acesso a experiências gastronômicas incríveis. Acreditamos que todos merecem saborear pratos excepcionais sem comprometer o orçamento, e é aí que entram nossos cupons de desconto.<br/><br/>

          Trabalhamos lado a lado com uma extensa rede de restaurantes parceiros, cuidadosamente selecionados, para oferecer a você uma ampla gama de opções culinárias com preços mais acessíveis. Desde pequenos estabelecimentos familiares até renomados restaurantes gourmet, temos ofertas para todos os gostos e ocasiões.<br/><br/>

          Nosso compromisso não se resume apenas a oferecer descontos. Buscamos criar uma comunidade de apreciadores da boa comida, compartilhando dicas, histórias e recomendações sobre os melhores pratos e experiências gastronômicas.<br/><br/>

          A transparência e a satisfação dos nossos clientes são fundamentais para nós. Por isso, trabalhamos incessantemente para garantir que sua experiência ao usar nossos cupons seja tão deliciosa quanto a refeição que você vai saborear. Afinal, queremos ser mais do que uma plataforma de descontos, queremos ser seu guia confiável no mundo da culinária local.<br/><br/>

          Junte-se a nós nessa jornada de descoberta gastronômica e economia inteligente. Explore, saboreie e economize enquanto desfruta do melhor que a gastronomia tem a oferecer.<br/><br/>

          Seja bem-vindo à Cuponzin, onde o prazer de comer bem encontra o prazer de economizar.<br/><br/>

          Bom apetite!
        </Text>


      </Content>
      <Footer />
    </Container>
  )
}

export default About
