import React, {  useState } from 'react';
import { Button, Container, Input, Title } from './styles';

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

type ChangeValueFunction = (newValue: string) => void;

function PasswordField({ placeHolder, value, changeValue, id, required, width, wrong }: { placeHolder?: string, value?: string, changeValue?: ChangeValueFunction, type: string, id: string, required: boolean, width?: number, wrong?: boolean }) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (changeValue) {
      changeValue(event.target.value);
    }
  };

  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <Container className={ wrong ? "wrong" : ""} style={width ? { width: `${width}%`} : {}}>
      <Input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={handleInputChange}
        id={id}
        name={id}
        required={required}
      />

      <Button onClick={() => setShowPassword(!showPassword)}>
        {
          showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
        }
      </Button>

      <Title htmlFor={id} style={value ? { top: '0', left: '0', fontSize: "10px" } : {}}>
        {placeHolder}
      </Title>
    </Container>
  );
}

export default PasswordField;
