import React from 'react'
import { Container, Content, Text, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as Icon } from '../../assets/icons/hat-chef.svg';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';


function Contact() {

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <Icon />
          <h2>Fale Conosco</h2>
        </Title>

        <Text>
        Se você tiver perguntas, comentários ou precisar de suporte, estamos aqui para ajudar! Entre em contato conosco de uma das seguintes maneiras:
        <br/><br/>
        <span>Formulário de Contato:<br/></span>
        Preencha o formulário abaixo com suas perguntas ou solicitações e entraremos em contato o mais breve possível.
        <br/>
        <a href="" target='_blank'>Formulário de Contato</a>

        <br/><br/>
        <span>E-mail:<br/></span>
        Envie-nos um e-mail para <a href="mailto:contato@cuponzin.com">contato@cuponzin.com</a>. Responderemos dentro de 1-2 dias úteis.

        <br/><br/>
        <span>Central de Suporte:<br/></span>
        Visite nossa seção de suporte na plataforma para encontrar respostas para perguntas frequentes ou para enviar uma solicitação diretamente.

        <br/><br/>
        <span>Redes Sociais:<br/></span>
        Siga-nos nas redes sociais para atualizações, ofertas exclusivas e para entrar em contato conosco através de mensagens privadas.
        <br/><br/>
        <a href="" target='_blank'>Facebook</a><br/>
        <a href="" target='_blank'>Instagram</a><br/>
        <a href="" target='_blank'>Linkedin</a><br/>
        <br/><br/>

        <span>Telefone:<br/></span>
        Para suporte imediato, entre em contato conosco pelo telefone <a href="tel:+5548998765432">(48) 9 9876-5432</a>. Nosso horário de atendimento é de segunda a sexta-feira, das 9h às 18h.
        <br/><br/>

        Estamos comprometidos em fornecer um excelente serviço e ficaremos felizes em ajudar com qualquer dúvida ou preocupação que você possa ter. Obrigado por escolher Cuponzin!
        </Text>

       

      </Content>
      <Footer />
    </Container>
  )
}

export default Contact
