import React from 'react'
import { Container, Content, Text, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as Icon } from '../../assets/icons/hat-chef.svg';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';


function FAQ() {

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <Icon />
          <h2>Perguntas frequentes</h2>
        </Title>

        <Text>
        <span>1. O que são os cupons de desconto oferecidos pela plataforma?<br/></span>
        Nossos cupons são ofertas promocionais disponibilizadas por restaurantes parceiros, permitindo descontos em pratos selecionados.
        <br/><br/>
        <span>2. Como posso utilizar os cupons?<br/></span>
        Basta escolher o cupom desejado na plataforma, realizar a compra e apresentá-lo no restaurante parceiro durante o pagamento e aproveitar o desconto.
        <br/><br/>
        <span>3. Existe alguma restrição nos cupons de desconto?<br/></span>
        Não. Os cupons podem ser usados a qualquer momento no restaurante durante o seu horário de funcionamento.
        <br/><br/>
        <span>4. É necessário criar uma conta para acessar os cupons?<br/></span>
        Sim, para realizar a compra de cupons é necessário criar uma conta na Cuponzin. É rápido, fácil e gratuito.
        <br/><br/>
        <span>5. Os cupons têm algum custo?<br/></span>
        Não, os cupons vendidos são de restaurantes parceiros da Cuponzin e não há nenhum custo adicional.
        <br/><br/>
        <span>6. Como são selecionados os restaurantes parceiros?<br/></span>
        Trabalhamos com uma variedade de restaurantes, desde locais familiares até estabelecimentos renomados, selecionados cuidadosamente para oferecer uma ampla gama de opções aos usuários.
        <br/><br/>
        <span>7. Os cupons têm validade?<br/></span>
        Não, os cupons não possui validades, e pode ser usado uma única vez no restaurante. 
        <br/><br/>
        <span>8. Posso utilizar mais de um cupom por visita ao restaurante?<br/></span>
        Em geral, cada cupon pode ser utilizado apenas um vez no restaurante, mas você pode comprar mais de um cupon para o mesmo prato.
        <br/><br/>
        <span>9. Onde posso acessar os cupons que eu comprei?<br/></span>
        No canto superior direito na sua tela de usuário, você pode acessar o menu e verificar seus cupons.
        <br/><br/>
        <span>10. Como posso entrar em contato se tiver outras dúvidas ou problemas?<br/></span>
        Se precisar de suporte adicional ou tiver outras dúvidas, entre em contato conosco através da seção de atendimento na plataforma.
        </Text>

      
      </Content>
      <Footer />
    </Container>
  )
}

export default FAQ
