import React, { useEffect, useState } from 'react'
import { AddRestaurant, Container, Input, List, LoadMore, Restaurant, Title } from './styles'
import ModalRegisterRestaurant from '../../../components/ModalRegisterRestaurant'

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';
import { getAllRestaurants } from '../../../services/api';
import { CiLocationOn } from "react-icons/ci"
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import ModalEditRestaurant from '../../../components/ModalEditRestaurant';


type restaurantItem = {
  id: number
  name: string
  email: string
  tel: string
  street: string
  number: number
  quantity_cupons: number
  neighborhood: string
  postal_code: string
}

function Restaurants() {

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [restaurantId, setRestaurantId] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [restaurants, setrestaurants] = useState<restaurantItem[]>([])
  const [searchRestaurant, setSearchRestaurant] = useState<string>("")

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleCloseEdit = () => {
    setOpenModalEdit(false)
  }

  async function getRestaurants() {
    setLoading(true)
    let search = ""
    search += `&page=${page}&search=${searchRestaurant}`

    const response = await getAllRestaurants(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as restaurantItem[];

      if (code === 200) {
        setrestaurants([...restaurants, ...data]);
        setLoading(false);
        return;
      }
    } else {
      toast.error("Error ao obter restaurantes")
    }
  }


  async function getRestaurantsSearch() {
    setLoading(true)
    let search = ""
    search += `&page=1&search=${searchRestaurant}`

    const response = await getAllRestaurants(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as restaurantItem[];

      if (code === 200) {
        setrestaurants(data);
        setLoading(false);
        return;
      }
    } else {
      console.error("ERROR TO GET COUPONS")
    }
  }

  useEffect(() => {
    getRestaurants()
  }, [page])

  useEffect(() => {
    getRestaurantsSearch()
  }, [searchRestaurant])
  
  const editRestaurant = (id: number) => {
    if(!id){
      return
    }
    setRestaurantId(id)
    setOpenModalEdit(true)
  }

  return (
    <Container>
      <ModalRegisterRestaurant isOpen={openModal} onRequestClose={handleClose} />
      <ModalEditRestaurant isOpen={openModalEdit} onRequestClose={handleCloseEdit} restaurantId={restaurantId} />
      <AddRestaurant onClick={handleOpen}>
        Adicionar novo Restaurante
      </AddRestaurant>

      <Title>Restaurantes</Title>

      <Input>
        <input type='text' id='search-restaurant' name='search-restaurant' placeholder='Procurar restaurante' onChange={(event) => setSearchRestaurant(event.target.value)} />
      </Input>

      <List>
      {
        restaurants.map((item) => (
          <Restaurant key={item.id}>
            <h3>{item.id}</h3>
            <div className="text">
              <h1>{item.name}</h1>
              <h2>{item.email}</h2>
              <h2>{item.tel}</h2>
              <h2>Quantidade de Cupons: {item.quantity_cupons}</h2>
              <div className="location">
                <CiLocationOn />
                <h2>Rua {item.street.replace("Rua", "").replace("rua", "").replace("RUA", "")} - {item.number}, {item.neighborhood} - {item.postal_code}</h2>
              </div>
            </div>

            <EditIcon onClick={() => editRestaurant(item.id)} className='edit' />
          </Restaurant>
        ))
      }

      {
          loading ?
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
          )
          :
          (
            <LoadMore onClick={() => setPage(page + 1)}>
              Carregar mais
            </LoadMore>
          )
        }
      </List>


    </Container>
  )
}

export default Restaurants
