import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  max-height: 312px;
  height: 40vh;
  min-height: 240px;
  overflow: hidden;
  border-radius: 12px;
  padding-top: 32px;
  padding-bottom: 32px;
  position: relative;

  .content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;
  }

  .slider{
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;


    a{
      text-align: none;
      width: 100%;
      height: 100%;

      img{
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    img.img{
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  h2{
    font-size: 24px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.white};
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 99;

    @media (max-width: 767px) {
      left: 32px;
      top: 32px;
      bottom: none;
      font-size: 22px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }

    @media (max-width: 420px) {
      left: 20px;
      top: 32px;
      bottom: none;
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    height: 240px;
    min-height: 240px;
    max-height: 240px;
  }

  @media (max-width: 500px) {
    height: 200px;
    min-height: 200px;
    max-height: 200px;
  }

  @media (max-width: 420px) {
    height: 180px;
    min-height: 180px;
    max-height: 180px;
  }
`;

export const NavigationDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  right: 40px;
  bottom: 64px;
  z-index: 99;

  svg{
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: ${theme.colors.background};
    border-radius: 50%;
    transition: all .4s;

    path{
      stroke: ${theme.colors.gray400};
      transition: all .4s;
    }
    rect{
      fill: transparent
    }

    &.disable{
      path{
        stroke: ${theme.colors.gray200};
      }
    }

    &:hover{
      path{
        stroke: ${theme.colors.text};
      }
    }
  }

`;
