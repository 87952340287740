import React, { useState, useEffect } from 'react'
import { AddCategory, Container, CategoriesList, Title, Category } from './styles'

import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg"
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg"
import ModalAddCategory from '../../../components/ModalAddCategory'
import { deleteCategory, getCategories } from '../../../services/api'

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';
import ModalEditCategory from '../../../components/ModalEditCategory'

type ResponseType = {
  id: number;
  name: string;
  picture: string;
  quantity: number;
  createdAt: string;
  updatedAt: string;
};

function Categories() {

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [categories, setCategories] = useState<ResponseType[]>([])

  const [idSelected, setIdSelected] = useState<number>(0)

  const URL = process.env.REACT_APP_URL_AWS_S3

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleOpenModalEdit = (idCupon: number) => {
    setIdSelected(idCupon)
    setOpenModalEdit(true)
  }

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false)
  }

  async function getCategory() {
    setLoading(true)
    const result = await getCategories();

    if (Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as ResponseType[];

      if (code === 200) {
        setCategories(response);
        setLoading(false);
        return;
      }
    } else {
      console.error("ERROR TO GET CATEGORIES")
    }

  }

  useEffect(() => {
    getCategory();
  }, [])

  const deleteCategories = async (id: number) => {
    setLoading(true)
    await deleteCategory(id)
    setLoading(false)
    getCategory()
  }

  return (
    <Container>
      <ModalAddCategory isOpen={openModal} onRequestClose={handleClose} />
      <ModalEditCategory isOpen={openModalEdit} onRequestClose={handleCloseModalEdit} idCupon={idSelected} />
      <AddCategory onClick={handleOpen}>
        Adicionar nova Categoria
      </AddCategory>

      <Title>Categorias</Title>

      <CategoriesList>
        {
          loading ?
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
          )
          :
          (
            categories.map(item => (
              <Category key={item.id}>
                <img src={`${URL}/${item.picture}`} alt={`${item.id}-${item.name}`} />
                <div className="text">
                  <h2>{item.name}</h2>
                  <h3>Produtos: {item.quantity}</h3>
                </div>

                <EditIcon className='edit' onClick={() => handleOpenModalEdit(item.id)} />
                <TrashIcon className='delete' onClick={() => deleteCategories(item.id)} />
              </Category>
            ))
          )
        }
      </CategoriesList>
    </Container>
  )
}

export default Categories
