import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const Container = styled.section`
  width: calc(100vw - 241px - 24px);
  min-height: calc(100vh - 24px);
  max-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: -24px;
  margin-bottom: -24px;
`;

export const AddCoupon = styled.button`
  height: 48px;
  min-height: 48px;
  padding: 0px 32px;
  width: fit-content;
  border-radius: 8px;
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin-bottom: 32px;

  &:active{
    background-color: ${theme.colors.lightBlue};
  }
`;

export const Title = styled.h3`
  width: calc(100vw - 241px - 72px);
  font-size: 16px;
  font-family: "NeueMachinaLight";
  color: ${theme.colors.black};
  padding-left: 24px;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.gray200};
  margin-bottom: 16px;
`;

export const CuponsList = styled.div`
  width: calc(100vw - 241px - 48px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray400};
  }
`;

export const Coupon = styled.div`
  height: 128px;
  min-height: 128px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.white};
  position: relative;
  cursor: pointer;

  h1{
    font-size: 20px;
    font-family: "NeueMachinaLight";
    color: ${theme.colors.text};
    margin: 0;
    margin-bottom: 4px;
    margin-left: 12px;
  }

  img{
    height: 104px;
    width: 128px;
    margin: 0 12px;
    object-fit: cover;
    object-position: center;
  }

  div.text{
    height: 104px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    h2{
      font-size: 18px;
      font-family: "NeueMachinaLight";
      color: ${theme.colors.black};
      margin: 0;
      margin-bottom: 4px;
    }

    h3{
      font-size: 12px;
      font-family: "NeueMachinaLight";
      color: ${theme.colors.black};
      margin: 0;
    }
  }

  svg.edit{
    height: 24px;
    width: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;

    path { stroke: ${theme.colors.black}}
  }

  svg.delete{
    height: 24px;
    width: 24px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;

    path { stroke: ${theme.colors.primary }}
  }

  button{
    height: 24px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    background-color: ${theme.colors.lightGreen};
    color: ${theme.colors.white};
    font-size: 16px;
    border-radius: 4px;
  }
`;

export const LoadMore = styled.div`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin: 24px 0;
`;
