import React, { useEffect, useState } from 'react'
import { Container, List, LoadMore, Cupon, Title } from './styles'

import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg"
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg"
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg"
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg"
import { ReactComponent as WarnIcon } from "../../../assets/icons/warning.svg"

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';
import { deletePublicity, getPublicity } from '../../../services/api';
import { AddCategory } from '../Categories/styles';
import ModalAddPublicity from '../../../components/ModalAddPublicity';
import { toast } from 'react-toastify';
import ModalEditPublicity from '../../../components/ModalEditPublicity';

type publicityItem = {
  id: number
  picture: string
  init: string
  end: number
}

function Publicity() {

  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [publicities, setPublicities] = useState<publicityItem[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenModalEdit, setIsOpenModalEdit] = useState<boolean>(false)
  const [idPublicity, setIdPublicity] = useState<number>(0)

  const currentDate = new Date()

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpenModalEdit = (id: number) => {
    if(!id) {
      return
    }
    setIdPublicity(id)
    setIsOpenModalEdit(true)
  }

  const handleCloseModalEdit = () => {
    setIsOpenModalEdit(false)
  }

  const URL = process.env.REACT_APP_URL_AWS_S3

  async function getPublicities() {
    setLoading(true)
    let search = ""
    search += `&page=${page}`

    const response = await getPublicity(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as publicityItem[];

      if (code === 200) {
        setPublicities([...publicities, ...data]);
        setLoading(false);
        return;
      }
    } else {
      toast.error("Erro ao obter cupons")
    }
  }

  useEffect(() => {
    getPublicities()
  }, [page])

  const deletePubli = async (id: number) => {
    if(!id) {
      toast.error("Erro ao deletar publicidade")
      return
    }

    setLoading(true)
    const code = await deletePublicity(id)

    if (code === 200) {
      toast.success("Publicidade deletada")
      setPublicities(publicities.filter(item => item.id !== id))
      setLoading(false)
      return
    }

    toast.error("Erro ao deletar publicidade")
    setLoading(false)
  }

  return (
    <Container>
      <ModalAddPublicity isOpen={isOpen} onRequestClose={handleClose} />
      <ModalEditPublicity isOpen={isOpenModalEdit} onRequestClose={handleCloseModalEdit} idPublicity={idPublicity} />
      <AddCategory onClick={handleOpen}>
        Adicionar Publicidade
      </AddCategory>

      <Title>Publicidades</Title>

      <List>
      {
        publicities.map((item) => (
          <Cupon key={item.id}>
            <img src={`${URL}/${item.picture}`} alt={`Publidade ${item.id}`} />
            <div className="text">
              <h2>ID Publicidade: {item.id}</h2>
              <h2>Data de início: {String(new Date(item.init).getDate()).padStart(2, "0")}/{String(new Date(item.init).getMonth()+1).padStart(2, "0")}/{new Date(item.init).getFullYear()}</h2>
              <h2>Data final: {String(new Date(item.end).getDate()).padStart(2, "0")}/{String(new Date(item.end).getMonth()+1).padStart(2, "0")}/{new Date(item.end).getFullYear()}</h2>

              {
                new Date(item.init) <= currentDate && new Date(item.end) >= currentDate ? <CheckIcon className='icon active' /> : null
              }

              {
                new Date(item.end) < currentDate ? <CrossIcon className='icon desactive' /> : null
              }

              {
                new Date(item.init) > currentDate ? <WarnIcon className='icon pending' /> : null
              }

            </div>

            <EditIcon className='edit' onClick={() => handleOpenModalEdit(item.id)} />
            <TrashIcon className='delete' onClick={() => deletePubli(item.id)} />
          </Cupon>
        ))
      }

      {
          loading ?
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
          )
          :
          (
            <LoadMore onClick={() => setPage(page + 1)}>
              Carregar mais
            </LoadMore>
          )
        }
      </List>


    </Container>
  )
}

export default Publicity
