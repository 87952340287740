import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
import { AddToCart, Container, Content } from './styles'
import {ReactComponent as AddCartIcon} from "../../assets/icons/shopping-cart-add.svg"
import { LoginContext } from '../../contexts/LoginContext'
import { ModalsContexts } from '../../contexts/ModalsContexts'
import { addCart, addFavorites, removeFavorites } from '../../services/api'

import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"


type ItemsProp = {
  id: number,
  name: string,
  priceOf: number,
  pricePurchase: number,
  picture: string,
}

import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';
import { theme } from '../../styles/Theme';

function ItemLayout({ id, name, pricePurchase, priceOf, picture }: ItemsProp) {

  const { user, setUser } = useContext(LoginContext)
  const { handleOpenModalLogin } = useContext(ModalsContexts)

  const [load, setLoad] = useState<boolean>(false)
  const [loadFavorite, setLoadFavorite] = useState<boolean>(false)

  const URL = process.env.REACT_APP_URL_AWS_S3

  const addToCartFunc = async (cuponId: number) => {
    if(load){
      return
    }

    if(!user || !user?.token) {
      handleOpenModalLogin()
    }

    if(user && user?.token === process.env.REACT_APP_USER_TOKEN) {
      setLoad(true)
      const code = await addCart({ cupon_id: cuponId, user_id: user.id, quantity: 1 })

      if (code === 201) {
        if(user.Carts.filter((item: { cupon_id: number }) => item.cupon_id === cuponId).length > 0) {
          setUser({
            ...user,
            Carts: user.Carts.map((item: { cupon_id: number, quantity: number }) => item.cupon_id === cuponId ? { ...item, quantity: item.quantity + 1 } : item)
          })
        } else {
          setUser({
            ...user,
            Carts: [...user.Carts, { user_id: user.id, cupon_id: cuponId, quantity: 1 }]
          })
        }
        toast.success("Adicionado no carrinho")
        setLoad(false)
        return
      }
      if (code === 400) {
        toast.error("Erro ao adicionar ao carrinho")
        setLoad(false)
        return
      }
      if (code === 406) {
        toast.error("Quantidade máxima excedida")
        setLoad(false)
        return
      }
      toast.error("Erro ao adicionar ao carrinho")
      setLoad(false)
    }
  }

  const addFavoriteCupon = async (cuponId: number) => {
    if(loadFavorite){
      return
    }

    if(!user || !user?.token) {
      handleOpenModalLogin()
      return
    }

    if(user && user.token === process.env.REACT_APP_USER_TOKEN  && Array.isArray(user?.favorites_id)) {
      setLoadFavorite(true)
      const code = await addFavorites({ cupon_id: cuponId, user_id: user.id })

      if (code === 201) {
        const favorites = user.favorites_id
        favorites.push(cuponId)
        setUser({
          ...user,
          favorites_id: favorites
        })
        setLoadFavorite(false)
        return
      }
      if (code === 400) {
        toast.error("Erro ao adicionar aos favoritos")
        setLoadFavorite(false)
        return
      }
      if (code === 401) {
        toast.warn("Este cupon já está adicionado nos favoritos")
        setLoadFavorite(false)
        return
      }
      toast.error("Erro ao adicionar aos favoritos")
      setLoadFavorite(false)
      return
    }
    setLoadFavorite(false)
  }

  const removeFavoriteCupon = async (cuponId: number) => {
    if(loadFavorite){
      return
    }

    if(!user || !user?.token) {
      handleOpenModalLogin()
      return
    }

    if(user && user.token === process.env.REACT_APP_USER_TOKEN  && Array.isArray(user?.favorites_id)) {
      setLoadFavorite(true)
      const code = await removeFavorites({ cupon_id: cuponId, user_id: user.id })

      if (code === 200) {
        setUser({
          ...user,
          favorites_id: user.favorites_id.filter((cupon: number) => cupon !== cuponId)
        })
        setLoadFavorite(false)
        return
      }
      if (code === 400) {
        toast.error("Erro ao remover dos favoritos")
        setLoadFavorite(false)
        return
      }
      toast.error("Erro ao remover dos favoritos")
      setLoadFavorite(false)
      return
    }
    toast.error("Erro ao remover dos favoritos")
    setLoadFavorite(false)
  }

  return (
    <Container>
      {
        loadFavorite ? <UseAnimations animation={loading} className='like' size={14} strokeColor={theme.colors.gray400} /> :
        (
          user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN && user?.favorites_id.indexOf(id) !== -1 ? <AiFillHeart onClick={() => removeFavoriteCupon(id)} className='like marked' /> : <AiOutlineHeart onClick={() => addFavoriteCupon(id)} className='like' />
        )
      }
      <Content to={`/cupon/${id}`}>
        <img src={`${URL}/${picture}`} alt={name} />
        <div className="title">
          <span>{name}</span>
          <div className="discount">
            {(100 - ((pricePurchase * 100) / priceOf)).toFixed(0)}%
          </div>
        </div>

        <div className="priceInfo">
          <div className="info border">
            <span>Economize</span>
            <h3>R$ {String((priceOf - pricePurchase).toFixed(2)).replace(".", ",")}</h3>
          </div>

          <div className="info">
            <span>De R${priceOf.toFixed(0)} por</span>
            <h3>R$ {String(pricePurchase.toFixed(2)).replace(".", ",")}</h3>
          </div>
        </div>
      </Content>

      <AddToCart onClick={() => addToCartFunc(id)}>
        {
          load ? <UseAnimations animation={loading} size={28} strokeColor={theme.colors.white} /> :
          (
            <>
              <span>Comprar</span>
              <AddCartIcon />
            </>
          )
        }
      </AddToCart>
    </Container>
  )
}

ItemLayout.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  priceOf: PropTypes.number.isRequired,
  pricePurchase: PropTypes.number.isRequired,
  picture: PropTypes.string.isRequired,
}

export default ItemLayout
