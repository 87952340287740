import styled from 'styled-components'
import { theme } from '../../styles/Theme';
import { NavLink } from 'react-router-dom';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  width: 95%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 88px;
  gap: 16px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
  width: 100%;

  svg{
    height: 32px;
    width: 32px;

    circle, path{
      fill: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 32px;
    color: ${theme.colors.gray400};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const MenuInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.gray200};
  position: relative;
`;

export const OpInfo = styled(NavLink)`
  width: 192px;
  height: 24px;
  font-size: 16px;
  color: ${theme.colors.gray400};
  font-family: "NeueMachinaLight";
  font-weight: 200;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.active{
    color: ${theme.colors.text};
  }
`;

export const Line = styled.div`
  width: 192px;
  height: 2px;
  border-radius: 2px;
  background-color: ${theme.colors.primary};
  position: absolute;
  bottom: -1px;
  left: 0px;
  transition: all .4s ease-out;
`;

export const Field = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
