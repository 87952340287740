import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { login } from "../services/api";
import { useNavigate } from "react-router-dom";

export const LoginContext = createContext();

export function LoginProvider({ children }) {

  const [user, setUser] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    async function signIn() {
      if(localStorage.getItem("cupozin-auth")) {
        try {
          const data = JSON.parse(localStorage.getItem("cupozin-auth"))
          if (data?.token && data?.email) {
            if(data?.access_token) {
              const [code, response] = await login({email: data.email, token: data.access_token})

              if(code === 200) {
                const auth = {
                  name: response.name,
                  tel: response.tel,
                  email: response.email,
                  token: response.token,
                  access_token: response.tokenAccess,
                }
                localStorage.setItem("cupozin-auth", JSON.stringify(auth))
                setUser(response)

                return
              } else {
                localStorage.removeItem("cupozin-auth")
              }

            } else {
              localStorage.removeItem("cupozin-auth")
            }

          } else {
            localStorage.removeItem("cupozin-auth")
          }
        } catch {
          localStorage.removeItem("cupozin-auth")
        }
      }
    }
    signIn()
  }, [])

  const logout = () => {
    localStorage.removeItem("cupozin-auth")
    navigate("/")
    setUser()
  }

  return(
    <LoginContext.Provider
      value={{
        user, setUser,
        logout
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

LoginProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
