import React, { useContext, useState, useEffect } from 'react'
import { Container, Content, Form, Title, PaymentContent, Products, MenuRestaurants } from './style';
import Header from '../../components/Header';

import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import CursorMask from '../../components/CursorMask';
import { LoginContext } from '../../contexts/LoginContext';
import { toast } from 'react-toastify';
import Footer from '../../components/Footer';


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

import { CartContext } from '../../contexts/CartContext';
import { initMercadoPago, Payment } from '@mercadopago/sdk-react';
import { getRestaurant, payment } from '../../services/api';
import { useNavigate } from 'react-router-dom';

function CheckOut() {

  const navigate = useNavigate()
  const { user, setUser } = useContext(LoginContext)
  const [initialization, setInitialization] = useState()
  const { cart, load, setCart, setLoading  } = useContext(CartContext)

  const [restaurantsIds, setRestaurantsIds] = useState([])
  const [restaurants, setRestaurants] = useState([])
  const [cartTwo, setCartTwo] = useState([])
  const [restaurantMarked, setRestaurantMarked] = useState(false)

  const [price, setPrice] = useState(0)

  const [loadingCheckOut, setLoadingChekOut] = useState(true)

  useEffect(() => {
    setPrice(0)
    setLoading(true)
    setCartTwo(cart.filter(item => item.restaurant_id === restaurantMarked))
    setLoading(false)
  }, [restaurantMarked])

  useEffect(() => {
    let value = 0
    cartTwo.map((item) => value = value + item.price_purchase * item.quantity)
    setPrice(value)
  }, [cartTwo])

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN && user?.email && price > 0) {
      const init = {
        amount: price.toFixed(2),
        payer: {
          email: user.email,
        },
        
      };
      setInitialization(init)

      initMercadoPago(process.env.REACT_APP_PUBLIC_KEY_MP, { locale: 'pt-BR' })
      setLoadingChekOut(false)
    }
  }, [price])

  const [fetchedRestaurants, setFetchedRestaurants] = useState({});

  const getAllRestaurants = async (restaurantId) => {
    if (!fetchedRestaurants[restaurantId]) {
      const [code, response] = await getRestaurant(restaurantId);
      if (code === 200) {
        setRestaurantsIds([...restaurantsIds, restaurantId]);
        setRestaurants([...restaurants, response]);
        setFetchedRestaurants({
          ...fetchedRestaurants,
          [restaurantId]: true,
        });
      }
    }
  };
  
  useEffect(() => {
    if (cart && cart.length > 0) {
      cart.forEach((item) => {
        if (!fetchedRestaurants[item.restaurant_id]) {
          getAllRestaurants(item.restaurant_id);
        }
      });
    }
  }, [cart, fetchedRestaurants]);




  const customization = {
    visual: {
      style: {
        theme: 'bootstrap',
        customVariables: {
          baseColor: theme.colors.lightGreen,
          textPrimaryColor: theme.colors.text,
          textSecondaryColor: theme.colors.gray400,
          errorColor: theme.colors.primary,
          successColor: theme.colors.lightGreen,
          baseColorSecondVariant: theme.colors.lightBlue,
          borderRadiusSmall: "4px",
          borderRadiusMedium: "4px",
          borderRadiusLarge: "4px",
          borderRadiusFull: "4px",
        },
      },
      texts: {
        cardholderName: {
          label: "Nome do titular do cartão",
          placeholder:"ex.: José Silva"
        },
      },
      paymentMethods: {
        maxInstallments: 6,
      }
    },
    paymentMethods: {
      creditCard: 'all',
      debitCard: 'all',
      bankTransfer: "all",
      maxInstallments: 12,
    },
  }


  const URL = process.env.REACT_APP_URL_AWS_S3

  const submit = async (formData) => {
    if(!restaurantMarked) {
      toast.warn("Não foi possível processar o pagamento para o restaurante")
      return
    }
    let restaurantName = ""
    restaurants.map(item => item.id === restaurantMarked ? restaurantName = item.name : null)

    let items = []

    cartTwo.map(item => {
      items.push({
        id: String(item.cupon_id),
        unit_price: item.price_purchase,
        quantity: item.quantity,
        category_id: item.category,
        title: item.plate_name,
        picture_url: `${URL}/${item?.picture}`,
      })
    })

    const data = {
      ...formData.formData,
      description: `Pedido Cupons - ${restaurantName}`,
      application_fee: Number((price.toFixed(2) * 0.10).toFixed(2)), // 10% do Valor para cuponzin
      external_reference: String(restaurantMarked),
      additional_info: {
        items,
      },
      metadata: { user_id: user.id }
    }


    const [code, response] = await payment(data)
    if(code === 200) {
      navigate(`/pagamento_status/${response?.body.id}`)
      setCart([])
      setUser({
        ...user,
        Carts: []
      })
      return
    }
    if(response?.body && response?.body.id) {
      navigate(`/pagamento_status/${response?.body.id}`)
      return
    }
    toast.error("Error ao processar pagamento")
    console.error(response)
    return
  }

  if(!cart || !user || !user?.token || user?.token !== process.env.REACT_APP_USER_TOKEN || !user?.Carts) {
    return (
      <Container>
        <CursorMask />
        <Header />
        <Content>
          <Title>
            <CardIcon/>
            <h2>Pagamento</h2>
          </Title>
        </Content>
        <Footer />
      </Container>
    )
  }


  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <MenuRestaurants>
          {
            restaurants.map(item => (
              <div className={item.id === restaurantMarked ? 'op active' : 'op'} key={item.id} onClick={() => setRestaurantMarked(item.id)}>
                <span>{item.name}</span>
              </div>
            ))
          }
        </MenuRestaurants>

        <Title>
          <CardIcon />
          <h2>Pagamento</h2>
        </Title>
        
        {
          restaurantMarked && price ? 
          (
            <PaymentContent>
              <Form>
                {
                  loadingCheckOut || !price ? <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} /> :
                  (
                    <Payment
                      initialization={initialization}
                      customization={customization}
                      onSubmit={submit}
                    />
                  )
                }
              </Form>
              <Products>
                {
                  load ? <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} /> :
                  (
                    <>
                      <div className="list-products">
                      {
                        cartTwo.map((item) => (
                          <a key={item?.id} href={`/cupon/${item?.cupon_id}`}>
                            <img src={`${URL}/${item?.picture}`} alt={item?.plate_name} />
                            <div className="text">
                              <h1>{item?.plate_name}</h1>
                              <h2>R$ {String(item?.price_purchase.toFixed(2)).replace(".", ",")}</h2>
                              <h3>x{item?.quantity}</h3>
                            </div>
                          </a>
                        ))
                      }
                    </div>
                    <div className="total">
                      <h2>Total</h2>
                      <h1>R$ {String(price.toFixed(2)).replace(".", ",")}</h1>
                    </div>
                    </>
                  )
                }
              </Products>
            </PaymentContent>
          ) : null
        }
      </Content>
      <Footer />
    </Container>
  )
}

export default CheckOut
