import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form, Link1, Open } from './styles'
import "./style.css";

import {ReactComponent as Logo } from "../../assets/images/Salmao.svg"
import InputField from '../InputField';
import PasswordField from '../PasswordField';

import { ModalsContexts } from '../../contexts/ModalsContexts'
import { login } from '../../services/api';
import { LoginContext } from '../../contexts/LoginContext';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

function ModalLogin() {

  const { setUser } = useContext(LoginContext)
  const navigate = useNavigate()

  const { modalLogin, handleCloseModalLogin, handleOpenModalRegister } = useContext(ModalsContexts);
  const [info, setInfo] = useState("⠀")
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState("")

  const [senha, setSenha] = useState("")
  

  const singIn = async (event) => {
    event.preventDefault()
    setInfo("⠀")
    if(!email || !senha){
      setInfo("Preencha todos os campos")
      return
    }

    // if(email === process.env.REACT_APP_ADMIN_USER && senha === process.env.REACT_APP_ADMIN_SECRET){
    //   const auth = {
    //     name: "Admin",
    //     email: "admin@admin.com",
    //     token: process.env.REACT_APP_ADMIN_TOKEN,
    //   }

    //   localStorage.setItem("cupozin-auth", JSON.stringify(auth))
    //   setUser(auth)
    //   handleCloseModalLogin()
    //   navigate("/admin/home")
    //   return
    // }

    setLoading(true)
    const [code, response] = await login({ email, password: senha })
    setSenha("")

    if(code === 200) {
      const auth = {
        name: response.name,
        tel: response.tel,
        email: response.email,
        token: response.token,
        access_token: response.tokenAccess,
      }
      localStorage.setItem("cupozin-auth", JSON.stringify(auth))
      setUser(response)
      setLoading(false)
      handleCloseModalLogin()

      if(response.token === process.env.REACT_APP_RESTAURANT_TOKEN){
        navigate("/restaurante/home")
      }

      if(response.token === process.env.REACT_APP_ADMIN_TOKEN){
        navigate("/admin/home")
      }
      return
    }

    if(code === 401) {
      setInfo("Senha e/ou email errados")
      setLoading(false)
      return
    }

    if(code === 404) {
      setInfo("Email não cadastrado")
      setLoading(false)
      return
    }
    setInfo(`${code} ERROR`)
    setLoading(false)
  }

  return (
    <Container
      isOpen={modalLogin}
      onRequestClose={handleCloseModalLogin}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <Logo className='logo-modal'/>
      <span>{info}</span>
      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} />
        )
        :
        (
          <Form onSubmit={singIn}>
            <InputField
              placeHolder='E-MAIL'
              value={email}
              changeValue={setEmail}
              type='email'
              id='email'
              required={true}
            />

            <PasswordField
              placeHolder='SENHA'
              value={senha}
              changeValue={setSenha}
              type='password'
              id='password'
              required={true}
            />

            <Link1 style={{marginBottom: "24px", marginTop: '8px'}} to="/esqueceu-a-senha" onClick={() => handleCloseModalLogin()}>Esqueceu a senha?</Link1>

            <Button type='submit'>
              Entrar
            </Button>

            <Open
              style={{marginBottom: "12px", marginTop: '12px', fontSize: "14px"}}
              onClick={handleOpenModalRegister}
            >
            Criar conta</Open>
          </Form>
        )
      }

    </Container>
  )
}

export default ModalLogin
