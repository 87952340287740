import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../styles/Theme";
// import { NavLink } from "react-router-dom";

Modal.setAppElement("#root")

export const Container = styled(Modal)`
  width: 90%;
  max-width: 640px;
  padding: 12px;
  height: auto;
  max-height: 90vh;
  min-height: 80px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  transition: all .4s;
  position: relative;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  svg.logo-modal{
    height: 24px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;

    path, rect, polygon{
      fill: ${theme.colors.primary};
    }
  }

  span{
    margin: 0;
    font-size: 12px;
    margin-bottom: 16px;
    color: ${theme.colors.lightBlue};
  }

  h2{
    margin: 0;
    font-size: 20px;
    margin-bottom: 24px;
    color: ${theme.colors.text};
    text-align: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Form = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    margin: 0;
    font-size: 12px;
    color: ${theme.colors.gray400};
    text-align: center;
    font-family: "NeueMachinaLight";
    width: 100%;
    font-weight: 200;
    text-align: left;
    margin-bottom: 4px;
  }

  input{
    width: 100%;
    margin: 0;
  }

  input[type='text']{
    width: 100%;
    height: 24px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid ${theme.colors.text};
    color: ${theme.colors.text};
    font-size: 16px;
    font-family: "NeueMachinaLight";
  }
`;

export const Preview = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    margin: 0;
    font-size: 12px;
    color: ${theme.colors.gray400};
    text-align: center;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    text-align: left;
    margin-bottom: 12px;
  }
`;

export const Layout = styled.div`
  height: 100%;
  width: 192px;
  max-width: 192px;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;

  img{
    height: 104px;
    min-height: 80px;
    width: auto;
    margin-top: -32px;
    margin-bottom: 20px;
  }

  span{
    font-size: 18px;
    color: ${theme.colors.text};
    font-family: "NeueMachinaLight";
    text-align: center;
  }
`;

export const Button = styled.button`
  height: 48px;
  min-height: 48px;
  padding: 0px 32px;
  width: 100%;
  border-radius: 8px;
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin-top: 32px;

  &:active{
    background-color: ${theme.colors.lightBlue};
  }
`;
