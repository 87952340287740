import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const Container = styled.section`
  width: calc(100vw - 241px - 24px);
  min-height: calc(100vh - 24px);
  max-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: -24px;
  margin-bottom: -24px;
`;

export const Title = styled.h3`
  width: calc(100vw - 241px - 72px);
  font-size: 16px;
  font-family: "NeueMachinaLight";
  color: ${theme.colors.black};
  padding-left: 24px;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid ${theme.colors.gray200};
  margin-bottom: 16px;
`;

export const List = styled.div`
  width: calc(100vw - 241px - 48px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray400};
  }
`;

export const Cupon = styled.div`
  height: 180px;
  min-height: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.white};
  position: relative;

  svg.edit{
    height: 14px;
    width: 14px;
    padding: 4px;
    border-radius: 50%;
    background-color: ${theme.colors.white};
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;

    path{
      stroke: ${theme.colors.gray400};
    }
  }

  img{
    height: 156px;
    width: 156px;
    margin: 0 12px;
    object-fit: cover;
    object-position: center;
  }

  div.text{
    height: 156px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    h1{
      font-size: 18px;
      font-family: "NeueMachinaLight";
      color: ${theme.colors.black};
      margin: 0;
      margin-bottom: 8px;
    }

    h2{
      font-size: 12px;
      font-family: "NeueMachinaLight";
      color: ${theme.colors.gray400};
      margin: 0;
    }

    div.location{
      display: flex;
      align-items: center;
      gap: 8px;

      svg{
        height: 16px;
        width: 16px;

        path {
          fill: ${theme.colors.gray400}
        }
      }

      h2{
        font-size: 12px;
        font-family: "NeueMachinaLight";
        color: ${theme.colors.gray400};
        margin: 0;
      }
    }
  }

  svg.delete{
    height: 24px;
    width: 24px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;

    path { stroke: ${theme.colors.primary }}
  }
`;

export const LoadMore = styled.div`
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin: 24px 0;
`;

export const Input = styled.div`
  width: calc(100vw - 241px - 48px);
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  background-color: ${theme.colors.white};
  margin: 16px 0;
  display: flex;
  align-items: center;

  input{
    width: 98%;
    margin-left: 8px;
    height: 32px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.black};

    &::placeholder{
      color: ${theme.colors.gray200};
    }
  }
`;
