import styled, { keyframes } from "styled-components";
import { theme } from "../../../../styles/Theme";

const AnimationLoading = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  height: 152px;
  position: absolute;
  right: -2.6%;

  .content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .slider{
    height: 100%;
    width: 192px;
    max-width: 192px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;

    a{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;

      img{
        height: 104px;
        width: auto;
        margin-top: -32px;
        margin-bottom: 20px;
      }

      span{
        font-size: 18px;
        color: ${theme.colors.text};
        font-family: "NeueMachinaLight";
        text-align: center;
      }
    }
  }

  .slider-load{
    height: 100%;
    width: 192px;
    max-width: 192px;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;

    div.img{
      height: 104px;
      width: 104px;
      margin-top: -32px;
      margin-bottom: 20px;
      background-color: ${theme.colors.gray100};
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      &:before{
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(45deg, ${theme.colors.primary}, ${theme.colors.gray100});
        left: 0%;
        top: 0%;
        animation: ${AnimationLoading} 1000ms linear infinite ;
      }
    }

    div.span{
      height: 24px;
      width: 80px;
      background-color: ${theme.colors.gray100};
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      &:before{
        position: absolute;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(45deg, ${theme.colors.primary}, ${theme.colors.gray100});
        left: 0%;
        top: 0%;
        animation: ${AnimationLoading} 1000ms linear infinite ;
      }
    }

  }
`;

export const NavigationDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 2.6%;
  bottom: -56px;

  svg{
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-color: transparent;

    path{
      stroke: ${theme.colors.gray400};
    }
    rect{
      fill: transparent
    }

    &.disable{
      path{
        stroke: ${theme.colors.gray200};
      }
    }
  }

`;
