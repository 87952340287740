import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../styles/Theme";
import InputMask from "react-input-mask";

Modal.setAppElement("#root")

export const Container = styled(Modal)`
  width: 90%;
  max-width: 640px;
  padding: 12px;
  height: auto;
  max-height: 90vh;
  min-height: 80px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  transition: all .4s;
  position: relative;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  svg.logo-modal{
    height: 24px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;

    path, rect, polygon{
      fill: ${theme.colors.primary};
    }
  }

  span{
    margin: 0;
    font-size: 12px;
    margin-bottom: 16px;
    color: ${theme.colors.lightBlue};
  }

  h2{
    margin: 0;
    font-size: 20px;
    margin-bottom: 24px;
    color: ${theme.colors.text};
    text-align: center;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  svg.delete{
    height: 24px;
    width: 24px;
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    right: 12px;

    path {
      stroke: ${theme.colors.primary};
    }
  }
`;

export const Form = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    margin: 0;
    font-size: 12px;
    color: ${theme.colors.gray400};
    text-align: center;
    font-family: "NeueMachinaLight";
    width: 100%;
    font-weight: 200;
    text-align: left;
    margin-bottom: 4px;
  }

  input{
    width: 100%;
    margin: 0;
  }

  div.line{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div.input{
      display: flex;
      flex-direction: column;
      width: 48%;

      input[type='number']{
        width: 100%;
        height: 24px;
        border: none;
        background-color: transparent;
        border-bottom: 1px solid ${theme.colors.text};
        color: ${theme.colors.text};
        font-size: 16px;
        font-family: "NeueMachinaLight";
      }
    }
  }

  input[type='text']{
    width: 100%;
    height: 24px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid ${theme.colors.text};
    color: ${theme.colors.text};
    font-size: 16px;
    font-family: "NeueMachinaLight";
  }

  textarea{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid ${theme.colors.text};
    color: ${theme.colors.text};
    font-size: 16px;
    font-family: "NeueMachinaLight";
  }

  select {
    width: 100%;
    height: 24px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid ${theme.colors.text};
    color: ${theme.colors.text};
    font-size: 16px;
    font-family: "NeueMachinaLight";

    option {
      color: ${theme.colors.text};
      font-size: 16px;
      font-family: "NeueMachinaLight";
      background-color: ${theme.colors.white};
    }
  }

  div.quantity{
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;

    svg{
      height: 28px;
      width: 28px;
      cursor: pointer;
      margin: 0;

      path{
        transition: all .4s;
        stroke: ${theme.colors.gray400};
      }

      rect{
        fill: transparent;
      }


      &:hover path {
        stroke: ${theme.colors.text};
      }

      &.block{
        cursor: not-allowed;
        path { stroke: ${theme.colors.gray200}}
      }
    }

    span {
      width: 40px;
      font-size: 20px;
      color: ${theme.colors.text};
      font-family: "NeueMachinaLight";
      font-weight: 200;
      text-align: center;
      margin: 0;
    }

    input{
      height: 22px;
      width: 48px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 20px;
      color: ${theme.colors.text};
      font-family: "NeueMachinaLight";
      font-weight: 200;
      text-align: center;
    }
  }
`;

export const Preview = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4{
    margin: 0;
    font-size: 12px;
    color: ${theme.colors.gray400};
    text-align: center;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    text-align: left;
    margin-bottom: 12px;
  }
`;



export const Button = styled.button`
  height: 48px;
  min-height: 48px;
  padding: 0px 32px;
  width: 100%;
  border-radius: 8px;
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s;
  border: none;
  margin-top: 32px;

  &:active{
    background-color: ${theme.colors.lightBlue};
  }
`;

export const InputWithMask = styled(InputMask)`
  width: 100%;
  height: 24px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid ${theme.colors.text};
  color: ${theme.colors.text};
  font-size: 16px;
  font-family: "NeueMachinaLight";
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  max-width: 256px;
  background-color: ${theme.colors.white};
  position: relative;
  overflow: hidden;
  height: 340px;

  &:before, &:after{
    content: '';
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    bottom: 56px;
    background-color: ${theme.colors.background};
  }

  &:before{
    left: -12px;
  }

  &::after{
    right: -12px;
  }

  svg.like{
    height: 14px;
    width: 14px;
    padding: 6px;
    border-radius: 50%;
    background-color: ${theme.colors.white};
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 99;

    path{
      fill: ${theme.colors.gray400};
    }
  }
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  position: relative;
  transition:  all .4s;
  cursor: pointer;


  img{
    width: 100%;
    height: 128px;
    min-height: 128px;
    object-fit: cover;
    object-position: center;
    transition:  all .4s;

  }

  &:hover img{
    width: 110%;
  }

  div.title{
    width: 100%;
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.gray200};
    position: relative;

    span.plate{
      font-size: 16px;
      color: ${theme.colors.text};
      font-family: "NeueMachinaLight";
      font-weight: 200;
      margin: 0;
      margin-left: 24px;
      width: 50%;
    }

    div.discount{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: ${theme.colors.primary};
      position: absolute;
      top: -24px;
      right: 24px;
      font-size: 14px;
      color: ${theme.colors.white};
      font-family: "NeueMachinaLight";
      font-weight: 200;
    }
  }

  div.priceInfo{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    div.info{
      width: 50%;
      min-width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span{
        font-size: 14px;
        color: ${theme.colors.text};
        font-family: "NeueMachinaLight";
        font-weight: 200;
        margin: 0;
        margin-bottom: 2px;
      }

      h3{
        font-size: 20px;
        color: ${theme.colors.text};
        font-family: "NeueMachinaLight";
        margin: 0;
      }

      &.border{
        border-right: 1px solid ${theme.colors.gray200};
        height: 100%;
      }
    }
  }
`;

export const AddToCart = styled.div`
  height: 68px;
  min-height: 68px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${theme.colors.lightGreen};
  cursor: pointer;

  span{
    font-size: 16px;
    color: ${theme.colors.white};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin: 0;
  }

  svg{
    height: 20px;
    width: 20px;

    path, circle{ fill: ${theme.colors.white}}
  }
`;
