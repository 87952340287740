import React, { useEffect, useState, useContext } from 'react'
import { AddCoupon, Container, Coupon, CuponsList, Input, LoadMore, Show, Title } from './styles'

import ModalValidateCupon from '../../../components/ModalValidateCupon'
import { getCodes } from '../../../services/api'
import { LoginContext } from '../../../contexts/LoginContext'

import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';

type ResponseType = {
  id: number;
  cupon_id: number;
  code: string;
  used: boolean,
  restaurant_id: number,
  user_id?: number,
  CuponId: number;
  createdAt: string;
  updatedAt: string;
};

function Validate() {

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [coupons, setCoupons] = useState<ResponseType[]>([])
  const [page, setPage] = useState<number>(1)
  const [searchCupon, setSearchCupon] = useState<string>("")

  const { user } = useContext(LoginContext);

  const handleOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  async function getCuponsRestaurant() {
    setLoading(true)
    let search = ""

    const restaurantId = user.id
    search += `&page=${page}`
    search += `&search=${searchCupon.toUpperCase()}`

    const response = await getCodes(restaurantId, search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as ResponseType[];

      if (code === 200) {
        setCoupons([...coupons, ...data]);
        setLoading(false);
        return;
      }
    } else {
      console.error("ERROR TO GET COUPONS")
    }
  }

  async function getCuponsRestaurantSearch() {
    setLoading(true)
    let search = ""

    const restaurantId = user.id
    search += `&page=1`
    search += `&search=${searchCupon.toUpperCase()}`

    const response = await getCodes(restaurantId, search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as ResponseType[];

      if (code === 200) {
        setCoupons(data);
        setLoading(false);
        return;
      }
    } else {
      console.error("ERROR TO GET COUPONS")
    }
  }

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      getCuponsRestaurantSearch()
    }
  }, [searchCupon])

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
      getCuponsRestaurant()
    }
  }, [page, user])

  return (
    <Container>
      <ModalValidateCupon isOpen={openModal} onRequestClose={handleClose} />
      <AddCoupon onClick={handleOpen}>
        Validar Cupon
      </AddCoupon>

      <Show onClick={() => setShow(!show)}>
        {
          show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
        }
        <h2>{show ? "Esconder" : "Mostrar"}</h2>
      </Show>

      <Title>Cupons códigos</Title>

      <Input>
        <input type='text' id='search-code' name='seacrh-code' placeholder='Código do cupon' onChange={(event) => setSearchCupon(event.target.value)} />
      </Input>

      <CuponsList>
        {
          coupons.map(item => (
            <Coupon key={item.id}>
              <h1>{show ? item.code : "**********"}</h1>
              {
                item.used ? <CheckIcon /> : <CrossIcon className='not-used' />
              }
            </Coupon>
          ))
        }
        {
          loading ?
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
          )
          :
          (
            coupons.length / page < page * 20 ? null : 
            (
              <LoadMore onClick={() => setPage(page + 1)}>
                Carregar mais
              </LoadMore>
            )
          )
        }
      </CuponsList>

    </Container>
  )
}

export default Validate
