import React, { ReactNode } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'


export const theme: DefaultTheme = {
  colors: {
    background: '#F2F3F5',
    text: '#051C2C',
    primary: '#F8786B',
    primaryBG: '#ffd4cf',
    white: '#FFFFFF',
    black: '#000000',
    gray100: '#E9EBEC',
    gray200: '#DEE2E4',
    gray400: '#919CA2',
    lightGreen: '#00C3B7',
    lightGreenBG: '#cdfffc',
    lightBlue: '#01A4CD',
    yellow: '#e8f719',
    yellowBG: '#fbffc2',
  },
}

interface ThemeProps {
  children: ReactNode
}

export function Theme({ children }: ThemeProps) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
