import React, { ReactElement } from 'react';
import { Container, Logo } from './style';
import { ReactComponent as Image } from '../../assets/images/Black.svg';

function Menu({ children }: { children: ReactElement }) {
  return (
    <Container>
      <Logo href='/'>
        <Image />
      </Logo>
      {children}
    </Container>
  );
}

export default Menu;
