import styled from 'styled-components'
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
