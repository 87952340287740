import React from 'react'
import { Container, MenuInfo, OpInfo, Page } from './styles'
import { Outlet } from 'react-router-dom'

function Settings() {
  return (
    <Container>
      <MenuInfo>
        <OpInfo to="/restaurante/configuracoes/info">Informações</OpInfo>
        <OpInfo to="/restaurante/configuracoes/seguranca">Segurança</OpInfo>
      </MenuInfo>

      <Page>
        <Outlet />
      </Page>
    </Container>
  )
}

export default Settings
