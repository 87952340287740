import styled from 'styled-components'
import { NavLink } from 'react-router-dom';
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  width: 95%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 88px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;

  svg{
    height: 32px;
    width: 32px;

    circle, path{
      fill: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 32px;
    color: ${theme.colors.gray400};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const CartTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.header{
    width: 100%;
    display: flex;
    align-items: center;
    height: 22px;
    background-color: ${theme.colors.gray200};
    justify-content: flex-end;
    gap: 8px;

    h2{
      font-size: 16px;
      color: ${theme.colors.text};

      &.quantity{
        width: 16%;
      }
      &.price{
        width: 24%;
      }

      @media (max-width: 660px) {
        &.quantity{
          width: 28%;
        }
        &.price{
          width: 28%;
        }
      }
    }
  }

  div.product{
    width: 100%;
    display: flex;
    align-items: center;
    height: 128px;
    border-bottom: 1px solid ${theme.colors.gray200};
    gap: 8px;

    div.info{
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      img{
        height: 104px;
        width: 104px;
        object-fit: cover;
        object-position: center;
        margin-right: 12px;
      }

      div.text{
        display: flex;
        flex-direction: column;
        height: 104px;

        h2{
          font-size: 20px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.text};
          margin: 0;
          margin-bottom: 4px;
        }

        h4{
          font-size: 12px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.gray400};
          margin: 0;
          margin-bottom: 12px;
        }

        h3{
          font-size: 16px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.gray400};
          margin: 0;
        }
      }
    }

    div.quantity{
      display: flex;
      flex-direction: column;
      width: 16%;
      min-width: 16%;
      height: 104px;

      select{
        width: 64px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid ${theme.colors.gray400};
        color: ${theme.colors.text};
        font-size: 18px;
        text-align: center;
        background-color: transparent;
        margin-bottom: 8px;

        option{
          font-size: 14px;
          text-align: left;
        }
      }

      @media (max-width: 660px) {
        width: 28%;
        min-width: 28%;
      }


      h4{
        font-size: 12px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.gray400};
        margin: 0;
        cursor: pointer;
        text-decoration: underline;
        width: 64px;
        text-align: center;
      }
    }

    div.price{
      display: flex;
      flex-direction: column;
      width: 24%;
      min-width: 24%;
      height: 104px;

      h2{
        font-size: 18px;
        color: ${theme.colors.lightBlue};
        margin: 0;
        margin-bottom: 4px;
      }

      h3{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.lightBlue};
        margin: 0;
      }

      @media (max-width: 660px) {
        width: 28%;
        min-width: 28%;

        h2{
          font-size: 16px;
        }

        h3{
          font-size: 14px;
        }
      }
    }
  }

  div.footer{
    width: 100%;
    display: flex;
    min-height: 192px;
    height: auto;
    padding-bottom: 12px;
    background-color: ${theme.colors.gray200};
    justify-content: flex-end;
    gap: 8px;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom: 4px solid ${theme.colors.primary};
    padding-top: 12px;


    h2.items{
      font-size: 16px;
      color: ${theme.colors.text};
      width: 16%;
      margin: 0;

      span{
        font-family: "NeueMachinaLight";
        font-weight: 200;
      }
    }

    div.total{
      display: flex;
      flex-direction: column;
      width: 24%;

      h2{
        font-size: 18px;
        color: ${theme.colors.lightBlue};
        margin: 0;
        margin-bottom: 4px;
      }
      h3{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.lightBlue};
        margin: 0;
        margin-bottom: 16px;
      }

      @media (max-width: 660px) {
        width: 28%;
        min-width: 28%;

        h2{
          font-size: 16px;
        }

        h3{
          font-size: 14px;
        }
      }

      h4{
        font-size: 12px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.gray400};
        margin: 0;
        margin-bottom: 12px;
        width: 80%;
      }
    }

    @media (max-width: 660px) {
      h2.items{
          width: 28%;
      }
      div.total{
        width: 28%;
      }
    }
  }

`;

export const Product = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;

  img{
    height: 104px;
    width: 104px;
    object-fit: cover;
    object-position: center;
    margin-right: 12px;

    @media (max-width: 768px) {
      width: 72px;
      height: 72px;
    }

    @media (max-width: 425px) {
      width: 48px;
      height: 48px;
    }

    @media (max-width: 400px) {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
  }

  div.text{
    display: flex;
    flex-direction: column;
    height: 104px;

    h2{
      font-size: 20px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      margin: 0;
      margin-bottom: 4px;
    }

    h4{
      font-size: 12px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.gray400};
      margin: 0;
      margin-bottom: 12px;
    }

    h3{
      font-size: 16px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.gray400};
      margin: 0;
    }

    @media (max-width: 768px) {
      h2{
        font-size: 18px;
      }
      h4{
        font-size: 12px;
      }
      h4{
        font-size: 14px;
      }
    }
    @media (max-width: 768px) {
      h2{
        font-size: 16px;
      }
      h4{
        display: none;
      }
      h4{
        font-size: 12px;
      }
    }
  }
`;

export const ButtonBuy = styled(NavLink)`
  height: 40px;
  min-height: 40px;
  width: calc(100% - 12px);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.lightGreen};
  transition: all .4s;
  font-size: 16px;
  color: ${theme.colors.white};
  cursor: pointer;
  padding-top: 2px;
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.lightBlue};
  }
  &.block {
    opacity: .6;
    cursor: not-allowed;
  }
`;
