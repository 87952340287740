import styled from "styled-components";
import { theme } from "../../../styles/Theme";
import { NavLink } from "react-router-dom";

export const Container = styled.section`
  width: calc(100vw - 241px - 24px);
  min-height: calc(100vh - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin-right: -24px;
  margin-bottom: -24px;
`;

export const MenuInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.gray200};
  position: relative;
  margin-bottom: 48px;
`;

export const OpInfo = styled(NavLink)`
  width: 192px;
  height: 24px;
  font-size: 16px;
  padding-bottom: 16px;
  color: ${theme.colors.gray400};
  font-family: "NeueMachinaLight";
  font-weight: 200;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.active{
    color: ${theme.colors.text};
    border-bottom: 2px solid ${theme.colors.text};
  }
`;

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
