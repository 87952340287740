import React, { useEffect, useState } from 'react'
import { Container, List, LoadMore, Cupon, Title, Input } from './styles'

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../../styles/Theme';
import { getCuponsList } from '../../../services/api';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from "../../../assets/icons/gallery-edit.svg";
import ModalEditCuponAdmin from '../../../components/ModalEditCuponAdmin';

type restaurantItem = {
  id: number
  restaurant_id: number
  plate_name: string
  picture: string
  category: string
  price_purchase: number
  restaurant_name: string
  quantity_total: number
  quantity_purchase: number
}

function Coupons() {

  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [restaurants, setrestaurants] = useState<restaurantItem[]>([])
  const [searchCupon, setSearchCupon] = useState<string>("")

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [idCupon, setIdCupon] = useState<number>(0)

  const handleOpen = (id: number) => {
    if(!id) {
      return
    }
    setIdCupon(id)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const URL = process.env.REACT_APP_URL_AWS_S3


  async function getRestaurants() {
    setLoading(true)
    let search = ""
    search += `&page=${page}&search=${searchCupon}`

    const response = await getCuponsList(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as restaurantItem[];

      if (code === 200) {
        setrestaurants([...restaurants, ...data]);
        setLoading(false);
        return;
      }
    } else {
      toast.error("ERROR AO OBTER CUPONS")
    }
  }

  async function getRestaurantsSearch() {
    setLoading(true)
    let search = ""
    search += `&page=1&search=${searchCupon}`

    const response = await getCuponsList(search)

    if (Array.isArray(response) && response.length >= 2) {
      const code = response[0] as number;
      const data = response[1] as restaurantItem[];


      if (code === 200) {
        setrestaurants(data);
        setLoading(false);
        return;
      }
    } else {
      toast.error("ERROR AO OBTER CUPONS")
    }
  }

  useEffect(() => {
    getRestaurants()
  }, [page])

  useEffect(() => {
    getRestaurantsSearch()
  }, [searchCupon])


  return (
    <Container>
      <ModalEditCuponAdmin isOpen={isOpen} onRequestClose={handleClose} cuponId={idCupon} />
      <Title>Cupons</Title>

      <Input>
        <input type='text' id='search-cupon' name='search-cupon' placeholder='Procurar cupon' onChange={(event) => setSearchCupon(event.target.value)} />
      </Input>

      <List>
      {
        restaurants.map((item) => (
          <Cupon key={item.id}>
            <EditIcon className="edit" onClick={() => handleOpen(item.id)} />
            <img src={`${URL}/${item.picture}`} alt={item.plate_name} />
            <div className="text">
              <h1>{item.plate_name}</h1>
              <h2>ID: {item.id}</h2>
              <h2>Categoria: {item.category}</h2>
              <h2>Restaurante: {item.restaurant_name}</h2>
              <h2>Preço: R$ {String(item.price_purchase.toFixed(2)).replace(".", ",")}</h2>
              <h2 style={{marginTop: "8px"}}>Quantidade de Vendas: {item.quantity_purchase}</h2>
              <h2>Quantidade Total: {item.quantity_total}</h2>
            </div>
          </Cupon>
        ))
      }

      {
          loading ?
          (
            <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
          )
          :
          (
            <LoadMore onClick={() => setPage(page + 1)}>
              Carregar mais
            </LoadMore>
          )
        }
      </List>


    </Container>
  )
}

export default Coupons
