import styled from "styled-components";
import { theme } from "../../styles/Theme";
import { NavLink } from "react-router-dom";

export const Container = styled.main`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  font-size: 20px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
`;

export const Options = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
`;

export const LinkMenu = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  min-height: 32px;
  border-radius: 8px;

  h4{
    font-size: 18px;
    font-weight: 200;
    font-family: "NeueMachinaLight";
    color: ${theme.colors.black};
    margin: 0;
    margin-bottom: -4px;
  }

  svg.icon{
    width: 24px;
    height: 24px;
    margin: 0 12px;

    path{
      stroke: ${theme.colors.black};
    }
  }

  svg.icon-2{
    width: 24px;
    height: 24px;
    margin: 0 12px;

    path{
      fill: ${theme.colors.black};
    }
  }

  &.active{
    background-color: ${theme.colors.black};
    h4{
      color: ${theme.colors.white};
    }
    svg.icon path{
      stroke: ${theme.colors.white};
    }

    svg.icon-2 path{
      fill: ${theme.colors.white};
    }
  }
`;

export const LogOut = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  min-height: 32px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 16px;

  h4{
    font-size: 18px;
    font-weight: 200;
    font-family: "NeueMachinaLight";
    color: ${theme.colors.primary};
    margin: 0;
    margin-bottom: -4px;
  }

  svg.icon{
    width: 24px;
    height: 24px;
    margin: 0 12px;

    path{
      stroke: ${theme.colors.primary};
    }
  }
`;

export const Page = styled.section`
  width: 100%;
  max-width: calc(100vw - 241px - 48px);
  min-height: calc(100vh - 48px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  overflow-y: auto;
`;

export const Input = styled.div`
  width: calc(100vw - 241px - 48px);
  height: 40px;
  min-height: 40px;
  border-radius: 8px;
  background-color: ${theme.colors.white};
  margin: 16px 0;
  display: flex;
  align-items: center;

  input{
    width: 98%;
    margin-left: 8px;
    height: 32px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.black};

    &::placeholder{
      color: ${theme.colors.gray200};
    }
  }
`;
