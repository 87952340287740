import React, { useEffect, useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Container, LinkMenu, LogOut, Options, Page } from './style'
import { LoginContext } from '../../contexts/LoginContext'
import Menu from '../../components/Menu'

import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg"
import { ReactComponent as CategoriesIcon } from "../../assets/icons/settings.svg"
import { ReactComponent as CouponsIcon } from "../../assets/icons/coupon.svg"
import { ReactComponent as MealIcon } from "../../assets/icons/meal.svg"
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg"
import { ReactComponent as PromotionIcon } from "../../assets/icons/promotion.svg"
import { ReactComponent as LogOutIcon } from "../../assets/icons/logout.svg"

function Admin() {

  const { user, logout } = useContext(LoginContext)
  const navigate = useNavigate()

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("cupozin-auth") || '{}')
    if(user && user?.token || data && data?.token) {
      if(data?.token.split("###")[0] === process.env.REACT_APP_ADMIN_TOKEN || user?.token.split("###")[0] === process.env.REACT_APP_ADMIN_TOKEN) {
        return
      } else {
        navigate("/")
      }
    }
  }, [])

  return (
    <Container>
      <Menu>
        <Options>
          <LinkMenu to="/admin/home">
            <HomeIcon className='icon'/>
            <h4>Home</h4>
          </LinkMenu>

          <LinkMenu to="/admin/categorias">
            <CategoriesIcon className='icon'/>
            <h4>Categorias</h4>
          </LinkMenu>

          <LinkMenu to="/admin/cupons">
            <CouponsIcon className='icon-2'/>
            <h4>Cupons</h4>
          </LinkMenu>

          <LinkMenu to="/admin/restaurantes">
            <MealIcon className='icon-2'/>
            <h4>Restaurantes</h4>
          </LinkMenu>

          <LinkMenu to="/admin/publicidade">
            <PromotionIcon className='icon-2'/>
            <h4>Publicidade</h4>
          </LinkMenu>

          <LinkMenu to="/admin/seguranca">
            <LockIcon className='icon'/>
            <h4>Segurança</h4>
          </LinkMenu>

          <LogOut onClick={() => logout()}>
            <LogOutIcon className='icon' />
            <h4>Sair</h4>
          </LogOut>

        </Options>
      </Menu>

      <Page>
        <Outlet />
      </Page>
    </Container>
  )
}

export default Admin
