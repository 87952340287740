import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { Button, Container, Content, Form, Layout, Preview } from './styles'
import "./style.css";

import { getCategory, updateCategory, uploadfiles } from '../../services/api';


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { toast } from 'react-toastify';

function ModalEditCategory({ isOpen, onRequestClose, idCupon }) {

  const [info, setInfo] = useState("⠀")
  const [name, setName] = useState("")
  const [image, setImage] = useState()
  const [imagePreview, setImagePreview] = useState()
  const [loading, setLoading] = useState()
  const [picture, setPicture] = useState()

  const URL = process.env.REACT_APP_URL_AWS_S3

  useEffect(() => {
    if(!image || image && image.length === 0){
      return
    }

    const file = image[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64 = reader.result
      setImagePreview(base64)
    }

    reader.readAsDataURL(file)
  }, [image])

  useEffect(() => {
    setImagePreview(false)
  }, [isOpen])

  async function getCategoryData() {
    setLoading(true)
    const [code, response] = await getCategory(idCupon)

    if(code === 200) {
      setName(response.name)
      setPicture(response.picture)
      setLoading(false)
      return
    }

    toast.error("Error ao obter categoria")
    setLoading(false)
    onRequestClose()
    return
  }

  useEffect(() => {
    if(idCupon) {
      getCategoryData()
    }
  }, [idCupon, isOpen])

  const send = async () => {
    if (!name){
      setInfo("Preecha todos os campos")
      return
    }

    if(image || image && image.length === 0) {
      setInfo("⠀")
      setLoading(true)

      const formData = new FormData();
      formData.append("file", image[0])

      const [code, response] = await uploadfiles(formData);

      if(code === 201) {

        const status = await updateCategory({ name, picture: response.path, previous_file: picture}, idCupon)
        if (status === 200) {
          setInfo("CATEGORIA ATUALIZADA")
          toast.success("Categoria atualizada")
          setName("")
          setLoading(false)
          onRequestClose()
          return
        }

        if (status === 400) {
          setInfo("Erro ao adicionar a categoria")
          toast.error("Erro ao adicionar a categoria")
          setLoading(false)
          return
        }

        setInfo(`${status} ERROR`)
        setLoading(false)
        return
      }
    }

    setInfo("⠀")
    setLoading(true)

    const status = await updateCategory({ name }, idCupon)
    if (status === 200) {
      setInfo("CATEGORIA ATUALIZADA")
      toast.success("Categoria atualizada")
      setName("")
      setLoading(false)
      onRequestClose()
      return
    }

    if (status === 400) {
      setInfo("Erro ao adicionar a categoria")
      toast.error("Erro ao adicionar a categoria")
      setLoading(false)
      return
    }

    setInfo(`${status} ERROR`)
    toast.error(`${status} ERROR`)
    setLoading(false)
    return
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Editar Categoria</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <h4 style={{marginTop: "28px"}}>Texto</h4>
              <input value={name} type="text" name="category" id="category" max={255} onChange={(event) => setName(event.target.value)} />

              <Button onClick={send}>
                Enviar
              </Button>
            </Form>

            <Preview>
              <h4>Pré-visualização</h4>

              <Layout>
                <img src={imagePreview ? imagePreview : `${URL}/${picture}`} alt={name} />
                <span>{name ? name : "⠀"}</span>
              </Layout>
            </Preview>
          </Content>
        )
      }

    </Container>
  )
}

ModalEditCategory.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  idCupon: PropTypes.number.isRequired,
};

export default ModalEditCategory
