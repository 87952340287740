import React from 'react'
import { Container, Content, Text, Title } from './style';
import Header from '../../components/Header';

import { ReactComponent as Icon } from '../../assets/icons/hat-chef.svg';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';


function Privacy() {

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <Icon />
          <h2>Políticas de Privacidade</h2>
        </Title>

        <Text>
          Esta Política de Privacidade descreve como a [Nome da Empresa] coleta, usa e protege as informações pessoais dos usuários da nossa plataforma de cupons de desconto em pratos de restaurantes.
          <br/><br/>
          <span>Informações Coletadas</span>
          <br/><br/>
          Informações de Registro: Podemos coletar informações pessoais, como nome, endereço de e-mail e informações de contato, quando você se registra em nossa plataforma ou interage conosco.
          <br/><br/>
          Uso de Serviços: Podemos registrar informações sobre como você utiliza nossos serviços, incluindo detalhes sobre os cupons utilizados, restaurantes visualizados e outras atividades na plataforma.
          <br/><br/>
          Cookies e Tecnologias Semelhantes: Podemos utilizar cookies e outras tecnologias de rastreamento para melhorar a experiência do usuário e coletar informações sobre o uso da plataforma.
          <br/><br/><br/><br/>
          <span>Uso das Informações</span>
          <br/><br/>
          Fornecimento de Serviços: Utilizamos as informações coletadas para fornecer e personalizar nossos serviços, incluindo a disponibilização de cupons de desconto e melhorias na experiência do usuário.
          <br/><br/>
          Comunicações: Podemos utilizar suas informações para enviar comunicações relacionadas aos serviços oferecidos, como atualizações, novidades ou informações sobre ofertas especiais.
          <br/><br/>
          Análises e Melhorias: Utilizamos dados coletados para análises internas, como entender padrões de uso, melhorar nossos serviços, desenvolver novos recursos e garantir a segurança da plataforma.
          <br/><br/><br/><br/>
          <span>Compartilhamento de Informações</span>
          <br/><br/>
          Parceiros e Terceiros: Podemos compartilhar informações com restaurantes parceiros para facilitar o uso dos cupons de desconto. No entanto, não vendemos, alugamos ou divulgamos informações pessoais a terceiros para fins de marketing sem seu consentimento.
          <br/><br/><br/><br/>
          <span>Segurança das Informações</span>
          <br/><br/>
          Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, uso indevido, alteração ou divulgação.
          <br/><br/><br/><br/>
          <span>Seus Direitos</span>
          <br/><br/>
          Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Se desejar exercer esses direitos ou tiver dúvidas sobre o processamento de suas informações, entre em contato conosco.
          <br/><br/><br/><br/>
          <span>Alterações na Política de Privacidade</span>
          <br/><br/>
          Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente. Quaisquer alterações entrarão em vigor após a publicação na plataforma.
          Ao utilizar nossa plataforma, você concorda com esta Política de Privacidade. Se tiver dúvidas ou preocupações, entre em contato conosco através dos canais fornecidos.
        </Text>



      </Content>
      <Footer />
    </Container>
  )
}

export default Privacy
