import React, { useContext, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { AddToCart, Button, Container, Content, ContentItem, Form, InputWithMask, Item, Preview } from './styles'
import "./style.css";

import placeholder from "../../assets/images/placeholder.webp"
import {ReactComponent as MinusIcon} from "../../assets/icons/minus.svg"
import {ReactComponent as PlusIcon} from "../../assets/icons/plus.svg"
import {ReactComponent as LikeIcon} from "../../assets/icons/heart.svg"
import {ReactComponent as CartIcon} from "../../assets/icons/shopping-cart-add.svg"


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { createCupons, getCategories, uploadfiles } from '../../services/api';
import { LoginContext } from '../../contexts/LoginContext';
import { toast } from 'react-toastify';

function ModalAddCupon({ isOpen, onRequestClose }) {

  const { user } = useContext(LoginContext);

  const [info, setInfo] = useState("⠀")
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [priceOf, setPriceOf] = useState("")
  const [pricePurchase, setPricePurchase] = useState("")
  const [quantity, setQuantity] = useState(1)
  const [image, setImage] = useState()
  const [imagePreview, setImagePreview] = useState()
  const [category, setCategory] = useState()

  const [categories, setCategories] = useState([])

  const [loading, setLoading] = useState()

  useEffect(() => {
    setImagePreview(false)
  }, [isOpen])

  useEffect(() => {
    if(!image || image && image.length === 0){
      return
    }

    const file = image[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64 = reader.result
      setImagePreview(base64)
    }

    reader.readAsDataURL(file)
  }, [image])

  const getCategory = async () => {
    setLoading(true)
    const [code, response] = await getCategories()

    if(code === 200) {
      setCategories(response)
      setLoading(false)
      return
    }
    toast.error("Error ao obter categorias")
    setInfo("ERROR ao obter categorias")
    setLoading(false)
  }

  useEffect(() => { getCategory() }, [])

  const send = async () => {
    if (!name || !image || image && image.length === 0 || !priceOf || !pricePurchase || !category || !quantity || !description){
      setInfo("Preecha todos os campos")
      return
    }
    if(!user && !user?.id){
      toast.warn("Não foi possível criar o cupon, tente novamente mais tarde")
      return
    }
    if (!quantity || quantity <= 0) {
      toast.warn("Error: Coloque uma quantidade mínima")
      return
    }
    if (quantity > 500) {
      toast.warn("Quantidade máxima: 500")
      return
    }
    setInfo("⠀")
    setLoading(true)

    const formData = new FormData();
    formData.append("file", image[0])

    const [code, response] = await uploadfiles(formData);

    if(code === 201) {

      const data = {
        plate_name: name,
        picture: response.path,
        category,
        price_of: priceOf,
        price_purchase: pricePurchase,
        quantity,
        restaurant_id: user.id,
        description
      }

      const status = await createCupons(data);

      if(status === 201) {
        toast.success("Cupons adicionados")
        setName("")
        setCategory("")
        setPriceOf("")
        setPricePurchase("")
        setQuantity(1)
        setLoading(false)
        onRequestClose();
        return
      }

      if(status === 400) {
        setInfo("Erro ao criar cupons")
        toast.error("Erro ao criar cupons")
        setLoading(false)
        return
      }

      toast.error(`${status} ERROR`)
      setInfo(`${status} ERROR`)
      setLoading(false)
      return
    }

    toast.error(`${code} ERROR`)
    setInfo(`${code} ERROR`)
    setLoading(false)
  }

  const minusQuantity = () => {
    if(quantity <= 1){
      return
    }
    const n = Number(quantity) - 1
    setQuantity(n)
  }
  const plusQuantity = () => {
    const n = Number(quantity) + 1
    setQuantity(n)
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Adicionar Cupon</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <h4 style={{marginTop: "28px"}}>Nome do Prato</h4>
              <input type="text" name="plate-name" id="plate-name"  maxLength={255} onChange={(event) => setName(event.target.value)} />

              <div style={{marginTop: "28px"}} className="line">
                <div className="input">
                  <h4>De</h4>
                  <InputWithMask mask="R$ 999,99" type="text" name="price-of" id="price-of" maxLength={255} onChange={(event) => setPriceOf(Number(event.target.value.replace("_", "0").replace("R$ ", "").replace(",", ".")))} />
                </div>

                <div className="input">
                  <h4>Por</h4>
                  <InputWithMask mask="R$ 999,99" type="text" name="price-purchase" id="price-purchase" maxLength={255} onChange={(event) => setPricePurchase(Number(event.target.value.replace("_", "0").replace("R$ ", "").replace(",", ".")))} />
                </div>
              </div>

              <h4 style={{marginTop: "28px"}}>Categoria</h4>
              <select defaultValue="" onChange={(event) => setCategory(event.target.value)}>
                <option value="">Selecione</option>
                {
                  categories.map(item => <option key={item.id} value={item.name}>{item.name}</option>)
                }
                <option value="Outro">Outro</option>
              </select>

              <h4 style={{marginTop: "28px"}}>Descrição do Prato</h4>
              <textarea onChange={(event) => setDescription(event.target.value)} id="description" name='description'/>

              <h4 style={{marginTop: "28px"}}>Quantidade</h4>
              <div className="quantity">
                <MinusIcon
                  className={quantity <= 1 ? "block" : ""}
                  onClick={quantity <= 1 ? null : minusQuantity}
                />

                <input type='number' min={1} max={500} value={quantity} onChange={(event) => setQuantity(event.target.value)} />

                <PlusIcon
                  className={quantity >= 500 ? "block" : ""}
                  onClick={quantity >= 500 ? null : plusQuantity} />
              </div>



              <Button onClick={send}>
                Enviar
              </Button>
            </Form>

            <Preview>
              <h4>Pré-visualização</h4>

              <Item>
                <LikeIcon className='like' />
                <ContentItem>
                  <img src={imagePreview ? imagePreview : placeholder} alt="Placeholder" />
                  <div className="title">
                    <span className='plate'>{name ? name : "⠀"}</span>
                    <div className="discount">{ priceOf && pricePurchase ? String(((pricePurchase * 100) / priceOf - 100).toFixed(0)).replace("-", "") : "0"}%</div>
                  </div>
                  <div className="priceInfo">
                    <div className="info border">
                      <span>Economize</span>
                      <h3>R$ {priceOf && pricePurchase ? String((priceOf - pricePurchase).toFixed(2)).replace(".", ",") : "0,00"}</h3>
                    </div>

                    <div className="info">
                      <span>De R${priceOf ? priceOf.toFixed(0) : 0} por</span>
                      <h3>R$ {pricePurchase ? String(pricePurchase.toFixed(2)).replace(".", ",") : "0,00"}</h3>
                    </div>
                  </div>
                </ContentItem>
                <AddToCart>
                  <span>Comprar</span>
                  <CartIcon />
                </AddToCart>
              </Item>
            </Preview>
          </Content>
        )
      }

    </Container>
  )
}

ModalAddCupon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalAddCupon
