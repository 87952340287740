import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { Button, Container, Content, Form, Layout, Preview } from './styles'
import "./style.css";

import placeholder from "../../assets/images/placeholder.webp"
import { createCategory, uploadfiles } from '../../services/api';


import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';
import { toast } from 'react-toastify';

function ModalAddCategory({ isOpen, onRequestClose }) {

  const [info, setInfo] = useState("⠀")
  const [name, setName] = useState("")
  const [image, setImage] = useState()
  const [imagePreview, setImagePreview] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    setImagePreview(false)
  }, [isOpen])

  useEffect(() => {
    if(!image || image && image.length === 0){
      return
    }

    const file = image[0]

    const reader = new FileReader()

    reader.onloadend = () => {
      const base64 = reader.result
      setImagePreview(base64)
    }

    reader.readAsDataURL(file)
  }, [image])

  const send = async () => {
    if (!name || !image || image && image.length === 0){
      setInfo("Preecha todos os campos")
      return
    }
    setInfo("⠀")
    setLoading(true)

    const formData = new FormData();
    formData.append("file", image[0])


    const [code, response] = await uploadfiles(formData);

    if(code === 201) {

      const status = await createCategory({ name, picture: response.path })
      if (status === 201) {
        setInfo("CATEGORIA ADICIONADA")
        toast.success("Categoria adicionada")
        setName("")
        setLoading(false)
        onRequestClose()
        return
      }

      if (status === 400) {
        setInfo("Erro ao adicionar a categoria")
        toast.error("Erro ao adicionar a categoria")
        setLoading(false)
        return
      }

      setInfo(`${status} ERROR`)
      toast.error(`${status} ERROR`)
      setLoading(false)
      return
    }

    setInfo(`${code} ERROR`)
    toast.error(`${code} ERROR`)
    setLoading(false)
  }

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >

      <h2>Adicionar nova Categoria</h2>
      <span>{info}</span>

      {
        loading ?
        (
          <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.black} />
        )
        :
        (
          <Content>
            <Form>
              <h4>Imagem</h4>
              <input type="file" onChange={(event) => setImage(event.target.files)} accept="image/*" maxLength="5000000" />

              <h4 style={{marginTop: "28px"}}>Texto</h4>
              <input type="text" name="category" id="category" max={255} onChange={(event) => setName(event.target.value)} />

              <Button onClick={send}>
                Enviar
              </Button>
            </Form>

            <Preview>
              <h4>Pré-visualização</h4>

              <Layout>
                <img src={imagePreview ? imagePreview : placeholder} alt="Placeholder" />
                <span>{name ? name : "⠀"}</span>
              </Layout>
            </Preview>
          </Content>
        )
      }

    </Container>
  )
}

ModalAddCategory.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalAddCategory
