import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Container = styled.main`
  width: 100%;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  width: 90%;
  max-width: 400px;
  height: auto;
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 4px;

  svg.logo{
    height: 24px;
    width: auto;
    margin: 24px 0;

    path, rect, polygon {
      fill: ${theme.colors.primary};
    }
  }

  h1{
    font-size: 20px;
    color: ${theme.colors.text};
    margin: 0;
    width: 90%;
    text-align: center;
  }

  h3.top{
    font-size: 16px;
    color: ${theme.colors.gray400};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin: 0;
    width: 90%;
    text-align: center;

    span {
      color: ${theme.colors.text};
    }
  }
`;

export const Field = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  h3{
    font-size: 12px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.gray400};
    margin: 0;
    margin-bottom: 2px;
  }
`;

export const Input = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: ${theme.colors.gray100};
  display: flex;
  align-items: center;
  justify-content: space-between;

  input{
    width: 95%;
    margin-left: 8px;
    height: 32px;
    border: none;
    outline: none;
    background-color: transparent;
    color: ${theme.colors.text};
    font-size: 16px;
    font-family: "NeueMachinaLight";
    font-weight: 200;

    &::placeholder{
      color: ${theme.colors.gray400};
    }

    &:focus, &:active {
      outline: none;
    }
  }

  svg{
    height: 28px;
    width: 28px;
    min-width: 28px;
    cursor: pointer;
    margin: 0 8px;

    path {
      fill: ${theme.colors.text};
    }

  }
`;

export const Resend = styled.h2`
  font-size: 16px;
  font-family: "NeueMachinaLight";
  font-weight: 200;
  color: ${theme.colors.gray400};
  margin: 0;
  cursor: pointer;
  margin-top: 24px;
`;

export const Button = styled.button`
  width: 90%;
  height: 40px;
  border-radius: 4px;
  border: none;
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.white};
  font-size: 18px;
  font-family: "NeueMachinaLight";
  font-weight: 400;
  transition: all .4s;
  margin: 24px 0;
  cursor: pointer;

  &:hover{
    background-color: ${theme.colors.lightBlue};
  }
`;
