import styled from 'styled-components'
import InputMask from "react-input-mask";
import { theme } from '../../styles/Theme';


export const Container = styled.main`
  width: 100%;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pay-button{
    width: 100%;
    height: 40px;
    margin-top: 24px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    background-color: ${theme.colors.lightGreen};
    color: ${theme.colors.white};
  }

  .button-container-2XwAbq, .svelte-1bb0x5s{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

export const Content = styled.section`
  width: 95%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
  margin-bottom: 88px;
`;

export const MenuRestaurants = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 32px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid ${theme.colors.gray200};
  margin-bottom: 24px;
  height: 48px;

  div.op{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 0 24px;
    color: ${theme.colors.gray400};
    height: 48px;
    cursor: pointer;

    span{
      font-size: 18px;
      font-weight: 400;
    }

    border-bottom: 2px solid transparent;

    &.active{
      color: ${theme.colors.text};
      border-bottom: 2px solid ${theme.colors.primary};
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray400};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;

  svg{
    height: 32px;
    width: 32px;

    path{
      stroke: ${theme.colors.gray400};
    }
  }

  h2{
    font-size: 32px;
    color: ${theme.colors.gray400};
    margin: 0;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    margin-bottom: -4px;
  }
`;

export const PaymentContent = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 512px;
  gap: 24px;

  div.line{
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const Field = styled.div`
  width: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  h3{
    font-size: 12px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
  }

  div.field{
    width: 100%;
    height: 40px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    display: flex;
    align-items: center;

    input{
      width: 95%;
      height: 32px;
      border: none;
      outline: none;
      background-color: transparent;
      border-radius: 4px;
      margin-left: 8px;
      font-size: 16px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};

      &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
      }

      &::placeholder{
        color: ${theme.colors.gray200};
      }

      &:focus, &:active {
        outline: none;
      }

    }
  }
`;

export const FieldCpf = styled.div`
  width: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  h3{
    font-size: 12px;
    font-family: "NeueMachinaLight";
    font-weight: 200;
    color: ${theme.colors.text};
    margin: 0;
  }

  div.field{
    width: 100%;
    height: 40px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    select{
      width: 30%;
      height: 32px;
      border: none;
      outline: none;
      background-color: transparent;
      border-radius: 4px;
      margin-left: 8px;
      font-size: 14px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.text};
      text-align: center;

      option{
        font-size: 14px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.text};
      }
    }
  }
`;

export const InputWithMask = styled(InputMask)`
  width: 95%;
  height: 32px;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 16px;
  font-family: "NeueMachinaLight";
  font-weight: 200;
  color: ${theme.colors.text};

  &:disabled{
    opacity: 0.6;
    cursor: not-allowed;
  }

  &::placeholder{
    color: ${theme.colors.gray200};
  }

  &:focus, &:active {
    outline: none;
  }
`;

export const InputWithMaskCpf = styled(InputMask)`
  width: 70%;
  height: 32px;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 16px;
  font-family: "NeueMachinaLight";
  font-weight: 200;
  color: ${theme.colors.text};

  &:disabled{
    opacity: 0.6;
    cursor: not-allowed;
  }

  &::placeholder{
    color: ${theme.colors.gray200};
  }

  &:focus, &:active {
    outline: none;
  }
`;

export const Products = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 256px;
  gap: 16px;

  div.list-products{
    width: 100%;
    display: flex;
    flex-direction: column;

    a{
      width: 100%;
      height: 92px;
      border-bottom: 1px solid ${theme.colors.gray200};
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 8px;

      img{
        height: 72px;
        width: 72px;
        min-width: 72px;
        object-fit: cover;
        object-position: center;
      }

      div.text{
        display: flex;
        flex-direction: column;
        height: 72px;

        h1{
          font-size: 16px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.text};
          margin: 0;
          margin-bottom: 8px;
        }

        h2{
          font-size: 14px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.gray400};
          margin: 0;
          margin-bottom: 2px;
        }
        h3{
          font-size: 12px;
          font-family: "NeueMachinaLight";
          font-weight: 200;
          color: ${theme.colors.gray400};
          margin: 0;
        }
      }

    }
  }

  div.total{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    h2{
      font-size: 16px;
      font-family: "NeueMachinaLight";
      font-weight: 200;
      color: ${theme.colors.lightBlue};
      margin: 0;
    }

    h1{
      font-size: 20px;
      font-family: "NeueMachina";
      color: ${theme.colors.lightBlue};
      margin: 0;
    }

  }

`;

export const ButtonBuy = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.lightGreen};
  transition: all .4s;
  font-size: 16px;
  color: ${theme.colors.white};
  cursor: pointer;
  padding-top: 2px;
  text-decoration: none;

  &:hover {
    background-color: ${theme.colors.lightBlue};
  }
  &.block {
    opacity: .6;
    cursor: not-allowed;
  }
`;
