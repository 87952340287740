import React, { useEffect, useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Container, LinkMenu, LogOut, Options, Page } from './style'
import { LoginContext } from '../../contexts/LoginContext'
import Menu from '../../components/Menu'

import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg"
import { ReactComponent as CouponsIcon } from "../../assets/icons/coupon.svg"
import { ReactComponent as LogOutIcon } from "../../assets/icons/logout.svg"
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg"
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-2.svg"

function Admin() {

  const { user, logout } = useContext(LoginContext)
  const navigate = useNavigate()

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("cupozin-auth") || '{}')
    if(user && user?.token || data && data?.token) {
      if(data?.token.split("###")[0] === process.env.REACT_APP_RESTAURANT_TOKEN || user?.token === process.env.REACT_APP_RESTAURANT_TOKEN) {
        return
      } else {
        navigate("/")
      }
    }
  }, [])

  return (
    <Container>
      <Menu>
        <Options>
          <LinkMenu to="/restaurante/home">
            <HomeIcon className='icon'/>
            <h4>Home</h4>
          </LinkMenu>

          <LinkMenu to="/restaurante/cupons">
            <CouponsIcon className='icon-2'/>
            <h4>Cupons</h4>
          </LinkMenu>

          <LinkMenu to="/restaurante/validar_cupons">
            <CheckIcon className='icon'/>
            <h4>Validar</h4>
          </LinkMenu>

          <LinkMenu to="/restaurante/configuracoes">
            <SettingsIcon className='icon'/>
            <h4>Configurações</h4>
          </LinkMenu>

          <LogOut onClick={() => logout()}>
            <LogOutIcon className='icon' />
            <h4>Sair</h4>
          </LogOut>

        </Options>
      </Menu>

      <Page>
        <Outlet />
      </Page>
    </Container>
  )
}

export default Admin
