import { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, NavigationDiv } from './styles';
import ItemLayout from '../../../components/ItemLayout';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';

import 'swiper/css';
import 'swiper/css/bundle';
import ItemLayoutLoad from '../../../components/ItemLayoutLoad';
import { getCupons } from '../../../services/api';


function Carousel({ filter }) {

  const [slides, setSlides] = useState(window.innerWidth / 284)
  const [loading, setLoading] = useState(false)
  const [cupons, setCupons] = useState([])

  const next = useRef()
  const prev = useRef()
  const swiperRef = useRef(null);

  useEffect(() => {
    function handleSize () {
      const calc = window.innerWidth / 284
      setSlides(calc)
    }

    window.addEventListener("resize", handleSize)

    return (() => {
      window.removeEventListener("resize", handleSize)
    })

  }, [])

  useEffect(() => {
    async function getHighlights() {
      setLoading(true)
      const search = filter
      const [status, response] = await getCupons(search);

      if(status === 200){
        setCupons(response)
        setLoading(false)
        return
      }
      setLoading(false)
    }
    getHighlights()
  }, [])


  return (
    <Container>
      <Swiper
        className="content"
        spaceBetween={28}
        slidesPerView={slides}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        navigation={{
          nextEl: next,
          prevEl: prev
        }}
        autoplay
        pagination={{ clickable: true }}
      >

        {
          loading ? (
            <>
              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>

              <SwiperSlide className='slider'>
                <ItemLayoutLoad />
              </SwiperSlide>
            </>
          )
          :
          (
            cupons.map((item) => (
              <SwiperSlide key={item.id} className='slider'>
                <ItemLayout
                  id={item.id}
                  name={item.plate_name}
                  picture={item.picture}
                  priceOf={Number(item.price_of)}
                  pricePurchase={Number(item.price_purchase)}
                />
              </SwiperSlide>
            ))
          )
        }
      </Swiper>

      <NavigationDiv>
        <ArrowLeftIcon
          ref={prev}
          // className={swiperRef.current && swiperRef.current.isBeginning ? 'disable' : ''}
          onClick={() => swiperRef.current.slidePrev()} />

        <ArrowRightIcon
          ref={next}
          // className={swiperRef.current && swiperRef.current.isEnd ? 'disable' : ''}
          onClick={() => swiperRef.current.slideNext()} />
      </NavigationDiv>
    </Container>
  );
}

Carousel.propTypes = {
  filter: PropTypes.string,
}

PropTypes.defaultValues = {
  filter: ""
}

export default Carousel;
