import React, {useEffect, useState, useContext} from 'react'
import { LoginContext } from '../../contexts/LoginContext';

import { Container, Content, Coupon, Title } from './style';
import Header from '../../components/Header';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

import { ReactComponent as CuponIcon } from '../../assets/icons/coupon.svg';
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import { CiLocationOn } from "react-icons/ci"

import { cuponsUser } from '../../services/api';
import { toast } from 'react-toastify';

type cupons = {
  id: number
  cupon_id: number
  picture: string
  plate_name: string
  restaurant_id: number
  restaurant_name: string
  street: string
  number: number
  neighborhood: string
  postal_code: string

  codes: [{
    code: string
    used: boolean
  }]
}

function CuponsUser() {

  const { user } = useContext(LoginContext)
  const [cupons, setCupons] = useState<cupons[]>([])
  const [show, setShow] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)


  const URL = process.env.REACT_APP_URL_AWS_S3

  const showOrHide = (id: number) => {
    if(show.indexOf(id) === -1){
      setShow([...show, id])
      return
    }
    setShow(show.filter(idShow => idShow !== id))
  }

  async function getCupons() {
    setLoading(true)
    const result = await cuponsUser(user.id)

    if (Array.isArray(result) && result.length >= 2) {
      const code = result[0] as number;
      const response = result[1] as cupons[];


      if(code === 200) {
        setCupons(response)
        setLoading(false)
        return
      }
      toast.error("Error ao carregar cupons")
      setLoading(false)
    }
  }

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN){
      getCupons();
    }
  }, [user])

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <CuponIcon />
          <h2>Cupons</h2>
        </Title>
        {
          loading ? <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} />
          :
          (
            cupons.map((item) => (
              <Coupon key={item.id} className={show.indexOf(item.id) !== -1 ? "active" : ""}>
                <div className="info-coupon">
                  <img src={`${URL}/${item.picture}`} alt={item.plate_name} />
                  <div className="text">
                    <h1>{item.plate_name}</h1>
                    <h2>Restaurante: {item.restaurant_name.replace("Restaurante", "").replace("restaurante", "").replace("RESTAURANTE", "")}</h2>
                    <div className="location">
                      <CiLocationOn />
                      <h3>Rua {item.street.replace("Rua", "").replace("rua", "").replace("RUA", "")} - {item.number}, {item.neighborhood} - {item.postal_code}</h3>
                    </div>
                  </div>
                </div>
                <div className="list">
                  {
                    item.codes.map(code => (
                      <div key={code.code} className="code">
                        <h2>{code.code}</h2>
                        {
                          code.used ? <CheckIcon /> : <CrossIcon className='not-used' />
                        }
                      </div>
                    ))
                  }
                </div>
                <button className="show" type='button' onClick={() => showOrHide(item.id)}>
                  {
                    show.indexOf(item.id) !== -1 ? "Esconder" : "Mostrar"
                  }
                </button>
              </Coupon>
            ))
          )
        }

      </Content>

      <Footer />
    </Container>
  )
}

export default CuponsUser
