import React, { useState, useContext, useEffect } from 'react'
import { Container, Content, Coupon, LinkCoupon, LoadMore, Title } from './style';
import Header from '../../components/Header';
import CursorMask from '../../components/CursorMask';
import Footer from '../../components/Footer';

import { AiOutlineHeart } from "react-icons/ai"
import { CiLocationOn } from "react-icons/ci"

import { LoginContext } from '../../contexts/LoginContext';
import { getFavoritesUser } from '../../services/api';
import { toast } from 'react-toastify';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from '../../styles/Theme';

type cuponsType = {
  id: number
  cupon_id: number
  picture: string
  plate_name: string
  restaurant_id: number
  restaurant_name: string
  street: string
  number: number
  neighborhood: string
  postal_code: string
}

function Favorites() {

  const { user } = useContext(LoginContext)
  const [load, setLoad] = useState<boolean>(false)
  const [cupons, setCupons] = useState<cuponsType[]>([])
  const [page, setPage] = useState<number>(1)

  const URL = process.env.REACT_APP_URL_AWS_S3

  async function getCupons() {
    setLoad(true)
    let search = ""

    search += `&page=${page}`

    const result = await getFavoritesUser(user.id, search)
    if(Array.isArray(result) && result.length >= 2 ){
      const code = result[0] as number;
      const response = result[1] as cuponsType[];

      if (code === 200) {
        setCupons([
          ...cupons,
          ...response,
        ])
        setLoad(false)
        return
      }
      toast.error("Erro ao obter cupons")
      setLoad(false)
      return
    }
  }

  useEffect(() => {
    if(user && user?.id && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN){
      getCupons()
      return
    }
    setCupons([])
  }, [user, page])

  return (
    <Container>
      <CursorMask />
      <Header />
      <Content>
        <Title>
          <AiOutlineHeart />
          <h2>Favoritos</h2>
        </Title>
        {
          cupons.map(item => (
            <Coupon key={item.id}>
              <LinkCoupon to={`/cupon/${item.id}`} className="info-coupon">
                <img src={`${URL}/${item.picture}`} alt={item.plate_name} />
                <div className="text">
                  <h1>{item.plate_name}</h1>
                  <h2>Restaurante: {item.restaurant_name.replace("Restaurante", "").replace("restaurante", "").replace("RESTAURANTE", "")}</h2>
                  <div className="location">
                    <CiLocationOn />
                    <h3>Rua {item.street.replace("Rua", "").replace("rua", "").replace("RUA", "")} - {item.number}, {item.neighborhood} - {item.postal_code}</h3>
                  </div>
                </div>
              </LinkCoupon>
            </Coupon>
          ))
        }
        {
          load ? <UseAnimations animation={loading2} size={28} fillColor={theme.colors.text} /> :
          (
            cupons.length / page < page * 20 ? null : 
            (
              <LoadMore onClick={() => setPage(page + 1)}>
                Carregar mais
              </LoadMore>
            )
          )
        }

      </Content>
      <Footer />
    </Container>
  )
}

export default Favorites
