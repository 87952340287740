import React, { useContext, useState, useEffect } from 'react'
import { Button, Container, Field, InputWithMask } from './style.js'
import { LoginContext } from '../../../contexts/LoginContext'
import { toast } from 'react-toastify';
import { updateUser } from '../../../services/api';

import UseAnimations from "react-useanimations";
import loading2 from 'react-useanimations/lib/loading2';
import { theme } from "../../../styles/Theme"

function Info() {

  const { user } = useContext(LoginContext)

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [load, setLoad] = useState(false)

  const update = async () => {
    if(!user || !user?.id || !user?.token || user.token !== process.env.REACT_APP_USER_TOKEN) {
      toast.error("Erro ao atualizar dados")
      return
    }


    if(!name || !tel){
      toast.warn("Preencha todos os campos")
      return
    }

    setLoad(true)
    const code = await updateUser({ name, tel }, user.id)

    if(code === 200) {
      toast.success("Informações atualizadas")
      setLoad(false)
      return
    }

    setLoad(false)
    toast.error("Erro ao atualizar dados")
  }

  useEffect(() => {
    if(user && user?.token && user?.token === process.env.REACT_APP_USER_TOKEN) {
      setName(user?.name)
      setEmail(user?.email)
      setTel(user?.tel)
    }
  }, [user])

  return (
    <Container>
      {
        load ? <UseAnimations style={{marginBottom: "40px"}} animation={loading2} size={32} fillColor={theme.colors.text} /> :
        (
          <>
            <Field>
              <h3>Nome</h3>
              <div className="field">
                <input type='text' name='name' id='name' value={name} onChange={(event) => setName(event.target.value)} placeholder='Nome' />
              </div>
            </Field>

            <Field>
              <h3>E-mail</h3>
              <div className="field">
                <input type='email' name='email' id='email' value={email} placeholder='E-mail' disabled />
              </div>
            </Field>

            <Field>
              <h3>Telefone</h3>
              <div className="field">
                <InputWithMask mask="(99) 9 9999-9999" type='text' name='tel' id='tel' value={tel} onChange={(event) => setTel(event.target.value)} placeholder='(00) 0 0000-0000' />
              </div>
            </Field>

            <Button type='button' onClick={update}>Atualizar</Button>
          </>
        )
      }
    </Container>
  )
}

export default Info
