/* eslint-disable @typescript-eslint/no-inferrable-types */
import React from 'react'
import { motion } from 'framer-motion'

import MousePosition from '../../utils/MousePosition'
import "./styles.css"



function CursorMask() {

  const { x, y }: {x: number, y: number} = MousePosition()
  const size:number = 15

  return (
    <motion.div
      className="mask"
      animate={{
        WebkitMaskPosition: `${x - size/2}px ${y - size/2}px`,
        WebkitMaskSize: `${size}px`,
        top: `${y - size/2}px`,
        left: `${x - size/2}px`,
      }}
      transition={{type: "tween", ease: "backOut"}}
    />
  )
}

export default CursorMask
