import React, { useContext } from 'react'
import { Container, Content, Section1, Col, Title, LinkFooter, Section2, Media, LinkMedia, Open } from './styles'

import { BsInstagram } from "react-icons/bs"
import { AiFillFacebook } from "react-icons/ai"
import { FaLinkedinIn } from "react-icons/fa"

import { ModalsContexts } from '../../contexts/ModalsContexts'


function Footer() {

  const { handleOpenModalRegister, handleOpenModalLogin } = useContext(ModalsContexts);

  return (
    <Container>
      <Content>
        <Section1>
          <Col>
            <Title>Mapa do site</Title>
            <LinkFooter to='/sobre'>Sobre nós</LinkFooter>
            <LinkFooter to='/perguntas-frequentes'>Perguntas frequentes</LinkFooter>
            <LinkFooter to='/termos-de-uso'>Termos de uso</LinkFooter>
            <LinkFooter to='/politicas-de-privacidade'>Políticas de Privacidade</LinkFooter>
            <LinkFooter to='/fale-conosco'>Fale Conosco</LinkFooter>
          </Col>

          <Col>
            <Title>Login</Title>
            <Open onClick={handleOpenModalRegister}>Cadastre-se</Open>
            <Open onClick={handleOpenModalLogin}>Fazer Login</Open>
            <LinkFooter to='/seja-parceiro'>Seja parceiro</LinkFooter>
          </Col>

          <Col>
            <Title>cuponzin™</Title>
            <Media>
              <LinkMedia to="https://instagram.com/" target='_blank'>
                <BsInstagram />
              </LinkMedia>

              <LinkMedia to="https://www.linkedin.com/" target='_blank'>
                <FaLinkedinIn />
              </LinkMedia>

              <LinkMedia to="https://www.facebook.com/login/" target='_blank'>
                <AiFillFacebook />
              </LinkMedia>
            </Media>
            <LinkFooter to='https://www.google.com/' target='_blank'>Comente sobre nós no Google</LinkFooter>
            <LinkFooter to='https://web.whatsapp.com/' target='_blank'>Receba ofertas no WhatsApp</LinkFooter>
          </Col>

          <Col>
            <Title>Atendimento</Title>
            <LinkFooter to='/duvidas-frequentes'>Dúvidas frequentes</LinkFooter>
            <LinkFooter to='https://www.google.com/' target='_blank'>Formulário</LinkFooter>
            <LinkFooter to='tel:+554898765432'>(48) 9 9876-54321</LinkFooter>
            <LinkFooter to='mailto:contato@cuponzin.com'>contato@cuponzin.com</LinkFooter>
            <LinkFooter to='https://web.whatsapp.com/' target='_blank'>WhatsApp</LinkFooter>
          </Col>
        </Section1>

        <Section2>
          <span>&copy; cuponzin™ 2023</span>
          <span>All rights reserved</span>
        </Section2>
      </Content>
    </Container>
  )
}

export default Footer
