import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../styles/Theme";

Modal.setAppElement("#root")

export const Container = styled(Modal)`
  width: 90%;
  max-width: 400px;
  height: auto;
  max-height: 90vh;
  min-height: 80px;
  background-color: ${theme.colors.background};
  color: ${theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  transition: all .4s;
  position: relative;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  svg.logo-modal{
    height: 24px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 32px;

    path, rect, polygon{
      fill: ${theme.colors.primary};
    }
  }

  span{
    margin: 0;
    font-size: 12px;
    margin-bottom: 16px;
    color: ${theme.colors.lightBlue};
  }
`;

export const Content = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  div.info-coupon{
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    gap: 12px;

    img{
      height: 64px;
      width: 64px;
      min-width: 64px;
      object-fit: cover;
      object-position: center;
    }

    div.text{
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 2px;
      width: 100%;

      h2{
        font-size: 16px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.text};
        margin: 0;
        margin-bottom: 4px;
      }

      h3{
        font-size: 12px;
        font-family: "NeueMachinaLight";
        font-weight: 200;
        color: ${theme.colors.gray400};
        margin: 0;
      }
    }
  }
`;


export const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 4px;
  border: none;
  background-color: ${theme.colors.lightGreen};
  font-size: 18px;
  color: ${theme.colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
`;

export const Add = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 16px;

  svg{
    height: 28px;
    width: 28px;
    cursor: pointer;
    margin: 0;

    path{
      transition: all .4s;
      stroke: ${theme.colors.gray400};
    }

    rect{
      fill: transparent;
    }


    &:hover path {
      stroke: ${theme.colors.text};
    }

    &.block{
      cursor: not-allowed;
      path { stroke: ${theme.colors.gray200}}
    }
  }

  span {
    width: 40px;
    font-size: 20px;
    color: ${theme.colors.text};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    text-align: center;
    margin: 0;
  }

  input{
    height: 22px;
    width: 48px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 20px;
    color: ${theme.colors.text};
    font-family: "NeueMachinaLight";
    font-weight: 200;
    text-align: center;
  }
`;
